import * as React from 'react';
import { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { getComponentElementKeyValue } from '../utils/key-value-getter';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';

interface P {
  componentElement: ComponentInputElement;
}

const useStyles = makeStyles(
  createStyles({
    checkbox: {
      '& .MuiTypography-root': {
        color: 'var(--color-text)',
        fontSize: 14
      },
      '& .MuiCheckbox-root': {
        color: 'var(--color-key)'
      }
    }
  })
);

/**
 *
 * bitkeyアカウントで使用したEmailを使用するか否かを選択可能な
 * Email入力用のコンポーネント
 *
 */
const CheckInputElement: React.FC<P> = ({ componentElement }) => {
  const styles = useStyles({});
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);
  const inputValue = useMemo(
    () =>
      getComponentElementKeyValue({
        inputValues,
        applicationParams,
        inputValueType: componentElement.valueType,
        valueKey: valueKey,
        initialValue: componentElement.initialValue
      }),
    [
      inputValues[valueKey],
      applicationParams[valueKey],
      componentElement.valueType,
      valueKey,
      componentElement.initialValue
    ]
  );

  const isDisable = useMemo(
    () =>
      checkComponentElementCondition({
        conditions: componentElement.isDisableCondition,
        inputValues: inputValues,
        applicationParams: applicationParams,
        initialValue: false
      }),
    [inputValues, applicationParams]
  );

  // 初期値の補完
  useEffect(() => {
    if (inputValues[valueKey] || !componentElement.initialValue || isDisable) {
      return;
    }
    updateInputValues(valueKey, componentElement.initialValue);
  }, []);

  return (
    <>
      <FormControlLabel
        className={styles.checkbox}
        control={
          <Checkbox
            color="primary"
            checked={inputValue}
            onChange={e => updateInputValues(valueKey, e.target.checked)}
          />
        }
        label={(componentElement.selectionItems && componentElement.selectionItems[0].label) || ''}
      />
    </>
  );
};

export default React.memo(CheckInputElement);
