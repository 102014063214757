import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      margin: '12px 0 0',
      borderTop: '1px solid #BDBDBD',
      borderCollapse: 'collapse',
      width: '100%'
    },
    tr: {
      margin: 0,
      textAlign: 'left'
    },
    th: {
      color: '#9E9E9E',
      fontSize: 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      border: '1px solid #E0E0E0',
      borderWidth: '0px 0px 1px',
      borderCollapse: 'collapse',
      padding: '16px 8px',
      margin: 0,
      width: '40%'
    },
    td: {
      color: 'var(--color-text)',
      fontSize: 14,
      fontWeight: 'normal',
      fontStyle: 'normal',
      lineHeight: '20px',
      border: '1px solid #E0E0E0',
      borderCollapse: 'collapse',
      borderWidth: '0px 0px 1px',
      padding: '16px 8px',
      margin: 0,
      wordBreak: 'break-all',
      width: '60%'
    }
  })
);

export const Table = (props: { children: JSX.Element }) => {
  const styles = useStyles({});
  return (
    <table className={styles.table}>
      <tbody>{props.children}</tbody>
    </table>
  );
};

interface TrProps {
  th: string;
  td: string;
}

export const Tr = (props: TrProps) => {
  const styles = useStyles({});

  return (
    <tr className={styles.tr}>
      <th className={styles.th}>{props.th}</th>
      <td className={styles.td}>{props.td}</td>
    </tr>
  );
};
