import React from 'react';
import { withStyles } from '@material-ui/styles';
import { FormControlLabel } from '@material-ui/core';
import Radio, { RadioProps } from '@material-ui/core/Radio';

export const CustomRadioButton = withStyles({
  root: {
    '&.MuiFormControlLabel-root': {
      border: '2px solid #E0E0E0',
      borderRadius: 4,
      boxSizing: 'border-box',
      margin: '8px 0 0',
      padding: '3px 0',
      color: 'var(--color-text)'
    }
  }
})(FormControlLabel);

export const BlueRadio = withStyles({
  root: {
    color: '#e0e0e0'
  },
  checked: {
    color: 'var(--color-key)'
  }
})((props: RadioProps) => <Radio color={'default'} {...props} />);
