import * as React from 'react';
import { DesktopBackground } from '../../components/atoms/DesktopBackground';
import { makeStyles } from '@material-ui/core/styles';
import IdentificationByBirth from '../../container/identification';

const useStyles = makeStyles(() => ({
  container: {
    background: 'var(--color-white)',
    textAlign: 'center',
    '@media (min-width:768px)': {
      borderRadius: 10
    }
  }
}));

const Identification: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <DesktopBackground>
      <div className={classes.container}>
        <IdentificationByBirth />
      </div>
    </DesktopBackground>
  );
};

export default Identification;
