enum ActionType {
  updateParameter = 'UPDATE_PARAMETER',
  verifyIdentification = 'VERIFY_IDENTIFICATION',
  successToVerifyIdentification = 'SUCCESS_TO_VERIFY_IDENTIFICATION',
  failedToVerifyIdentification = 'FAILED_TO_VERIFY_IDENTIFICATION'
}

export type verifyIdentificationErrorStatus = 'no_error' | 'unauthorized' | 'locked' | 'internal';

export interface IdentificationParameterState {
  birthday: string;
}

export default ActionType;
