import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  createStyles({
    container: {
      margin: '0 auto',
      width: '100px'
    },
    load: {
      width: 100,
      animation: 'loading 3s linear infinite'
    },
    loading: {
      strokeDashoffset: '0',
      strokeDasharray: 300,
      strokeWidth: 4,
      strokeMiterlimit: 4,
      strokeLinecap: 'round',
      animation: 'loading-circle 1.5s linear infinite',
      fill: 'transparent'
    }
  })
);

interface Props {
  white?: boolean;
}

export const Loading: FC<Props> = ({ white }) => {
  const styles = useStyles({});
  const strokeColor = white ? 'var(--color-white)' : 'var(--color-key)';

  return (
    <div className={styles.container}>
      <svg className={styles.load} x="0px" y="0px" viewBox="0 0 150 150">
        <circle style={{ stroke: strokeColor }} className={styles.loading} cx="75" cy="75" r="60" />
      </svg>
    </div>
  );
};
