import { UserAction } from './actions';
import ActionType from './types';

export interface UserState {
  email: string;
  phoneNumber?: string;
  user_id: string;
  birthday: string;
  access_token: string;
  refresh_token: string;
}

export const initialState: () => UserState = () => {
  return {
    email: '',
    phoneNumber: '',
    user_id: '',
    birthday: '',
    access_token: '',
    refresh_token: ''
  };
};

const reducer = (state: UserState = initialState(), action: UserAction): UserState => {
  switch (action.type) {
    case ActionType.setEmail: {
      const newState = { ...state };
      newState.email = action.payload;
      return newState;
    }
    case ActionType.setPhoneNumber: {
      const newState = { ...state };
      newState.phoneNumber = action.payload;
      return newState;
    }
    case ActionType.setBirthday: {
      const newState = { ...state };
      newState.birthday = action.payload;
      return newState;
    }
    case ActionType.setAccessToken: {
      const newState = { ...state };
      newState.access_token = action.payload;
      return newState;
    }
    case ActionType.setUserId: {
      const newState = { ...state };
      newState.user_id = action.payload;
      return newState;
    }
    case ActionType.setRefreshToken: {
      const newState = { ...state };
      newState.refresh_token = action.payload;
      return newState;
    }
    default:
      ((_: never) => {
        return;
      })(action);
      return state;
  }
};

export default reducer;
