import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AppState } from '../../store';

export const saveQueryParamsToCookie = (): ThunkAction<{}, AppState, {}, AnyAction> => async (dispatch, getState) => {
  const { allSpaceId, contractId } = getState().property.property;

  document.cookie = `all_space_id=${allSpaceId}`;
  document.cookie = `contract_id=${contractId}`;
};
