import ActionTypes, { PropertyStatus } from './types';
import { PropertyAction } from './actions';
import { Property } from './types';

export interface PropertyState {
  property: Property;
  status: PropertyStatus;
}

export const initialState: () => PropertyState = () => {
  return {
    status: 'not_load',
    property: {
      allSpaceId: '',
      contractId: '',
      applicationStatus: '',
      hasElectricContract: true,
      occupyScheduledDate: new Date(),
      postCode: '',
      address: '',
      prefecture: '',
      city: '',
      afterCity: '',
      buildingName: '',
      propertyName: '',
      propertyCode: '',
      sumamoruCode: '',
      status: '',
      managementCompanyAddress: '',
      managementCompanyName: '',
      managementCompanyPhoneNumber: '',
      sumamoruInstallAppTypes: []
    }
  };
};

const reducer = (state: PropertyState = initialState(), action: PropertyAction): PropertyState => {
  switch (action.type) {
    case ActionTypes.startToFetchProperty: {
      const newState = { ...state };
      newState.status = 'property_loading';
      return newState;
    }
    case ActionTypes.setProperty: {
      const newState = { ...state };
      newState.property = action.payload;
      newState.status = 'set';
      return newState;
    }
    case ActionTypes.failedSetProperty: {
      const newState = { ...state };
      newState.status = 'error';
      return newState;
    }
    case ActionTypes.setAllSpaceId: {
      const newState = { ...state };
      newState.property.allSpaceId = action.payload;
      return newState;
    }
    case ActionTypes.setContractId: {
      const newState = { ...state };
      newState.property.contractId = action.payload;
      return newState;
    }
    default:
      ((_: never) => {
        return;
      })(action);
      return state;
  }
};

export default reducer;
