import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import * as UserActions from '../user/actions';
import { AnyAction } from 'redux';
import { AppState } from '../../store';
import AuthService from '../../utils/BkpAuthService';

export const submitCode = (code: string): ThunkAction<Promise<void>, AppState, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(Actions.submitCode(code));
    const { email, password, signupSessionId, phoneNumber } = getState().signUpForm;
    AuthService.verifyPasscode(signupSessionId, code, password.value)
      .then(data => {
        document.cookie = `access_token=${data.accessToken}`;
        document.cookie = `user_d=${data.userId}`;
        document.cookie = `email=${email.value}`;
        document.cookie = `phone_number=${phoneNumber.value}`;

        dispatch(
          Actions.submitSuccess(data.accessToken, data.refreshToken, data.userId, email.value, phoneNumber.value)
        );

        dispatch(UserActions.setEmail(email.value));
        dispatch(UserActions.setPhoneNumber(phoneNumber.value));
        dispatch(UserActions.setAccessToken(data.accessToken));
        dispatch(UserActions.setUserId(data.userId));
      })
      .catch(error => {
        console.error(error);
        dispatch(Actions.submitFailed());
      });
  } catch (error) {
    console.error(error);
    dispatch(Actions.submitFailed());
  }
};
