import { connect } from 'react-redux';
import { updateParamater } from '../modules/application/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import BaseForm from '../components/BaseForm';
import * as module from '../modules/application';
import * as propertyModule from '../modules/property';
import * as userModule from '../modules/user';

export const mapStateToProps = (state: AppState) => {
  return {
    inputValues: {
      ...module.Selectors.getParameter(state)
    },
    applicationParams: {
      ...state.property.property,
      bitkeyEmail: userModule.Selectors.getUserEmail(state),
      identifiedBirthday: userModule.Selectors.getUserBirthday(state)
    },

    use_og_gas: module.Selectors.getUseOgGas(state),
    start_power_date: module.Selectors.getStartPowerDate(state),
    start_gas_date: module.Selectors.getGasDate(state),
    start_gas_time: module.Selectors.getGasTime(state),
    isCoodinatedGasStartDate: module.Selectors.getIsCoodinatedGasStartDate(state),
    first_name: module.Selectors.getFirstName(state),
    family_name: module.Selectors.getFamilyName(state),
    first_name_kana: module.Selectors.getFirstNameKana(state),
    family_name_kana: module.Selectors.getFamilyNameKana(state),
    phone_number: module.Selectors.getPhoneNumber(state),
    phone_number_when_move: module.Selectors.getPhoneNumberWhenMove(state),
    management_company_address: state.property.property.managementCompanyAddress,
    management_company_name: state.property.property.managementCompanyName,
    management_company_phonenumber: state.property.property.managementCompanyPhoneNumber,
    birthday: module.Selectors.getBirthday(state),
    email: module.Selectors.getEmail(state),
    email_confirmation: module.Selectors.getEmailConfirmation(state),
    pay_method: module.Selectors.getPayMethod(state),
    move_plan_date: state.property.property.occupyScheduledDate,
    property: propertyModule.Selectors.getProperty(state),
    gender: module.Selectors.getGender(state),
    bitkeyEmail: userModule.Selectors.getUserEmail(state),
    bitkeyPhoneNumber: userModule.Selectors.getUserPhoneNumber(state),
    initialBirthday: userModule.Selectors.getUserBirthday(state),
    isSameEmail: module.Selectors.getIsSameEmail(state),
    isPhoneNumberError: module.Selectors.getIsPhoneNumberError(state),
    isPhoneNumberWhenMoveError: module.Selectors.getIsPhoneNumberWhenMoveError(state),
    isEmailMatch: module.Selectors.getIsEmailMatch(state)
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) => ({
  updateInputValues: (key: string, value: string | number | boolean | undefined) =>
    dispatch(updateParamater(key, value)),
  updateParameter: (key: string, value: string | number | boolean | undefined) => dispatch(updateParamater(key, value)),
  getTokens: () => dispatch(userModule.operations.setUserFromCookie()),
  getIds: () => dispatch(propertyModule.operations.setIdsFromCookie()),
  getProperty: () => dispatch(propertyModule.operations.getProperty())
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseForm);
