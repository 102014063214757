import ActionTypes from './types';
import { EntryAction } from './actions';
import { IndexStatus } from './types';

export interface IndexState {
  status: IndexStatus;
}

export const initialState: () => IndexState = () => {
  return {
    status: 'not_set'
  };
};

const reducer = (state: IndexState = initialState(), action: EntryAction): IndexState => {
  switch (action.type) {
    case ActionTypes.setQueryParams: {
      const newState = { ...state };
      newState.status = 'query_set';
      return newState;
    }
    case ActionTypes.invalidQueryParams: {
      const newState = { ...state };
      newState.status = 'invalid_query';
      return newState;
    }
    case ActionTypes.clearStatus: {
      const newState = { ...state };
      newState.status = 'not_set';
      return newState;
    }

    default:
      ((_: never) => {
        return;
      })(action);
      return state;
  }
};

export default reducer;
