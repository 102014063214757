import * as React from 'react';
import { useMemo, useState, useContext, useEffect } from 'react';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ItemValueKey } from '../../config/item-value-key';

interface P {
  componentElement: ComponentInputElement;
}

const useStyles = makeStyles(
  createStyles({
    selectPlaceholder: {
      color: '#BDBDBD'
    }
  })
);

const YEAR: number[] = [];
for (let i = 1901; i < 2020; i++) {
  YEAR.push(i);
}
const MONTH = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const SelectDateElement: React.FC<P> = ({ componentElement }) => {
  const styles = useStyles({});
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);

  const [year = 1980, setYear] = useState<number>(1980);
  const [month = 1, setMonth] = useState<number>(1);
  const [day, setDay] = useState<number>(1);
  const [lastday, setLastday] = useState<number[]>([]);

  useEffect(() => {
    const lastday = new Date(year, month, 0).getDate();
    const days: number[] = [];
    for (let i = 0; i < lastday; i++) {
      days.push(i + 1);
    }
    if (day > lastday) {
      setDay(1);
    }
    setLastday(days);
  }, [year, month, day]);

  useEffect(() => {
    const _month = month < 10 ? `0${month}` : `${month}`;
    const _day = day < 10 ? `0${day}` : `${day}`;

    updateInputValues(valueKey, `${year}/${_month}/${_day}`);
  }, [year, month, day, updateInputValues]);

  useEffect(() => {
    const birthday = applicationParams['identifiedBirthday'];
    if (valueKey === ItemValueKey.CustomerBirthDay && birthday && birthday !== year + month + day) {
      const initialYear = birthday.slice(0, 4);
      const initialMonth = birthday.slice(4, 6);
      const initialDay = birthday.slice(6, 8);
      setYear(Number(initialYear));
      setMonth(Number(initialMonth));
      setDay(Number(initialDay));
      const formattedBirthday = `${initialYear}/${initialMonth}/${initialDay}`;
      updateInputValues(ItemValueKey.CustomerBirthDay, formattedBirthday);
    }
  }, [applicationParams['identifiedBirthday']]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <FormControl margin="normal" style={{ width: '100%', marginTop: 8 }} variant="outlined">
          <Select value={year} onChange={e => setYear(e.target.value as number)} displayEmpty>
            <MenuItem value={undefined} disabled>
              <span className={styles.selectPlaceholder}>年</span>
            </MenuItem>
            {YEAR.reverse().map(y => {
              return (
                <MenuItem key={`y${y}`} value={y}>
                  {y}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl margin="normal" style={{ width: '100%', marginTop: 8 }} variant="outlined">
          <Select value={month} onChange={e => setMonth(e.target.value as number)} displayEmpty>
            <MenuItem value={undefined} disabled>
              <span className={styles.selectPlaceholder}>月</span>
            </MenuItem>
            {MONTH.map(m => {
              return (
                <MenuItem key={`m${m}`} value={m}>
                  {m}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl margin="normal" style={{ width: '100%', marginTop: 8 }} variant="outlined">
          {day ? (
            <Select value={day} onChange={e => setDay(e.target.value as number)} displayEmpty disabled={lastday === []}>
              <MenuItem value={undefined} disabled>
                <span className={styles.selectPlaceholder}>日</span>
              </MenuItem>
              {lastday &&
                lastday.map(d => (
                  <MenuItem key={`m${d}`} value={d}>
                    {d}
                  </MenuItem>
                ))}
            </Select>
          ) : (
            <></>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default React.memo(SelectDateElement);
