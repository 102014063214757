import * as React from 'react';
import { useMemo, useContext, useEffect } from 'react';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentElement } from '../../types/common/item-definition-types';
import { ItemValueKeySuffix } from '../../enums/common/item-value-key-suffix';
import ComponentDescriptionElement from './ComponentDescriptionElement';
import InputComponentElement from '../InputComponetns/InputComponentElement';
import { ApplyLabel } from '../../components/Label';

interface P {
  component: ComponentElement;
}

const ComponentElementFunction: React.FC<P> = ({ component }) => {
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const isDisplay = useMemo(
    () =>
      checkComponentElementCondition({
        conditions: component.isDisplayCondition,
        inputValues: inputValues,
        applicationParams: applicationParams,
        initialValue: true
      }),
    [inputValues, applicationParams]
  );

  useEffect(() => {
    if (!component.inputElement) {
      return;
    }
    updateInputValues(component.inputElement.valueKey + ItemValueKeySuffix.IsDisplayed, isDisplay);

    // 入力画面での対象項目 かつ 表示されていない かつ readOnlyでない場合には指定の項目をundefinedで更新する
    if (!component.showOnlyConfirmation && !isDisplay && !component.inputElement.readonly) {
      updateInputValues(component.inputElement.valueKey, undefined);
    }
  }, [isDisplay]);

  return (
    <>
      {isDisplay && (
        <>
          {component.label && <ApplyLabel text={component.label} />}
          {component.descriptionsBeforeInput &&
            component.descriptionsBeforeInput.map((description, i) => (
              <ComponentDescriptionElement key={`before-description-${component.id}-${i}`} description={description} />
            ))}
          {component.inputElement && <InputComponentElement componentElement={component.inputElement} />}
          {component.descriptionsAfterInput &&
            component.descriptionsAfterInput.map((description, i) => (
              <ComponentDescriptionElement key={`after-description-${component.id}-${i}`} description={description} />
            ))}
        </>
      )}
    </>
  );
};

export default React.memo(ComponentElementFunction);
