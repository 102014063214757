import { withStyles } from '@material-ui/styles';
import { Fab } from '@material-ui/core';

export const CustomKeyButton = withStyles({
  root: {
    '&.MuiButtonBase-root': {
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-key)',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
      width: '100%',
      '&:hover': {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
        backGround: 'var(--color-key)'
      }
    },
    '&:disabled': {
      color: 'var(--color-white)',
      backgroundColor: '#E0E0E0',
      width: '100%'
    }
  }
})(Fab);
