import SignUpActionType, { SignupMethod, SignupStatus, TextFieldStatus } from './types';
import { ActionWithPayload } from '../utils/types';

export const updateEmail = (email: string): ActionWithPayload<SignUpActionType.updateEmail, string> => ({
  type: SignUpActionType.updateEmail,
  payload: email
});

export const updatePhoneNumber = (
  phoneNumber: string
): ActionWithPayload<SignUpActionType.updatePhoneNumber, string> => ({
  type: SignUpActionType.updatePhoneNumber,
  payload: phoneNumber
});

export const updatePassword = (password: string): ActionWithPayload<SignUpActionType.updatePassword, string> => ({
  type: SignUpActionType.updatePassword,
  payload: password
});

export const validateSignupEmail = (
  status: TextFieldStatus
): ActionWithPayload<SignUpActionType.validateSignupEmail, TextFieldStatus> => ({
  type: SignUpActionType.validateSignupEmail,
  payload: status
});

export const validateSignupPhoneNumber = (
  status: TextFieldStatus
): ActionWithPayload<SignUpActionType.validateSignupPhoneNumber, TextFieldStatus> => ({
  type: SignUpActionType.validateSignupPhoneNumber,
  payload: status
});

export const validateSignupPassword = (
  status: TextFieldStatus
): ActionWithPayload<SignUpActionType.validateSignupPassword, TextFieldStatus> => ({
  type: SignUpActionType.validateSignupPassword,
  payload: status
});

export const startToSignup = (): ActionWithPayload<SignUpActionType.startToSignup, {}> => ({
  type: SignUpActionType.startToSignup,
  payload: {}
});

export const signupSuccess = (signupSessionId: string): ActionWithPayload<SignUpActionType.signupSuccess, string> => ({
  type: SignUpActionType.signupSuccess,
  payload: signupSessionId
});

export const signupFailed = (status: SignupStatus): ActionWithPayload<SignUpActionType.signupFailed, SignupStatus> => ({
  type: SignUpActionType.signupFailed,
  payload: status
});

export const clearSignupStatus = (): ActionWithPayload<SignUpActionType.clearSignupStatus, {}> => ({
  type: SignUpActionType.clearSignupStatus,
  payload: {}
});

export const changeSignupMethod = (
  signupMethod: SignupMethod
): ActionWithPayload<SignUpActionType.changeSignupMethod, SignupMethod> => ({
  type: SignUpActionType.changeSignupMethod,
  payload: signupMethod
});

export type SignUpAction =
  | ReturnType<typeof updateEmail>
  | ReturnType<typeof updatePhoneNumber>
  | ReturnType<typeof updatePassword>
  | ReturnType<typeof validateSignupEmail>
  | ReturnType<typeof validateSignupPhoneNumber>
  | ReturnType<typeof validateSignupPassword>
  | ReturnType<typeof startToSignup>
  | ReturnType<typeof signupSuccess>
  | ReturnType<typeof signupFailed>
  | ReturnType<typeof clearSignupStatus>
  | ReturnType<typeof changeSignupMethod>;
