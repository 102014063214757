import * as React from 'react';
import { useMemo, useContext } from 'react';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';
import { ComponentElement } from '../../types/common/item-definition-types';
import EmphasizedDisableInput from './EmphasizedDisableInput';
import ConfirmationListViewElement from './ConfirmationListViewElement';
import { ConfirmationDisplayType } from '../../enums/confirmation/confirmation-display-type';
import { ConfimationFormStateContext } from '../template/ConfirmTemplate';

interface P {
  component: ComponentElement;
  confirmationDisplayType?: ConfirmationDisplayType;
}

const ConfirmationComponentHandler: React.FC<P> = ({ component, confirmationDisplayType }) => {
  const { inputValues, applicationParams } = useContext(ConfimationFormStateContext);

  const useEmpasizeDisableInput = useMemo(
    () => confirmationDisplayType === ConfirmationDisplayType.EmphasizedDisableInput,
    []
  );
  const useNormalListView = useMemo(
    () => !confirmationDisplayType || confirmationDisplayType === ConfirmationDisplayType.NormalList,
    []
  );

  const isDisplay = useMemo(
    () =>
      checkComponentElementCondition({
        conditions: component.isDisplayCondition,
        inputValues: inputValues,
        applicationParams: applicationParams,
        initialValue: true
      }),
    [inputValues, applicationParams]
  );

  return (
    <>
      {isDisplay && !component.showOnlyInput && (component.inputElement || component.inputElements) && (
        <>
          {useEmpasizeDisableInput && component.inputElement && (
            <EmphasizedDisableInput component={component.inputElement} />
          )}
          {useNormalListView && <ConfirmationListViewElement component={component} />}
        </>
      )}
    </>
  );
};

export default React.memo(ConfirmationComponentHandler);
