import { AppState } from '../../store';

export const getUserEmail = ({ user }: AppState): string => {
  return user.email;
};

export const getUserPhoneNumber = ({ user }: AppState): string => {
  return user.phoneNumber || '';
};

export const getUserBirthday = ({ user }: AppState): string => {
  return user.birthday || '';
};

export const getUserAccessToken = ({ user }: AppState): string => {
  return user.access_token;
};

export const getUserId = ({ user }: AppState): string => {
  return user.user_id;
};

export const getUserRefreshToken = ({ user }: AppState): string => {
  return user.refresh_token;
};
