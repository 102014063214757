import * as React from 'react';
import { useMemo, useContext, useEffect } from 'react';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BaseFormStateContext } from '../BaseForm';
import { ContainerElement } from '../../types/common/item-definition-types';
import { ItemValueKeySuffix } from '../../enums/common/item-value-key-suffix';
import ComponentDescriptionElement from './ComponentDescriptionElement';
import InputComponentElement from '../InputComponetns/InputComponentElement';
// import { ApplyLabel } from '../../components/Label';
import { Container, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { ApplyHeading, ApplyLabel, ApplyCaption } from '../../components/Label';
import ComponentElement from '../componentElements/ComponentElement';

interface P {
  container: ContainerElement;
}

const useStyles = makeStyles(
  createStyles({
    container: {
      textAlign: 'left',
      padding: '0 0 20px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0'
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-key)'
        }
      }
    }
  })
);

const ContainerElementFunction: React.FC<P> = ({ container }) => {
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);
  const styles = useStyles({});

  const isDisplay = useMemo(
    () =>
      checkComponentElementCondition({
        conditions: container.isDisplayCondition,
        inputValues: inputValues,
        applicationParams: applicationParams,
        initialValue: true
      }),
    [inputValues, applicationParams]
  );

  // useEffect(() => {
  //   if (!component.inputElement) {
  //     return;
  //   }
  //   updateInputValues(component.inputElement.valueKey + ItemValueKeySuffix.IsDisplayed, isDisplay);
  // }, [isDisplay]);

  return (
    <>
      {isDisplay && (
        <Container className={styles.container} key={container.id} id={container.id}>
          {container.title && <ApplyHeading id={container.id} text={container.title} />}
          {container.components &&
            container.components
              .filter(component => !component.showOnlyConfirmation)
              .map(component => <ComponentElement component={component} key={component.id} />)}
        </Container>
      )}
    </>
  );
};

export default React.memo(ContainerElementFunction);
