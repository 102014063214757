import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import * as UserActions from '../user/actions';
import { AnyAction } from 'redux';
import AuthService from '../../utils/BkpAuthService';
import { AppState } from '../../store';

export const submitLoginForm = (): ThunkAction<Promise<void>, AppState, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(Actions.startToSignin());

    const { identifyValue, password } = getState().signInForm;

    // 電話番号でマッチしたら電話番号によるログイン試行を行う
    const phoneRegex = /^0{1}\d{8,13}$/;
    if (phoneRegex.test(identifyValue.value)) {
      // 電話番号によるログイン試行
      AuthService.loginWithPhoneNumber(identifyValue.value, password.value)
        .then(data => {
          document.cookie = `access_token=${data.accessToken}`;
          document.cookie = `user_id=${data.userId}`;
          document.cookie = `phone_number=${identifyValue.value}`;

          dispatch(Actions.signinSuccess());

          dispatch(UserActions.setPhoneNumber(identifyValue.value));
          dispatch(UserActions.setAccessToken(data.accessToken));
          dispatch(UserActions.setUserId(data.userId));
          dispatch(UserActions.setRefreshToken(data.refreshToken));
        })
        .catch(error => {
          switch (error.status) {
            case 404: {
              return dispatch(Actions.signinFailed('not_found'));
            }
            case 401: {
              return dispatch(Actions.signinFailed('auth_failed'));
            }
            case 422: {
              return dispatch(Actions.signinFailed('invalid'));
            }
            default: {
              return dispatch(Actions.signinFailed('error'));
            }
          }
        });
    } else {
      // メールによるログイン試行
      AuthService.loginWithEmail(identifyValue.value, password.value)
        .then(data => {
          document.cookie = `access_token=${data.accessToken}`;
          document.cookie = `user_id=${data.userId}`;
          document.cookie = `email=${identifyValue.value}`;

          dispatch(Actions.signinSuccess());

          dispatch(UserActions.setEmail(identifyValue.value));
          dispatch(UserActions.setAccessToken(data.accessToken));
          dispatch(UserActions.setUserId(data.userId));
          dispatch(UserActions.setRefreshToken(data.refreshToken));
        })
        .catch(error => {
          switch (error.status) {
            case 404: {
              return dispatch(Actions.signinFailed('not_found'));
            }
            case 401: {
              return dispatch(Actions.signinFailed('auth_failed'));
            }
            case 422: {
              return dispatch(Actions.signinFailed('invalid'));
            }
            default: {
              return dispatch(Actions.signinFailed('error'));
            }
          }
        });
    }
  } catch (error) {
    dispatch(Actions.signinFailed('error'));
  }
};
