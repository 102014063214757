import * as React from 'react';
import { useMemo, useContext } from 'react';
import { getConfirmationInputText } from '../utils/key-value-getter';
import { ComponentElement } from '../../types/common/item-definition-types';
import { ConfimationFormStateContext } from '../template/ConfirmTemplate';
import { Tr } from '../../components/Table';

interface P {
  component: ComponentElement;
}

const breakedText = text => {
  return text.split('¥n').map((line, key) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ));
};

const ConfirmationListViewElement: React.FC<P> = ({ component }) => {
  const { inputValues, applicationParams } = useContext(ConfimationFormStateContext);

  const displayText = useMemo(() => {
    if (!component.inputElement && !component.inputElements) {
      return '';
    }

    // 表示する際に使用するキーが単一である場合には、ただ返すだけ
    if (component.inputElement) {
      return getConfirmationInputText({
        inputValues,
        applicationParams,
        inputValueType: component.inputElement.valueType,
        valueKey: component.inputElement.valueKey,
        initialValue: component.inputElement.initialValue,
        converter: component.inputElement.converter,
        inputComponentType: component.inputElement.inputType,
        selectionItems: component.inputElement.selectionItems
      });
    }

    if (!component.inputElements) {
      return '';
    }

    return breakedText(
      component.inputElements
        .map(inputElement =>
          getConfirmationInputText({
            inputValues,
            applicationParams,
            inputValueType: inputElement.valueType,
            valueKey: inputElement.valueKey,
            initialValue: inputElement.initialValue,
            converter: inputElement.converter,
            inputComponentType: inputElement.inputType,
            selectionItems: inputElement.selectionItems
          })
        )
        .join(' ')
    );
  }, [component]);

  const label = useMemo(() => breakedText(component.confirmationLabel || component.label || ''), []);

  return <Tr th={label} td={displayText} />;
};

export default React.memo(ConfirmationListViewElement);
