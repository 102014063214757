import { withStyles } from '@material-ui/styles';
import { Fab } from '@material-ui/core';

export const BaseFab = withStyles({
  root: {
    '&.MuiButtonBase-root': {
      '&:hover': {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
        background: 'var(--color-key)'
      }
    },
    '&.MuiFab-root': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)'
    }
  }
})(Fab);
