export enum ComponentType {
  TextFieldInput = 'TextFieldInput',
  SelectItemInput = 'SelectItemInput',
  RadioButtonInput = 'RadioButtonInput',
  FixedText = 'FixedText',
  KeyboardDateInput = 'KeyboardDateInput',
  SelectDateInput = 'SelectDateInput',
  EmailInput = 'EmailInput',
  CheckInput = 'CheckInput',
  KanaNameAutoCompleteInput = 'KanaNameAutoCompleteInput'
}
