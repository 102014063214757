import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    linkContainer: {
      margin: '14px 0 0',
      textAlign: 'center'
    },
    link: {
      fontSize: 14,
      color: 'var(--color-key)',
      fontWeight: 'bold',
      fontStyle: 'normal',
      textDecoration: 'none'
    }
  })
);

interface LinkProps {
  text: string;
  path: string;
}

export const ChangeAccountTypeButton = (props: LinkProps) => {
  const styles = useStyles({});
  return (
    <div className={styles.linkContainer}>
      <Link to={props.path} className={styles.link}>
        {props.text}
      </Link>
    </div>
  );
};
