import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';
// import { Path } from '../../constants/path';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box'
    },
    managementContainer: {
      backgroundColor: '#ECEFF1',
      padding: 20,
      boxSizing: 'border-box',
      '& p': {
        fontSize: 15,
        color: 'var(--color-gray-1)',
        lineHeight: '22px',
        margin: 0
      }
    },
    managementHeading: {
      color: '#828282 !important',
      fontWeight: 'bold',
      marginBottom: '8px !important'
    },
    body: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '21px'
    }
  })
);
const Tokushoho: React.FC = () => {
  const styles = useStyles({});
  return (
    <div className={styles.container}>
      <h1>特定商取引法に基づく表示</h1>
      <p className={styles.body}>
        ■会社名
        <br />
        京葉瓦斯株式会社
        <br />
        <br />
        ■代表者
        <br />
        取締役社長 羽生 弘
        <br />
        <br />
        ■所在地
        <br />
        千葉県市川市市川南２－８－８
        <br />
        <br />
        ■電話番号
        <br />
        ０４７－３６１－０２１１
        <br />
        <br />
        ■Eメールアドレス
        <br />
        auto-reply@mail.sumamoru.entryservice.jp
        <br />
        <br />
        ■商品・役務の提供時期
        <br />
        ・電気：お客さまの需給契約の申込みを承諾したときに受給開始予定日をお知らせし、必要なお手続きを経たのち受給開始予定日から供給します。
        <br />
        ・ガス：開栓のお申込みを受けた日に合意した日より供給します。
        <br />
        ・駆けつけサービス/優待・割引サービス：サービスのお申込みを受けた日に合意した日より提供します。
        <br />
        <br />
        ■お申込み有効期限
        <br />
        ・電気：お客さまがサービスの提供を受けることを希望する日の前日まで
        <br />
        ・ガス：お客さまがサービスの提供を受けることを希望する日の５営業日前まで
        <br />
        <br />
        ■商品・役務の対価、対価の支払時期及び方法、提供条件
        <br />
        ・電気の供給に係る電気の料金、その他の供給条件は「電気需給約款(低圧)」および「電気料金プラン定義書」によります。詳細は以下をご覧ください。
        <a href={'/static/base_electric_term.pdf'} target="_blank" rel="noopener noreferrer">
          こちら(電気需給約款(低圧))
        </a>
        と
        <a href={'/static/base_price_definitions.pdf'} target="_blank" rel="noopener noreferrer">
          こちら(電気料金プラン定義書)
        </a>
        をご覧ください。
        <br />
        <br />
        ・都市ガスの供給に係るガスの料金、その他の供給条件は、「一般ガス供給約款」または「ガス小売供給約款」および「ガス料金プラン定義書」によります。詳細は以下をご覧ください。
        <a href={'https://www.keiyogas.co.jp/home/ryoukin/menu/yakkan.html'} target="_blank" rel="noopener noreferrer">
          こちら
        </a>
        をご覧ください。
        <br />
        <br />
        ・駆けつけサービスの利用条件は、駆けつけサービス契約約款によります。詳細は
        <a href={'/static/seurity_service_term.pdf'} target="_blank" rel="noopener noreferrer">
          駆けつけサービス契約約款
        </a>
        をご覧ください。
        <br />
        <br />
        <br />
        ■その他の商品の販売条件、役務の提供条件
        <br />
        電気（スマモル賃貸プランに限ります。）および駆けつけサービス/優待・割引サービスは、当社が指定する、物件の管理等を行う事業者からの紹介があり、当社が適当と判断した場合にのみご利用いただけます。
        <br />
        <br />
      </p>

      <p className={styles.body}>
        ■会社名
        <br />
        大阪ガスセキュリティサービス株式会社
        <br />
        <br />
        ■代表者
        <br />
        代表取締役社長 　米田 吉克
        <br />
        <br />
        ■所在地
        <br />
        大阪市淀川区十三本町３−６−５
        <br />
        <br />
        ■電話番号
        <br />
        ０１２０-７５０−０８２
        <br />
        <br />
        ■Eメールアドレス
        <br />
        auto-reply@mail.cdesumamoru.entryservice.jp
        <br />
        <br />
        ■商品・役務の提供時期
        <br />
        ・駆けつけサービス…電気の供給開始日より、提供いたします。
        <br />
        <br />
        ■お申込み有効期限
        <br />
        ・電気の申込有効期限に準じます
        <br />
        <br />
        ■商品・役務の対価、対価の支払時期及び方法、提供条件
        <br />
        ・駆けつけサービスの利用条件は、駆けつけサービス契約約款によります。詳細は
        <a href={'/static/seurity_service_term.pdf'} target="_blank" rel="noopener noreferrer">
          駆けつけサービス契約約款
        </a>
        をご覧ください。
        <br />
        <br />
        <br />
        ■その他の商品の販売条件、役務の提供条件
        <br />
        ・駆けつけサービスは、当社が指定する、物件の管理等を行う事業者からの紹介があり、当社が適当と判断した場合にのみご利用いただけます。
        <br />
        <br />
      </p>
    </div>
  );
};

export default Tokushoho;
