import * as React from 'react';
import { useMemo, useContext, useCallback, useState, useEffect } from 'react';
import * as AutoKana from 'vanilla-autokana';
import { zenkakuRegex, zenkakuKanaRegex } from '../utils/regex';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { ItemValueKeySuffix } from '../../enums/common/item-value-key-suffix';
import { ApplyTextField } from '../baseform/ApplyTextField';
import { ApplyLabel } from '../Label';

interface P {
  componentElement: ComponentInputElement;
}

const KanaNameAutoCompleteInpuComponent: React.FC<P> = ({ componentElement }) => {
  const { inputValues, updateInputValues } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);
  const lastNameColumn = useMemo(() => `${valueKey}LastName`, []);
  const firstNameColumn = useMemo(() => `${valueKey}FirstName`, []);
  const lastNameKanaColumn = useMemo(() => `${valueKey}LastNameKana`, []);
  const firstNameKanaColumn = useMemo(() => `${valueKey}FirstNameKana`, []);

  const lastNameHasErrorColumn = useMemo(() => lastNameColumn + ItemValueKeySuffix.HasError, []);
  const firstNameHasErrorColumn = useMemo(() => firstNameColumn + ItemValueKeySuffix.HasError, []);
  const lastNameKanaHasErrorColumn = useMemo(() => lastNameKanaColumn + ItemValueKeySuffix.HasError, []);
  const firstNameKanaHasErrorColumn = useMemo(() => firstNameKanaColumn + ItemValueKeySuffix.HasError, []);

  const lastNameValue = useMemo(() => inputValues[lastNameColumn] || '', [inputValues[lastNameColumn]]);
  const firstNameValue = useMemo(() => inputValues[firstNameColumn] || '', [inputValues[firstNameColumn]]);
  const lastNameKanaValue = useMemo(() => inputValues[lastNameKanaColumn] || '', [inputValues[lastNameKanaColumn]]);

  const firstNameKanaValue = useMemo(() => inputValues[firstNameKanaColumn] || '', [inputValues[firstNameKanaColumn]]);

  const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [lastNameKanaErrorMessage, setLastNameKanaErrorMessage] = useState('');
  const [firstNameKanaErrorMessage, setFirstNameKanaErrorMessage] = useState('');

  // inputValueから取得した値を直接使うとhandleできねーといわれるのでlocalをもたせて対応
  const [lastNameLocal, setLastNameLocal] = useState(lastNameValue);
  const [firstNameLocal, setFirstNameLocal] = useState(firstNameValue);
  const [lastNameKanaLocal, setLastNameKanaLocal] = useState(lastNameKanaValue);
  const [firstNameKanaLocal, setFirstNameKanaLocal] = useState(firstNameKanaValue);

  // AutoKana関連の部分
  const [autoKana, setAutoKana] = useState<{
    lastName: AutoKana.AutoKana;
    firstName: AutoKana.AutoKana;
  }>();

  useEffect(() => {
    setAutoKana({
      lastName: AutoKana.bind(`#${lastNameColumn}`, `#${lastNameKanaColumn}`, {
        katakana: true
      }),
      firstName: AutoKana.bind(`#${firstNameColumn}`, `#${firstNameKanaColumn}`, {
        katakana: true
      })
    });
  }, []);

  const checkLastNameError = useCallback(
    (lastName: string) => {
      let error = true;
      if (!lastName || lastName === '') {
        error = false;
        setLastNameErrorMessage('');
      } else if (!lastName.match(zenkakuRegex)) {
        setLastNameErrorMessage('全角で入力してください');
      } else if (lastName.length > 10) {
        setLastNameErrorMessage('10文字以内で入力してください');
      } else {
        error = false;
        setLastNameErrorMessage('');
      }
      checkFirstNameError(firstNameValue);
      updateInputValues(lastNameHasErrorColumn, error);
    },
    [lastNameColumn, firstNameValue]
  );

  const checkFirstNameError = useCallback(
    (firstName: string) => {
      let error = true;
      if (!firstName || firstName === '') {
        error = false;
        setFirstNameErrorMessage('');
      } else if (!firstName.match(zenkakuRegex)) {
        setFirstNameErrorMessage('全角で入力してください');
      } else if (firstName.length > 10) {
        setFirstNameErrorMessage('10文字以内で入力してください');
      } else if (firstName.length + lastNameValue.length > 14) {
        setFirstNameErrorMessage('氏名の合計を14文字以内で入力してください。');
      } else {
        error = false;
        setFirstNameErrorMessage('');
      }
      checkLastNameKanaError(lastNameKanaValue);
      updateInputValues(firstNameHasErrorColumn, error);
    },
    [lastNameValue, lastNameKanaValue]
  );

  const checkLastNameKanaError = useCallback(
    (lastNameKana: string) => {
      let error = true;
      if (!lastNameKana || lastNameKana === '') {
        error = false;
        setLastNameKanaErrorMessage('');
      } else if (!lastNameKana.match(zenkakuKanaRegex)) {
        setLastNameKanaErrorMessage('全角カナで入力してください');
      } else if (lastNameKana.length > 10) {
        setLastNameKanaErrorMessage('10文字以内で入力してください');
      } else {
        error = false;
        setLastNameKanaErrorMessage('');
      }
      checkFirstNameKanaError(firstNameKanaValue);
      updateInputValues(lastNameKanaHasErrorColumn, error);
    },
    [firstNameKanaValue]
  );

  const checkFirstNameKanaError = useCallback(
    (firstNameKana: string) => {
      let error = true;
      if (!firstNameKana || firstNameKana === '') {
        error = false;
        setFirstNameKanaErrorMessage('');
      } else if (!firstNameKana.match(zenkakuKanaRegex)) {
        setFirstNameKanaErrorMessage('全角カナで入力してください');
      } else if (firstNameKana.length > 10) {
        setFirstNameKanaErrorMessage('10文字以内で入力してください');
      } else if (firstNameKana.length + lastNameKanaValue.length > 14) {
        setFirstNameKanaErrorMessage('フリガナの合計を14文字以内で入力してください。');
      } else {
        error = false;
        setFirstNameKanaErrorMessage('');
      }
      updateInputValues(firstNameKanaHasErrorColumn, error);
    },
    [lastNameKanaValue]
  );

  // フォーカスが外れた際にstateの更新とエラーチェック処理を行う
  const handleLastNameChange = useCallback(
    (name: string) => {
      updateInputValues(lastNameColumn, name);
      setLastNameLocal(name);
      const kana = autoKana && autoKana.lastName.getFurigana();
      updateInputValues(lastNameKanaColumn, kana);
      setLastNameKanaLocal(kana);
    },
    [updateInputValues, autoKana]
  );
  const handleFirstNameChange = useCallback(
    (name: string) => {
      updateInputValues(firstNameColumn, name);
      setFirstNameLocal(name);
      const kana = autoKana && autoKana.firstName.getFurigana();
      updateInputValues(firstNameKanaColumn, kana);
      setFirstNameKanaLocal(kana);
    },
    [updateInputValues, autoKana]
  );
  const handleLastNameKanaChange = useCallback(
    (name: string) => {
      updateInputValues(lastNameKanaColumn, name);
      setLastNameKanaLocal(name);
    },
    [updateInputValues]
  );
  const handleFirstNameKanaChange = useCallback(
    (name: string) => {
      updateInputValues(firstNameKanaColumn, name);
      setFirstNameKanaLocal(name);
    },
    [updateInputValues]
  );

  return (
    <>
      <ApplyLabel text={'氏名'} />
      <ApplyTextField
        id={lastNameColumn}
        placeholder={'姓'}
        value={lastNameLocal}
        error={lastNameErrorMessage !== ''}
        helperText={lastNameErrorMessage}
        onChange={value => handleLastNameChange(value)}
        onBlur={value => checkLastNameError(value)}
      />
      <ApplyTextField
        id={firstNameColumn}
        placeholder={'名'}
        value={firstNameLocal}
        error={firstNameErrorMessage !== ''}
        helperText={firstNameErrorMessage}
        onChange={value => handleFirstNameChange(value)}
        onBlur={value => checkFirstNameError(value)}
      />
      <ApplyLabel text={'フリガナ'} />
      <ApplyTextField
        id={lastNameKanaColumn}
        placeholder={'セイ'}
        value={lastNameKanaLocal}
        error={lastNameKanaErrorMessage !== ''}
        helperText={lastNameKanaErrorMessage}
        onChange={value => handleLastNameKanaChange(value)}
        onBlur={value => checkLastNameKanaError(value)}
      />
      <ApplyTextField
        id={firstNameKanaColumn}
        placeholder={'メイ'}
        value={firstNameKanaLocal}
        error={firstNameKanaErrorMessage !== ''}
        helperText={firstNameKanaErrorMessage}
        onChange={value => handleFirstNameKanaChange(value)}
        onBlur={value => checkFirstNameKanaError(value)}
      />
    </>
  );
};

export default React.memo(KanaNameAutoCompleteInpuComponent);
