import * as React from 'react';
import { useMemo, useContext, useEffect } from 'react';
import { RadioGroup } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';
import { getComponentElementKeyValue } from '../utils/key-value-getter';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { CustomRadioButton, BlueRadio } from '../CustomRadioButton';

interface P {
  componentElement: ComponentInputElement;
}

const useStyles = makeStyles(
  createStyles({
    radioGroupSelectionContainer: {
      margin: '8px 0 0',
      '& .MuiTypography-root': {
        fontSize: 14
      }
    },
    activeRadioBorder: {
      border: '2px solid var(--color-key) !important'
    }
  })
);

const RadioGroupComponent: React.FC<P> = ({ componentElement }) => {
  const styles = useStyles({});
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);

  const inputValue = useMemo(
    () =>
      getComponentElementKeyValue({
        inputValues,
        applicationParams,
        inputValueType: componentElement.valueType,
        valueKey: valueKey,
        initialValue: componentElement.initialValue
      }),
    [
      inputValues[valueKey],
      applicationParams[valueKey],
      componentElement.valueType,
      valueKey,
      componentElement.initialValue
    ]
  );

  const isDisable = useMemo(
    () =>
      checkComponentElementCondition({
        conditions: componentElement.isDisableCondition,
        inputValues: inputValues,
        applicationParams: applicationParams,
        initialValue: false
      }),
    [inputValues, applicationParams]
  );

  // 初期値の補完
  useEffect(() => {
    if (inputValues[valueKey] || !componentElement.initialValue || isDisable) {
      return;
    }
    updateInputValues(valueKey, componentElement.initialValue);
  }, []);

  return (
    <>
      <RadioGroup
        className={styles.radioGroupSelectionContainer}
        aria-label={`radio-group-${valueKey}`}
        name={`radio-group-${valueKey}`}
        value={inputValue}
        onChange={e => updateInputValues(valueKey, e.target.value)}
      >
        {componentElement.selectionItems &&
          componentElement.selectionItems
            .filter(item => !item.notDisplay)
            .map(item => (
              <CustomRadioButton
                className={
                  (inputValues[valueKey] || componentElement.initialValue) === item.value
                    ? styles.activeRadioBorder
                    : ''
                }
                key={`radio-${valueKey}-${item.value}`}
                label={item.label}
                value={item.value}
                control={<BlueRadio />}
                disabled={isDisable || item.disabled}
              />
            ))}
      </RadioGroup>
    </>
  );
};

export default React.memo(RadioGroupComponent);
