import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Index from '../container';
import { DesktopBackground } from '../components/atoms/DesktopBackground';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'var(--color-white)',
      textAlign: 'center',
      '@media (min-width:768px)': {
        borderRadius: 10
      }
    }
  })
);

const InitialScreen: React.FC<{}> = () => {
  const styles = useStyles();

  return (
    <>
      <DesktopBackground>
        <div className={styles.container}>
          <Index />
        </div>
      </DesktopBackground>
    </>
  );
};

export default InitialScreen;
