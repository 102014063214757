import React from 'react';
import InitialScreen from './screens/InitialScreen';
import ApplicationScreen from './screens/application/ApplyScreen';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SignupScreen from './screens/account/SignupScreen';
import SigninScreen from './screens/account/SigninScreen';
import CompletedScreen from './screens/account/CompletedScreen';
import ValidationScreen from './container/validationScreen';

import { Path } from './constants/path';
import { connect } from 'react-redux';
import { AppState } from './store';
import OrderCompleteScreen from './screens/application/ApplyCompleteScreen';
import ApplyConfirmScreen from './screens/application/ApplyConfirmScreen';
import NotFoundTemplate from './screens/others/NotFound';
import Tokushoho from './config/Tokushoho';
import Terms from './screens/others/Terms';
import SitePolicy from './config/SitePolicy';
import ExplanationMatters from './screens/others/ExplanationMatters';
import RedirectWithDecodeURL from './screens/RedirectWithDecodeURL';
import Identification from './screens/identification/Identification';
import ImportantTerm from './screens/others/ImportantTerm';

const mapStateToProps = (state: AppState) => {
  return {
    index: state.index,
    property: state.property,
    signUpForm: state.signUpForm,
    signInForm: state.signInForm,
    applyForm: state.applyForm,
    validation: state.validation,
    user: state.user
  };
};

type Props = ReturnType<typeof mapStateToProps>;

const App: React.FC<Props> = props => {
  return (
    <div>
      <Router>
        <Switch>
          <Route path={Path.redirect} component={RedirectWithDecodeURL} />
          <Route path={Path.identification} component={Identification} />
          <Route exact path={Path.home} component={InitialScreen} />
          <Route
            exact
            path={Path.account.signup}
            render={() => <SignupScreen hasElectricContract={props.property.property.hasElectricContract} />}
          />
          <Route
            exact
            path={Path.account.signin}
            render={() => <SigninScreen hasElectricContract={props.property.property.hasElectricContract} />}
          />
          <Route exact path={Path.account.validation} component={ValidationScreen} />
          <Route
            exact
            path={Path.account.completed}
            render={() => <CompletedScreen hasElectricContract={props.property.property.hasElectricContract} />}
          />
          <Route
            exact
            path={Path.application.url}
            render={() => <ApplicationScreen hasElectricContract={props.property.property.hasElectricContract} />}
          />
          <Route
            exact
            path={Path.application.confirm}
            render={() => <ApplyConfirmScreen hasElectricContract={props.property.property.hasElectricContract} />}
            on
          />
          <Route
            exact
            path={Path.application.complete}
            render={() => (
              <OrderCompleteScreen
                hasElectricContract={props.property.property.hasElectricContract}
                sumamoruInstallAppTypes={props.property.property.sumamoruInstallAppTypes}
              />
            )}
          />
          <Route exact path={Path.other.terms} component={Terms} />
          <Route exact path={Path.other.tokushoho} component={Tokushoho} />
          <Route exact path={Path.other.importantTerm} component={ImportantTerm} />
          <Route exact path={Path.other.explanation_matters} component={ExplanationMatters} />
          <Route exact path={Path.other.sitePolicy} component={SitePolicy} />
          <Route component={NotFoundTemplate} />
        </Switch>
      </Router>
    </div>
  );
};
export default connect(mapStateToProps, {})(App);
