enum ActionType {
  setEmail = 'SET_EMAIL',
  setPhoneNumber = 'SET_PHONE_NUMBER',
  setAccessToken = 'SET_ACCESS_TOKEN',
  setBirthday = 'SET_BIRTHDAY',
  setUserId = 'SET_USER_ID',
  clearUser = 'CLEAR_USER',
  setRefreshToken = 'SET_REFRESH_TOKEN'
}

export default ActionType;
