import * as React from 'react';
import { useMemo, useEffect } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';
import { baseFormText, confirmationText } from '../../config/item-definitions';
import { ComponentDescriptionType } from '../../enums/common/component-description-type';
import { ItemValueKey } from '../../config/item-value-key';
import { ValueOriginType } from '../../enums/common/value-origin-type';
import { getComponentElementKeyValue } from '../utils/key-value-getter';

interface P {
  inputValues: any;
  updateInputValues: any;
  applicationParams: any;
}

const useStyles = makeStyles(
  createStyles({
    termsCaption: {
      fontSize: 14,
      lineHeight: '21px',
      color: 'var(--color-text)',
      '& a': {
        textDecoration: 'none',
        color: 'var(--color-key)',
        fontWeight: 'bold'
      }
    },
    agreeCheckbox: {
      display: 'block',
      margin: 0,
      textAlign: 'center',
      '& .MuiTypography-root': {
        color: 'var(--color-text)',
        fontSize: 14
      },
      '& .MuiCheckbox-root': {
        color: 'var(--color-key)'
      }
    }
  })
);

/**
 *
 * 規約などの説明文を確認した後に、同意ボタンをクリックして先にすすためのコンポーネント
 *
 */
const AgreeTermElement: React.FC<P> = props => {
  const styles = useStyles({});
  const valueKey = ItemValueKey.AgreeThePolicy;
  const { inputValues, updateInputValues, applicationParams } = props;

  const visibleContents = useMemo(() => {
    const test = confirmationText.filter(unit =>
      checkComponentElementCondition({
        conditions: unit.isDisplay,
        inputValues: inputValues,
        applicationParams: applicationParams,
        initialValue: true
      })
    );
    return test;
  }, [inputValues, applicationParams]);

  const inputValue = useMemo(() => {
    const value = getComponentElementKeyValue({
      inputValues,
      applicationParams,
      inputValueType: ValueOriginType.InputValues,
      valueKey: valueKey,
      initialValue: false
    });
    return value;
  }, [inputValues[valueKey], applicationParams[valueKey], valueKey]);

  // 初期値の補完
  useEffect(() => {
    updateInputValues(valueKey, false);
  }, []);

  const updateUserInput = React.useCallback(
    (value: any) => {
      updateInputValues(valueKey, value);
    },
    [updateInputValues]
  );

  return (
    <p className={styles.termsCaption}>
      {baseFormText.useInformationConfirmation.map(text => (
        <>
          {text}
          <br />
        </>
      ))}
      <br />
      {visibleContents.map(unit => (
        <>
          {unit.contents.map(unitList => (
            <>
              {unitList.map(unit => (
                <>
                  {unit.type == ComponentDescriptionType.Normal ? (
                    <>{unit.text}</>
                  ) : unit.type == ComponentDescriptionType.ExternalLink ? (
                    <a target="_blank" rel="noopener noreferrer" href={unit.link}>
                      {unit.text}
                    </a>
                  ) : (
                    <a href={unit.path} target="_blank" rel="noopener noreferrer">
                      {unit.text}
                    </a>
                  )}
                </>
              ))}
              <br />
            </>
          ))}
        </>
      ))}
      <br />
      <FormControlLabel
        className={styles.agreeCheckbox}
        control={
          <Checkbox color="primary" checked={inputValue || false} onChange={e => updateUserInput(e.target.checked)} />
        }
        label={'同意する'}
      />
    </p>
  );
};

export default React.memo(AgreeTermElement);
