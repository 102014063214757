import BitkeyPlatformAPI, { ActivateUserResponse } from './bitkey-platform-api';

export enum AuthServiceError {
  BKP_INVALID_CREDENTIAL = 'BKP_INVALID_CREDENTIAL',
  BKP_EMAIL_INVALID = 'EMAIL_INVALID',
  BKP_FAILED_VERIFY_PASSCODE = 'FAILED_VERIFY_PASSCODE',
  BKP_FAILED_ALREADY_EXIST = 'FAILED_ALREADY_EXIST'
}

class AuthServiceImpl {
  public preRegisterUser = async (email: string): Promise<string> => {
    try {
      const res = await BitkeyPlatformAPI.addUser({
        email,
        mailTemplate:'other'
      });
      return res.signupSessionId;
    } catch (e) {
      if (e.status === 409) {
        throw AuthServiceError.BKP_FAILED_ALREADY_EXIST;
      } else if (e.status === 400) {
        throw AuthServiceError.BKP_EMAIL_INVALID;
      }
      throw e;
    }
  };

  public preRegisterUserWithPhoneNumber = async (phoneNumber: string): Promise<string> => {
    try {
      const res = await BitkeyPlatformAPI.addUser({
        phone: phoneNumber,
        mailTemplate:'other'
      });
      return res.signupSessionId;
    } catch (e) {
      if (e.status === 409) {
        throw AuthServiceError.BKP_FAILED_ALREADY_EXIST;
      } else if (e.status === 400) {
        throw AuthServiceError.BKP_EMAIL_INVALID;
      }
      throw e;
    }
  };

  public verifyPasscode = async (
    bkpSessionId: string,
    passcode: string,
    password: string
  ): Promise<ActivateUserResponse> => {
    try {
      const bkpAuth = await BitkeyPlatformAPI.activateUser({
        authorizationCode: passcode,
        password,
        public: true,
        signupSessionId: bkpSessionId
      });
      BitkeyPlatformAPI.setTokens(bkpAuth.accessToken, bkpAuth.refreshToken);

      return bkpAuth;
    } catch (e) {
      if (e.status === 401) {
        console.log('コードが異なります');
        throw AuthServiceError.BKP_FAILED_VERIFY_PASSCODE;
      }
      throw e;
    }
  };

  /**
   * こっち使う場合は色々な観点からfirebaseとbkpの両方に対してこのタイミングでauthを通す。
   * @param email
   * @param password
   */
  public loginWithEmail = async (
    email: string,
    password: string
  ): Promise<{ accessToken: string; refreshToken: string; userId: string }> => {
    // SlackTrace.trigger(email);
    try {
      /**
       * Login to BKP
       */
      const bkpAuth = await BitkeyPlatformAPI.authenticateUser({
        email,
        password
      }).catch(e => {
        // console.error(e);
        throw e;
      });

      BitkeyPlatformAPI.setTokens(bkpAuth.accessToken, bkpAuth.refreshToken);

      return {
        accessToken: bkpAuth.accessToken,
        refreshToken: bkpAuth.refreshToken,
        userId: bkpAuth.userId
      };
    } catch (e) {
      throw e;
    }
  };
  /**
   * 電話番号によるログイン試行
   * @param phoneNumber
   * @param password
   */
  public loginWithPhoneNumber = async (
    phoneNumber: string,
    password: string,
  ): Promise<{ accessToken: string; refreshToken: string; userId: string }> => {
  try {
    /**
     * 090XXXXXXXXを8190XXXXXXXXに変換してあげないとBKPの認証通らない
     * TODO BKPのバグ直ったらここ直すこと
     */
    const convertedPhoneNumber = "81" + phoneNumber.slice(1);
    /**
     * Login to BKP
     */
    const bkpAuth = await BitkeyPlatformAPI.authenticateUser({
      phone: convertedPhoneNumber,
      password
    }).catch(e => {
      // console.error(e);
      throw e;
    });

    BitkeyPlatformAPI.setTokens(bkpAuth.accessToken, bkpAuth.refreshToken);

    return {
      accessToken: bkpAuth.accessToken,
      refreshToken: bkpAuth.refreshToken,
      userId: bkpAuth.userId
    };
  } catch (e) {
    throw e;
  }
};

  public logout = async () => {
    // localからトークンを消す
  };

  public changeEmail = async (newEmail: string): Promise<boolean> => {
    return BitkeyPlatformAPI.startUpdateUserEmail({
      email: newEmail
    })
      .then(async () => {
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  };

  public changePassword = async (oldPassword: string, newPassword: string) => {
    const personaId =
      'どっかからとってくるpersonaId おそらくローカルストレージ';
    await BitkeyPlatformAPI.updatePersonaPassword(personaId, {
      newPassword,
      oldPassword
    }).catch(e => {
      throw new Error('パスワードの変更に失敗');
    });

    return true;
  };

  public async issuePasswordReset(
    email: string,
    personaId?: string
  ): Promise<void> {
    await BitkeyPlatformAPI.issuePasswordResetToken({
      email,
      personaId
    });
  }

  public async passwordReset(
    resetToken: string,
    email: string,
    password: string
  ): Promise<void> {
    await BitkeyPlatformAPI.resetPassword({
      password,
      resetToken
    });
  }
}

const AuthService = new AuthServiceImpl();

export default AuthService;
