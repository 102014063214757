import React from 'react';
import { Container } from '@material-ui/core';
import BaseStepper from '../../components/BaseStepper';
import ApplyForm from '../../container/applyForm';
import { DesktopBackground } from '../../components/atoms/DesktopBackground';

interface Props {
  hasElectricContract?: boolean;
}
const ApplyScreen: React.FC<Props> = ({ hasElectricContract }) => {
  return (
    <DesktopBackground>
      <BaseStepper step={1} hasElectoric={hasElectricContract} />
      <Container>
        <ApplyForm />
      </Container>
    </DesktopBackground>
  );
};

export default ApplyScreen;
