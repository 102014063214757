import { connect } from 'react-redux';
import * as Actions from '../modules/signIn/actions';
import * as module from '../modules/signIn';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import { SignInForm } from '../components/SignInForm';
import { TextFieldStatus } from '../modules/signIn/types';
import * as propertyModule from '../modules/property';

export const mapStateToProps = (state: AppState) => {
  return {
    identifyValue: module.Selectors.getIdentifyValue(state),
    identifyValueStatus: module.Selectors.getIdentifyValueStatus(state),
    password: module.Selectors.getPassword(state),
    passwordStatus: module.Selectors.gettPasswordStatus(state),
    status: module.Selectors.getStatus(state),
    property: propertyModule.Selectors.getProperty(state)
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) => ({
  updateSigninIdentifyValue: (value: string) => dispatch(Actions.updateSigninIdentifyValue(value)),
  updateSigninPassword: (value: string) => dispatch(Actions.updateSigninPassword(value)),
  submitLoginForm: () => dispatch(module.operations.submitLoginForm()),
  validateIdentifyValue: (s: TextFieldStatus) => dispatch(Actions.validateSigninIdentifyValue(s)),
  validatePassword: (s: TextFieldStatus) => dispatch(Actions.validateSigninPassword(s)),
  getIds: () => dispatch(propertyModule.operations.setIdsFromCookie()),
  getProperty: () => dispatch(propertyModule.operations.getProperty())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInForm);
