enum ActionType {
  updateEmail = 'UPDATE_EMAIL',
  updatePhoneNumber = 'UPDATE_PHONE_NUMBER',
  updatePassword = 'UPDATE_PASSWORD',
  startToSignup = 'START_TO_SIGNUP',
  signupSuccess = 'SIGNUP_SUCCESS',
  signupFailed = 'SIGNUP_FAILED',
  validateSignupEmail = 'VALIDATE_SIGNUP_EMAIL',
  validateSignupPhoneNumber = 'VALIDATE_SIGNUP_PHONE_NUMBER',
  validateSignupPassword = 'VALIDATE_SIGNUP_PASSWORD',
  clearSignupStatus = 'CLEAR_SIGNUP_STATUS',
  changeSignupMethod = 'CHANGE_SIGNUP_METHOD'
}

export type SignupStatus = 'not_signup' | 'signup_loading' | 'signup' | SignupErrorStatus;

export type SignupErrorStatus = 'internal' | 'already_exist';

export type TextFieldStatus = 'empty' | 'valid' | 'invalid';

export enum SignupMethod {
  Email = 'Email',
  PhoneNumber = 'PhoneNumber'
}

export default ActionType;
