import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import { ApplicationStatus } from '../../enums/application-status';

const useStyles = makeStyles(() =>
  createStyles({
    errorMessage: {
      backgroundColor: '#E91E63',
      color: '#FFFFFF',
      padding: '1px 12px',
      borderRadius: 4,
      textAlign: 'left',
      margin: '16px 0'
    },
    errorTitle: {
      fontWeight: 'bold',
      fontSize: '18px',
      display: 'inline-flex'
    },
    errorDesc: {
      fontWeight: 'normal',
      fontSize: '14px',
      marginTop: '0'
    },
    editButton: {
      color: 'var(--color-key)',
      position: 'absolute',
      fontSize: 14,
      top: 4,
      right: 0
    },
    warningIcon: {
      marginRight: '10px',
      marginTop: '2px'
    }
  })
);

interface P {
  status: ApplicationStatus;
}

/**
 *
 * 申し込み確認画面にて申し込みステータスに応じて画面上部に表示する警告・エラー情報
 *
 */
const ApplicationStatusHeader: React.FC<P> = ({ status }) => {
  const styles = useStyles({});

  return (
    <>
      {status === ApplicationStatus.Unauthorized && (
        <div className={styles.errorMessage}>
          <h3 className={styles.errorTitle}>
            <WarningIcon className={styles.warningIcon} />
            再ログインしてください
          </h3>
        </div>
      )}
      {status === ApplicationStatus.Internal && (
        <div className={styles.errorMessage}>
          <h3 className={styles.errorTitle}>
            <WarningIcon className={styles.warningIcon} />
            申し込みに失敗しました。
          </h3>
          <p className={styles.errorDesc}>
            もう一度時間を置いてお試しください。
            <br />
            なんども失敗する場合はサポートまでご連絡ください。
          </p>
        </div>
      )}
      {status === ApplicationStatus.Conflict && <p className={styles.errorMessage}>既に申し込み済みです</p>}
    </>
  );
};

export default React.memo(ApplicationStatusHeader);
