import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { bitlockVoApiKey, bitlockVoApiPath } from '../../config/baseConfig';
import { Property } from '../../modules/property/types';
import { PrefectureJPMap } from '../../utils/address-types';
import { Loading } from '../../components/atoms/Loading';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box'
    },
    managementContainer: {
      backgroundColor: '#ECEFF1',
      padding: 20,
      boxSizing: 'border-box',
      '& p': {
        fontSize: 15,
        color: 'var(--color-gray-1)',
        lineHeight: '22px',
        margin: 0
      }
    },
    managementHeading: {
      color: '#828282 !important',
      fontWeight: 'bold',
      marginBottom: '8px !important'
    },
    body: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '21px'
    }
  })
);

type status = 'not_load' | 'loading' | 'load' | 'error';

const ExplanationMatters: React.FC<{}> = () => {
  const styles = useStyles({});
  const history = useHistory();
  const [status, setStatus] = useState<status>('not_load');
  const [propertyData, setPropertyData] = useState<Property>();

  useEffect(() => {
    if (status === 'not_load') {
      const params = new URLSearchParams(history.location.search);
      const allSpaceId = params.get('s') || '';
      const contractId = params.get('c') || '';

      if (allSpaceId === '' || contractId === '') {
        setStatus('error');
        return;
      }

      const endpoint = `${bitlockVoApiPath}/spaces/${allSpaceId}/contracts/${contractId}`;
      const apiKey = bitlockVoApiKey;
      const headers = {
        'x-vo-api-key': apiKey,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
      const method = 'GET';

      setStatus('loading');

      fetch(endpoint, {
        method,
        headers,
        mode: 'cors'
      })
        .then(res => {
          if (res.status !== 200) {
            setStatus('error');
            return;
          }
          // tslint:disable-next-line: no-floating-promises
          res.json().then(json => {
            setPropertyData({
              allSpaceId: allSpaceId,
              contractId: contractId,
              applicationStatus: json.data.applicationStatus,
              hasElectricContract: json.data.hasElectricContract,
              occupyScheduledDate: new Date(json.data.occupyScheduledDate),
              postCode: json.data.postCode,
              address: json.data.address,
              prefecture: PrefectureJPMap[json.data.prefecture],
              city: json.data.city,
              afterCity: json.data.afterCity,
              buildingName: json.data.buildingName,
              propertyName: json.data.name,
              propertyCode: json.data.code,
              sumamoruCode: json.data.sumamoruCode,
              status: json.data.status,
              managementCompanyAddress: json.data.managementCompanyAddress,
              managementCompanyName: json.data.managementCompanyName,
              managementCompanyPhoneNumber: json.data.managementCompanyPhoneNumber,
              sumamoruInstallAppTypes: json.data.sumamoruInstallAppTypes
            });
            setStatus('load');
          });
        })
        .catch(error => {
          console.error(error);
          setStatus('error');
        });
    }
  }, [status, history]);

  return (
    <div className={styles.container}>
      <h1>重要事項説明書</h1>
      <p className={styles.body}>
        ■契約の申込みについて
        <br />
        ・当社と電気需給契約を締結することを希望される場合は、当社または当社の指定店に直接お申込みいただくほか、電話、インターネット等によりお申込みいただけます。
        <br />
        ・当社は、電気の需給状況、供給設備の状況、料金のお支払い状況（すでに消滅しているものを含む当社との他の契約の料金について支払期日を経過してもお支払いがない場合を含みます。）その他によってやむをえない場合および当社が適当でないと判断した場合には、当社はお申込みを承諾できないことがあります。
        <br />
        ・お客さまが同一の需要場所において電気の購入先を他の小売電気事業者から当社に変更される場合には、現在ご契約中の小売電気事業者への解約連絡は当社が代行して行います。お引越しに伴い電気の購入先を変更される場合には、お引越し前の需要場所の電気の解約手続きはお客さまご自身で行っていただく必要があります。
        <br />
        <br />
        ■契約の成立および契約期間について
        <br />
        ・インターネットによるお申込みの場合は、本申込み画面よりお申込みいただき、受付完了画面が表示されたときに契約が成立いたします。
        <br />
        ・契約期間はご契約された料金メニュー・オプション割引に別段の定めがある場合を除き、需給契約が成立した日から、需給開始日以降1年目の日までといたします。
        <br />
        ■契約内容について
        <br />
        ・契約内容の詳細は当社の電気供給約款によるものといたします。
        <br />
        ・当社は電気事業法上において定められている契約締結前及び契約締結後の書面交付について、電子メールまたは書面でお知らせする事項を除いては、書面交付に代えて、電気供給約款を当社ホームページに掲載する方法によりこれを提供いたします。
        <br />
        ・当社は、電気供給約款を変更することがあります。お客さまは、変更後の電気供給約款に異議がある場合、解約することができます。
        <br />
        ・電気供給約款または需給契約の内容を変更する場合は、その変更内容をお客さまにお知らせいたします。その際には、供給条件の説明を、書面の交付、インターネット上での開示または電子メールの送信その他当社が適当と判断した方法により行い、当該変更をしようとする必要事項のみを説明し、記載することについてあらかじめ承諾していただきます。
        <br />
        <br />
        ■供給開始時期について
        <br />
        ・電気需給契約の締結後、現在ご契約中の小売電気事業者との解約や送配電事業者との託送供給契約の締結等、当社による必要な手続きが完了した時点で、供給開始予定日を改めてお知らせいたします。他社から切り替えられる場合の供給開始予定日は、スマートメーターが既に設置されている場合はお申込みから2～3週間後、スマートメーターが現在設置されていない場合はお申込みから2週間～1ヵ月半後となります。なお、手続きの都合により、供給開始予定日のご案内が供給開始後となる場合があります。また、お知らせした供給開始予定日は、手続きの都合により変更となる場合があります。あらかじめご了承ください。
        <br />
        ・現在スマートメーターが設置されていない場合は、送配電事業者がスマートメーターを設置いたします。
        <br />
        ・供給開始予定日より前にお申込みをキャンセルされる場合は、供給開始予定日の2営業日前までに当社へその旨をお申し出いただく必要があります。
        <br />
        <br />
        ■料金メニュー・オプション割引の適用条件について
        <br />
        ・料金メニューおよびオプション割引は、お客さまからのお申込みに基づき適用条件を満たす場合に適用いたします。
        <br />
        ・機器の設置を適用条件とする料金メニューをお申込みされる場合は、対象機器の所有状況の確認のため、当社よりお客さまへご連絡させていただく場合があります。また、今後当社が必要とする場合は対象機器の実際の所有状況の確認にご協力いただくことについて承諾していただきます。
        <br />
        ・対象機器の撤去や当社と締結するガスの使用契約の解約等で料金メニューまたはオプション割引の適用条件を満たさなくなった場合は、すみやかにその旨を当社へ連絡していただきます。この場合、料金メニューまたはオプション割引の適用は当社が通知を受けた直後の検針日の前日までといたします。
        <br />
        ・料金メニューまたはオプション割引の適用条件を満たさないで電気をご使用の場合、電気供給約款に基づき本来お支払いいただくべきであった金額とすでに料金としてお支払いいただいた金額との差額を精算させていただきます。
        <br />
        <br />
        ■料金について
        <br />
        ・電気料金には毎月、燃料費調整額を加減いたします。また、電気料金の一部として、電気をご使用のお客さまに電気のご使用量に応じて再生可能エネルギー発電促進賦課金をご負担いただきます。
        <br />
        ・ホームページやチラシ等に記載される料金メリットは一定の試算条件に基づくものとなります。電気のご使用量によってはこれまでの料金と比べメリットが出ない場合がありますので、電気料金シミュレーションの実施をお勧めいたします。また電気料金シミュレーション結果は、お客さまの電気使用実績に基づく推定値となります。ご使用状況や気候の変化等による電気ご使用量の変動、燃料費調整額等の事由により、電気料金シミュレーション結果と実際の電気料金は異なります。
        <br />
        <br />
        ■料金算定の方法と料金のお支払いについて
        <br />
        ・検針および使用量の算定は、送配電事業者により託送供給等約款に従って行われます。その結果を当社が受け取り当社の電気供給約款の定めに基づき電気料金を算定いたします。電気料金の計算方法については、こちらをご確認ください。
        <br />
        ・料金算定期間は、前月の検針日から当月の検針日の前日までの期間といたします。ただし、電気の供給を開始し、または需給契約が消滅した場合の料金算定期間は、開始日から直後の検針日の前日までの期間または直前の検針日から消滅日の前日までの期間といたします。
        <br />
        ・電気の供給を開始し、または需給契約が消滅した場合で、料金算定期間が29日以下または36日以上となった場合や、前月の検針日から当月の検針日の前日までの期間が24日以下または36日以上となった場合には、当該料金算定期間の料金を日割計算により算定いたします。その場合には、所定の計算式に基づき、基本料金または最低料金は使用日数に応じて日割計算をし、段階制の電力量料金についてはそれぞれの段階の範囲を日割計算によって区分し、算定いたします。ただし、当社または送配電事業者の都合で料金算定期間の日数が36日以上となった場合を除きます。
        <br />
        ・電気のご使用量および電気料金は、当社の会員専用サイト「マイ大阪ガス」にてお知らせいたします。当社の電気をご契約されている方は、マイ大阪ガスにご加入いただきます。
        <br />
        ・当社のガスをご契約のお客さまは、電気料金を翌月のガス料金と合わせてお支払いいただきます。この場合、電気料金は翌月のガス検針時にお渡しする
        <br />
        「ご使用量のお知らせ」でもお知らせいたします。ただし、電気検針日とガス検針日の日程等によっては、翌月のガス料金と合わせてご請求できない場合があります。また、お客さまのご契約内容によっては、「ご使用量のお知らせ」にてお知らせできない場合があります。当社のガスをご契約でないお客さまは、マイ大阪ガスにて料金をお知らせした後に請求いたします。
        <br />
        ・電気料金は当社の電気供給約款に定める方法で、支払期日までに毎月お支払いいただきます。支払期日は、電気供給約款に定める支払義務発生日の翌日から起算して30日目といたします。
        <br />
        ・支払期日を経過してもなお料金のお支払いがない場合は、電気供給約款の定めに基づき延滞利息を申し受けます。
        <br />
        ・支払期日を経過してもなお料金（当社との他の契約の料金を含みます。）、延滞利息または電気供給約款に基づき生じたその他の債務についてお支払いがない場合等当社が電気供給約款で定める一定の事由に該当するときは、当社は15日前を目安に通知のうえ契約を解約することがあります。電気の解約に先立ち、請求書をお送りするときは、当社は、そのお客さまに対し、請求書の発行・送付にかかる事務手数料として330円（税込）を申し受けます。
        <br />
        <br />
        ■契約の変更および解約について
        <br />
        ・お客さまが同一の需要場所において電気の購入先を当社から他の小売電気事業者に変更される場合には、新たな小売電気事業者に対し契約の申込みをしていただきます（当社への解約のお申し出は不要です）。
        <br />
        ・契約の変更や解約を希望される場合は、大阪ガスグッドライフコールへお申し付けください。転宅等により解約される場合は、解約を希望される日の前日の15時までに当社へお申し出いただく必要があります。
        <br />
        ・契約期間満了に先だって需給契約の解約または変更がない場合は、契約期間満了後も同一条件、同一期間で自動更新いたします。更新後の契約期間等は、書面、電子メール、インターネット上での開示等当社が適当と判断する方法によりお知らせいたします。
        <br />
        ・解約金の定めのある契約については、当社指定期間外での契約変更または解約に対し、解約金を申し受けます。上記期間の到来は、書面、電子メール、インターネット上での開示等当社が適当と判断する方法によりお知らせし、契約満了日までに変更や解約手続きが完了していない場合は、契約満了日をもって、従前と同一の内容で契約を更新いたします。詳細はこちらをご確認ください。
        <br />
        ・クーリング・オフにより契約を解除された場合や当社から契約を解約した場合等で、お客さまが無契約状態となったときには、電気の供給が停止いたしますので、他の小売電気事業者へお申込みいただく必要があります。
        <br />
        <br />
        ■その他
        <br />
        ・当社の供給電気方式および供給電圧は、電灯契約の場合は交流単相2線式標準電圧100ボルトまたは交流単相3線式標準電圧100ボルトおよび200ボルト、動力契約の場合は交流3相3線式標準電圧200ボルトとし、周波数は、標準周波数60ヘルツといたします。
        <br />
        ・お客さまが新たに電気を使用される場合等で、新たに配電設備や特別供給設備を施設するときや、新たな電気の使用等にともなわないでお客さまの希望によって供給設備を変更する場合は、託送供給等約款に従い当社が送配電事業者に支払うべき金額を工事費負担金としてお客さまから申し受けます。
        <br />
        ・送配電事業者の指示や災害の発生等により電気の供給を中止または制限する場合があります。これら、当社の責めによらずに電気の供給を中止または制限する場合、当社は損害賠償責任を負わないものといたします。
        <br />
        ・ご自宅で人工呼吸器等の医療機器をご使用されている場合等で停電等により損害を受けるおそれがある場合は、代替電源のご準備等必要な措置をお客さまにて講じていただきますようお願いいたします。
        <br />
        ・当社または送配電事業者が必要と判断した場合には、お客さまの承諾を得て、係員をお客さまの使用場所に立ち入らせていただきます。この場合、正当な事由がない限り、立ち入ることを承諾していただきます。また、電気供給に必要な設備の施設や電力品質維持に関して必要な協力、その他託送供給等約款におけるお客さまが遵守すべき事項について承諾していただきます。
        <br />
        ・現在ご契約中の小売電気事業者との契約を解約することで、解約金の発生やポイントの失効等、お客さまの不利益となる事項が発生する可能性があります。また、分散型発電システム等の系統連系申請中に小売電気事業者を変更した場合、系統連系の再申請が必要となる可能性があります。
      </p>
      <p className={styles.body}>
        <br />
        ■小売電気事業者について 事業者名 大阪ガス株式会社 事業者住所
        <br />
        大阪市中央区平野町四丁目1番2号 登録番号 A0048 お問い合わせ
        <br />
        0120-000-555（グッドライフコール） 受付時間 （月～土）8:00～21:00
        <br />
        （日・祝）9:00～21:00 お客さま情報の取扱いについて
        <br />
        <br />
        ■お客さま情報の利用目的
        <br />
        当社は、ガス・電気・熱等の各種のエネルギーをお客さまにご利用いただくにあたり、電気需給契約の申込受付等の機会に、直接または業務委託先等を通じて、お客さまの個人情報（お客さまの氏名、住所、電話番号等）を取得いたしますが、これらの情報は以下の目的に利用させていただきます。
        <br />
        （1）エネルギー供給およびその普及拡大 （2）エネルギー供給設備工事
        <br />
        （3）エネルギー供給設備・消費機器(厨房、給湯、空調等)の修理・取替・点検等の保安活動
        <br />
        （4）漏洩・火災自動通報、供給の遠隔遮断等のエネルギー供給事業に関連するサービスの提供
        <br />
        （5）エネルギー消費機器・警報器等の機器および住宅設備の販売（リース・レンタル等を含む）、設置、修理・点検、商品開発、アフターサービス
        <br />
        （6）上記各種事業に関するサービス・製品のお知らせ・PR、調査・データ集積・分析、研究開発
        <br />
        （7）その他上記（1）から（6）に附随する業務の実施
        <br />
        なお、当社は、上記の業務を円滑に進めるため、金融機関、クレジットカード会社、コンビニエンスストア、債権回収会社、情報処理会社、協力会社（サービスショップ、工事会社等）、Daigasグループ会社等に業務の一部を委託することがあります。その際、当社からこれらの業務委託先に必要な範囲でお客さま情報を提供することがあります。その場合、当社は、業務委託先との間で取扱いに関する契約を結ぶ等、適切な監督を行います。
        <br />
        また、お客さまが当社の電気供給約款によりお支払いいただくことが必要となった料金その他の債務について、当社の定める期日を経過してもお支払いが確認できない場合等には、お客さまの氏名、住所、お支払い状況等の情報を他の小売電気事業者へ当社が通知することがあります。
        <br />
        <br />
        ■お客さま情報の共同利用
        <br />
        当社は、契約手続きに際しお伺いしたお客さまの個人情報を、手続きに必要な範囲で、小売電気事業者、一般送配電事業者、需要抑制契約者および電力広域的運営推進機関との間で共同利用いたします。詳細は当社のプライバシーポリシーをご確認ください。
        <br />
        <br />
        スマモル賃貸プラン　要綱
        <br />
        <br />
        １．料金の特徴
        <br />
        スマモル賃貸プランは、「スマートロック」「駆けつけサービス」「優待・割引サービス」（以下総称して「スマモル賃貸サービス」といいます。）が付帯したプランです。各種サービスの利用料金は、お支払いいただく電気料金に含まれます。
        <br />
        <br />
        ２．スマモル賃貸プランのお申込みについて
        <br />
        ・スマモル賃貸プランのお申込みをご希望の場合、物件の管理等を行う事業者（以下、「管理会社等」といいます。）がお客さまからお知らせいただいたメールアドレス宛に、当社がお申込みサイトのURLを送付いたします。
        <br />
        ・お客さまは、URLの案内に従い、お申込みに必要な情報を入力いただきます。
        <br />
        ・お申込みいただいた内容は、スマモル賃貸プランのお申込みに利用するほか、スマモル賃貸サービスのご利用に必要な範囲で、管理会社等および当社の業務委託先等に連携いたします。
        <br />
        ・お申込みサイトには、お客さまからあらかじめ管理会社等にお伝えいただいたご契約内容が表示されます。表示内容が異なる場合は、お申込み内容の入力前に、必ず管理会社等にお問い合わせください。
        <br />
        <br />
        ３．スマモル賃貸サービスについて
        <br />
        ・スマモル賃貸プランには、以下のサービスが付帯します。
        <br />
        <br />
        ① スマートロック
        <br />
        株式会社ビットキーが提供する、お客さまの需要場所の入口等の鍵に取り付けることで、スマートフォンや専用のリモコンキーから扉の鍵を開閉できる商品「bitlockLITE」をご利用いただけます。
        <br />
        <br />
        ② 駆けつけサービス
        <br />
        暮らしの中のトラブル等に対し、出張対応等を行うサービスをご利用いただけます。
        <br />
        <br />
        ③ 優待・割引サービス
        <br />
        トラベル、グルメ、レジャー、エンターテインメントなどの様々なサービスが市価または定価に比べ割安に利用できるサービスをご利用いただけます。
        <br />
        ・スマモル賃貸サービスは、一部のサービスを除き、原則としてお客さまのご入居日からご利用いただけます。
        <br />
        ・スマモル賃貸サービスの内容は、それぞれのサービスの要綱または利用規約等にて定めるものといたします。当社以外の事業者がサービスの内容または利用規約等を変更したことにより、お客さまに不利益が生じても、当社はその責任を負いません。
        <br />
        ・お客さまの需要場所等により、一部のサービスを提供できない場合があります。お申込みの前に管理会社等にお問い合わせください。
        <br />
        <br />
        ４．契約の更新について
        <br />
        ・スマモル賃貸プランの契約期間は、需給開始日以降2年目の日までといたします。
        <br />
        ・ご契約期間満了1か月前を目途にご案内を送付します。同内容で契約を継続される場合、特段のお手続きは不要です。契約種別の変更や解約をご希望の場合は、お送りするご案内に従って、お手続きください。
        <br />
        <br />
        ５．契約種別の変更・解約について
        <br />
        ・契約期間途中に、お客さまにスマモル賃貸プランの適用がなくなった場合は、原則として、適用がなくなった日から当初の契約終了日までの残存期間（1か月未満の端数は切り捨てます。）に応じて、1か月あたり880円（税込）を乗じた金額を当社にお支払いいただきます。ただし、お引越しによるご解約の場合、または、更新後の期間中の解約およびプラン変更の場合については、解約金はいただきません。なお、スマモル賃貸プランの解約後にお引越しされた場合で、お引越し日が当初の契約終了日までの日であった場合でも、お支払いいただいた解約金の精算は行いません。
        <br />
        ・スマモル賃貸プランを当初の契約の期間中に解約した場合でも、当該契約が満了する予定であった日までは、スマモル賃貸サービスを利用することができます。当該契約が満了する予定であった日以降のスマートロックのご利用には、別途費用が発生する場合があります。詳細は管理会社等にお問い合わせください。
        <br />
        <br />
        ６．お申込みについて
        <br />
        ・当社が適当でないと判断した場合、その他やむを得ない事情がある場合には、申込みを承諾できない場合があります。
        <br />
        <br />
        ７．当社によるプラン変更・廃止について
        <br />
        ・スマモル賃貸プランの内容を変更・廃止する場合があります。あらかじめご了承ください。
        <br />
        <br />
        ８．その他
        <br />
        ・現在ご契約の料金メニューによっては、スマモル賃貸プランへの変更によりデメリットが生じる場合があります。
        <br />
        ・お引越しされる場合は、当社へ電気の需給契約の廃止のご連絡をしていただく必要がございます。
        <br />
        ・詳細の運用等については、約款に定める通りといたします。
      </p>
      {/* {(status === 'loading' || status === 'load') && (
        <div className={styles.managementContainer}>
          {status === 'loading' && <Loading />}
          {status === 'load' && (
            <>
              <p className={styles.managementHeading}>販売店(媒介業者)</p>
              <p>{propertyData ? propertyData.managementCompanyName : '-'}</p>
              <p>{propertyData ? propertyData.managementCompanyAddress : '-'}</p>
              <p>TEL: {propertyData ? propertyData.managementCompanyPhoneNumber : '-'}</p>
            </>
          )}
        </div>
      )} */}
    </div>
  );
};

export default ExplanationMatters;
