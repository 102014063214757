import { bitlockVoApiPath, bitlockVoApiKey } from '../config/baseConfig';
import { LoadBaseApplicationConditionOutput } from '../types/api-definition/load-base-application-condition-output';

export default class BaseApplicationConditionService {
  public static loadBaseApplicationCondition = async (
    allSpaceId: string,
    contractId: string
  ): Promise<LoadBaseApplicationConditionOutput> => {
    const endpoint = `${bitlockVoApiPath}/spaces/${allSpaceId}/contracts/${contractId}`;
    const apiKey = bitlockVoApiKey;
    const headers = {
      'x-vo-api-key': apiKey,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    const method = 'GET';

    return fetch(endpoint, {
      method,
      headers,
      mode: 'cors'
    }).then(res => {
      if (res.status !== 200) {
        throw Error('not status is 200');
      }
      return res.json().then(json => json.data);
    });
  };
}
