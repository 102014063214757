import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import signUpFormReducer from './signUp';
import indexReducer from './index/index';
import propertyReducer from './property';
import signInFormReducer from './signIn';
import applyFormReducer from './application';
import validationReducer from './validation';
import userReducer from './user';
import identificationReducer from './identification';

const appReducer = combineReducers({
  property: propertyReducer,
  form: formReducer,
  signUpForm: signUpFormReducer,
  index: indexReducer,
  signInForm: signInFormReducer,
  applyForm: applyFormReducer,
  validation: validationReducer,
  user: userReducer,
  identification: identificationReducer
});
export default appReducer;
