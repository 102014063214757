import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'var(--color-background-gray)',
      padding: '20px 16px 24px',
      borderRadius: 14,
      margin: '20px 0 0'
    }
  })
);

export const AccountContainer = (props: { children: JSX.Element }) => {
  const styles = useStyles({});

  return <div className={styles.container}>{props.children}</div>;
};
