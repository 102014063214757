import React from 'react';
import clsx from 'clsx';
import { Stepper, StepLabel, Step, StepConnector } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { StepIconProps } from '@material-ui/core/StepIcon';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/styles';

interface PropsFromParent {
  step: number;
  desc?: boolean;
  hasElectoric?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: 'var(--color-key)',
      height: '180px',
      '@media (min-width:768px)': {
        borderRadius: '10px 10px 0 0'
      }
    },
    heading: {
      margin: 0,
      paddingTop: 20,
      color: 'var(--color-white)',
      fontSize: 16,
      letterSpacing: '0.08em',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    description: {
      color: 'var(--color-white)',
      fontSize: 14,
      lineHeight: '20px',
      textAlign: 'left',
      padding: '0 24px'
    },
    stepLabel: {
      color: 'var(--color-white)',
      fontSize: 12,
      padding: '0 16px 0',
      textAlign: 'center',
      display: 'block'
    },
    notCompleted: {
      opacity: 0.7
    },
    middleStepLabel: {
      padding: '0 18px 0'
    },
    activeStep: {
      fontWeight: 'bold'
    }
  })
);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: 'var(--color-white)',
    display: 'flex',
    height: 24,
    alignItems: 'center'
  },
  active: {
    height: 24,
    width: 24,
    borderRadius: 24,
    backgroundColor: 'var(--color-white)',
    color: 'var(--color-key)',
    display: 'flex',
    justifyContent: 'center'
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    backgroundColor: 'var(--color-white)',
    padding: 4,
    height: 16,
    width: 16,
    borderRadius: 24,
    color: 'var(--color-key)',
    zIndex: 1,
    fontSize: 10
  }
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 11,
    left: 'calc(-50% + 4px)',
    right: 'calc(50% + 4px)'
  },
  active: {
    '& $line': {
      borderColor: 'var(--color-white)'
    }
  },
  completed: {
    '& $line': {
      borderColor: 'var(--color-white)'
    }
  },
  line: {
    borderColor: 'var(--color-white)',
    opacity: 0.7,
    borderTopWidth: 1
  }
})(StepConnector);

const BaseStepper: React.FC<PropsFromParent> = props => {
  const steps = ['アカウント連携', '申込情報入力', '確認'];
  const styles = useStyles();
  const title = props.hasElectoric ? 'スマモル賃貸プラン申し込み' : 'スマモル賃貸サービス申し込み';

  function QontoStepIcon(props: StepIconProps): JSX.Element {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active
        })}
      >
        {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{title}</h1>
      {props.desc ? (
        <p className={styles.description}>
          このページの内容は、入居申込時に頂いた情報を元に作成しております。申込内容と異なる場合は、
          <span style={{ fontWeight: 600 }}>物件の管理会社</span>
          へご連絡ください。
          <br />
          内容にお間違いなければ、画面下部のボタンから申込みへお進みください。
        </p>
      ) : (
        <Stepper
          style={{ background: '#03A9F4' }}
          activeStep={props.step}
          alternativeLabel
          connector={<QontoConnector />}
        >
          {steps.map((label, idx) => {
            let className = styles.stepLabel;
            if (props.step < idx) {
              className = `${className} ${styles.notCompleted}`;
            }
            if (idx === 1) {
              className = `${className} ${styles.middleStepLabel}`;
            }
            if (props.step === idx) {
              className = `${className} ${styles.activeStep}`;
            }
            return (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <span className={className}>{label}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </div>
  );
};

export default BaseStepper;
