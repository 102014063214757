import ActionType from './types';
import { ActionWithPayload } from '../utils/types';

export const setQueryParams = (): ActionWithPayload<ActionType.setQueryParams, {}> => ({
  type: ActionType.setQueryParams,
  payload: {}
});

export const invalidQueryParams = (): ActionWithPayload<ActionType.invalidQueryParams, {}> => ({
  type: ActionType.invalidQueryParams,
  payload: {}
});

export const clearStatus = (): ActionWithPayload<ActionType.clearStatus, {}> => ({
  type: ActionType.clearStatus,
  payload: {}
});

export type EntryAction =
  | ReturnType<typeof setQueryParams>
  | ReturnType<typeof invalidQueryParams>
  | ReturnType<typeof setQueryParams>
  | ReturnType<typeof clearStatus>;
