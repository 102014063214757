import { connect } from 'react-redux';
import * as Actions from '../modules/validation/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import ValidationTemplate from '../components/template/ValidationTemplate';
import * as module from '../modules/validation';
import * as signUpModule from '../modules/signUp';

export const mapStateToProps = (state: AppState) => {
  return {
    status: module.Selectors.getStatus(state),
    signupMethod: signUpModule.Selectors.getSignupMethod(state)
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) => ({
  initSubmitStatus: () => dispatch(Actions.initSubmitStatus()),
  submitCode: code => dispatch(module.Operations.submitCode(code))
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationTemplate);
