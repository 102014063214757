import ActionType, { IdentificationParameterState, verifyIdentificationErrorStatus } from './types';
import { IdentificationAction } from './actions';
import * as lodash from 'lodash';

type VerifyIdentificationStatus = 'not_set' | 'processing' | 'identified' | verifyIdentificationErrorStatus;

export interface IdentificationState {
  parameters: IdentificationParameterState;
  status: VerifyIdentificationStatus;
}

export const initialState: () => IdentificationState = () => {
  return {
    status: 'not_set',
    parameters: {
      birthday: '19800101'
    }
  };
};

const reducer = (state: IdentificationState = initialState(), action: IdentificationAction): IdentificationState => {
  switch (action.type) {
    case ActionType.verifyIdentification:
      const newSubmitProcessingState = { ...state };
      newSubmitProcessingState.status = 'processing';
      return newSubmitProcessingState;
    case ActionType.successToVerifyIdentification:
      const newSubmitSuccessState = { ...state };
      newSubmitSuccessState.status = 'identified';
      return newSubmitSuccessState;
    case ActionType.failedToVerifyIdentification:
      const newSubmitFailedState = { ...state };
      newSubmitFailedState.status = action.payload as verifyIdentificationErrorStatus;
      return newSubmitFailedState;
    case ActionType.updateParameter:
      const parameters = lodash.clone(state.parameters);
      switch (action.payload.key) {
        case 'birthday':
          parameters.birthday = action.payload.value as string;
          break;
        default:
          break;
      }
      return {
        ...state,
        parameters
      };
    default:
      return state;
  }
};

export default reducer;
