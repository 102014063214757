import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box',
      fontSize: 14,
      '& h1': {
        color: 'var(--color-text)',
        fontSize: 24,
        margin: '40px 0 0'
      },
      '& h2': {
        color: 'var(--color-text)',
        fontSize: 18,
        margin: '20px 0 0'
      },
      '& h3': {
        color: 'var(--color-text)',
        fontSize: 16,
        fontWeight: 'normal'
      },
      '& table': {
        width: '100%',
        margin: '12px 0 0',
        border: '2px solid #333',
        borderCollapse: 'collapse'
      },
      '& tr': {
        margin: 0,
        textAlign: 'left'
      },
      '& th': {
        color: '#333',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        border: '1px solid #333',
        borderWidth: '0px 1px 1px 0px',
        borderCollapse: 'collapse',
        padding: '16px 8px',
        margin: 0,
        width: '40%'
      },
      '& td': {
        color: 'var(--color-text)',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '20px',
        border: '1px solid #333',
        borderCollapse: 'collapse',
        borderWidth: '0px 0px 1px',
        padding: '16px 8px',
        margin: 0,
        wordBreak: 'break-all',
        width: '60%'
      }
    }
  })
);

const Terms: React.FC = () => {
  const styles = useStyles({});

  return (
    <>
      <div className={styles.container}>
        <h1 id="coupon">優待・割引サービス（めちゃとクーポン）利用規約</h1>
        <h2>第１条（目的）</h2>
        <p>
          優待・割引サービス（めちゃとクーポン）利用規約（以下「本規約」といいます。）は、大阪ガス株式会社（以下「当社」といいます。）が提供する優待・割引サービス（めちゃとクーポン）（以下「本サービス」といいます。）の利用に関して定めるものです。
        </p>
        <h2>第２条（サービスの内容）</h2>
        <p>
          本サービスは、トラベル、グルメ、レジャー、エンターテインメント等の様々なサービスに加え、Daigasグループ会社が提供するフィットネスクラブ「コ・ス・パ」や料理教室「大阪ガスクッキングスクール」等のサービスを市価または定価と比べ割安に利用できるサービスです。
        </p>
        <h2>第３条（お客さまの定義）</h2>
        <p>
          本規約において使用する「お客さま」とは、本規約の内容を確認し、同意したうえで、当社所定の申込方法により、申込み手続きを行い、当社がその申込みを承諾した方をいいます。
        </p>
        <h2>第４条（委託）</h2>
        <p>当社は、本サービスのサービス提供を、株式会社ベネフィット・ワンに委託します。</p>
        <h2>第５条（加入要件）</h2>
        <p>
          本サービスは、お客さまがスマモル賃貸プランの標準採用物件に入居する場合で、物件の管理等を行う事業者（以下「管理会社等」といいます。）からの紹介を受けた場合に加入することができます。
        </p>
        <h2>第６条（本サービスの申込み、利用）</h2>
        <p>
          (1)お客さまは、本サービスを当社に申込みする際には、あらかじめ本規約の内容を確認し、同意のうえ、当社所定の申込方法に則って申し込むものとします。
          <br />
          (2)お客さまは、当社が発行したID（以下「ID」といいます）およびパスワードでログインする
          <br />
          ことにより、ベネフィット・ワンが提供する「ベネフィット・ステーション」を利用できます。
          <br />
          (3)「ベネフィット・ステーション」の利用については、ベネフィット・ワンが別途定める「ベネフィット・ステーション（個人）会員規約」が適用され、お客さまはこれを遵守するものとします。
          <br />
          (4)お客さまの「ベネフィット・ステーション」の利用等に関して、ベネフィット・ワンとの間に生じた紛議については、お客さまとベネフィット・ワンとの間で解決するものとし、その紛議が当社の責に帰すべき事由により生じた場合を除いて、当社は一切責任を負いません。
        </p>
        <h2>第７条（本サービスの開始時期）</h2>
        <br />
        本サービスは、一部のサービスを除き、原則としてお客さまのご入居日からサービスを開始します。お客さまのお申込みが遅れたことにより、サービスの提供開始が遅れた場合でも、当社はその責任を負いません。
        <br />
        <h2>第8条（提供期間）</h2>
        <br />
        (1)本サービスの提供期間は、原則として入居日から2年間となります。ただし、お客さまにスマモル賃貸プランのご契約がある場合、その契約が続く限り本サービスの提供は継続されます。
        <br />
        上記にかかわらず、お引越しに伴う解約の場合、または入居日から2年を経過する前にお引越しされる場合は、当該お引越し日をもってサービスの提供は終了されるものとします。
        <br />
        (2)当社は、お客さまが第10条または第16条に違反していると当社またはベネフィット・ワンが判断した場合には、本サービスの提供を中止できるものとし、お客さまは予めこれを承諾するものとします。
        <br />
        <h2>第9条（サービス内容の変更）</h2>
        <br />
        当社およびベネフィット・ワンは、お客さまの承諾を得る事なく、本サービスの全部または一部の変更または追加が出来るものとします。これにより、お客さまが本サービスの一部割引・優待を利用できなかったことに関し、当社およびベネフィット・ワンは何らの責任も負いません。
        <br />
        <h2>第10条（本サービスにおける遵守事項、禁止事項）</h2>
        <br />
        お客さまは、本サービスの利用にあたって次の各号に該当する行為を行ってはならないものと
        <br />
        します。
        <br />
        ①本サービスを営利目的で利用する行為、または本サービスを通じて営利を得る目的の行為。
        <br />
        ②本規約および諸規程に記載されている内容を超えるサービス提供を求める行為または本規約を逸脱する行為及びそれに類する行為。
        <br />
        ③本サービスに関係する個人・法人・団体を誹謗中傷する行為。
        <br />
        ④本サービスに関係する個人・法人・団体が保有する著作権、財産権、その他の権利を侵害す
        <br />
        る行為、または侵害する恐れがある行為。
        <br />
        ⑤本サービスに関係する個人・法人・団体に不利益または損害を与える行為または与える恐れのある行為。
        <br />
        ⑥犯罪、反社会的行為を含む公序良俗に反する行為またはそれに関連する行為。
        <br />
        ⑦法律に違反する行為または違反の恐れのある行為。
        <h2>第11条（提供の拒否）</h2>
        <br />
        当社およびベネフィット・ワンは、以下のいずれかの事由に該当すると判断した場合、お客さまに対して、本サービスの提供を拒否することができるものとします。
        <br />
        ①お客さま本人からの本サービスの利用申込みであることを確認できない場合。
        <br />
        ②台風・大雨・暴風・豪雪等の異常気象、地震・噴火等の天災地変もしくは戦争・暴動または公権力の行使等により、本サービスを提供することが困難または危険が伴うことが予測される場合。
        <br />
        ③本サービスの対応内容を逸脱する場合。
        <br />
        ④委託会社が本サービスを提供することが困難であると判断する場合。
        <br />
        ⑤本サービスを提供することにより、第三者の所有物の損壊、第三者の権利または利益の制限あるいは第三者に損害が生じることが想定される場合。
        <br />
        ⑥当社または委託会社のシステムの定期的、緊急的な保守点検が必要な場合またはシステムに障害が発生した場合。
        <br />
        ⑦前各号以外でも、社会通念上、本サービスの提供が困難であるとみられる場合。
        <br />
        <h2>第12条（個人情報）</h2>
        <br />
        当社は、ガス・電気・熱等の各種エネルギーや当社の商品・サービスをお客さまにご利用いただくにあたり、当契約の申込み受付等により、当社が直接または業務委託先等を通じて、お客さまの個人情報（お客さまの氏名・住所・電話番号等）を取得します。これらの個人情報は、以下の目的に利用します。
        <br />
        ①エネルギー供給およびその普及拡大
        <br />
        ②エネルギー供給設備工事
        <br />
        ③エネルギー供給設備・消費機器（厨房・給湯・空調等）の修理・取替・点検等の保安活動
        <br />
        ④漏洩・火災自動通報、供給の遠隔遮断等のエネルギー供給事業に関連するサービスの提供
        <br />
        ⑤エネルギー消費機器・警報器等の機器および住宅設備の販売（リース・レンタル等を含む）、設置、修理・点検、商品開発、アフターサービス
        <br />
        ⑥上記各種事業に関するサービス・製品のお知らせ・PR・調査・データ集積・分析、研究開発
        <br />
        ⑦その他上記①～⑥に附随する業務の実施
        <br />
        なお、当社は、上記の業務を円滑に進めるため、金融機関、クレジットカード会社、コンビニエンスストア、債権回収会社、情報処理会社、保証サービス会社、協力会社（サービスショップ、メンテサービス会社、工事会社等）に業務の一部を委託することがあります。その際、当社からこれらの業務委託先に必要な範囲で個人情報を提供することがあります。その場合、当社は、業務委託先との間で取り扱いに関する契約を結ぶ等、適切な監督を行います。
        <br />
        <h2>第13条（損害賠償の制限）</h2>
        <br />
        本サービスの利用によりお客さまが何らかの損害を被った場合、それが当社およびベネフィット・ワンの故意または重過失により発生したものでない限り、当社およびベネフィット・ワンは当該損害を賠償する責任を負わないものとします。
        <br />
        <h2>第14条（通知）</h2>
        <br />
        当社は、本サービスに関するお客さまへの通知を当社のウェブサイト上にその内容を掲載することをもって、本サービスに関する通知に代えることができるものとします。
        <br />
        この場合、当社が当該通知内容を当社のウェブサイト上に掲載した時点をもって、お客さまに対して、当該通知がなされたものとみなします。
        <br />
        <h2>第15条（変更の届出）</h2>
        <br />
        (1)
        お客さまは、氏名、住所、電話番号、メールアドレス、その他本サービスの利用に伴い当社へ届け出た内容に変更があった場合は、速やかに当社所定の方法で当社に届け出るものとします。なお、お客さまが届出内容の変更があったにもかかわらず、当社に届出をしない場合（当社への届出後、当社がその変更内容を確認できるまでの間を含みます。）、本規約に定める当社からの通知については、当社がお客さまから届出を受けている連絡先へ発信したことにより、通知を行ったものとみなします。
        <br />
        (2)
        前項の届出があった場合、当社は、その届出のあった事実を確認するための書類の提示又は提出をお客さまに求める場合があります。お客さまはこれに応じるものとします。
        <br />
        <h2>第16条（反社会的勢力の排除）</h2>
        <br />
        (1)お客さまは、次の各号のいずれかにも該当しないこと、かつ将来にわたっても該当しな
        <br />
        いことを表明し、保証するものとします。
        <br />
        ①自らが、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、総会屋、社会運動等標ぼうゴロその他これに準じるもの（以下総称して「暴力団員等」といいます。）であること。
        <br />
        ②お客さまが自らもしくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもって取引を行う等、暴力団員等を利用していると認められる関係を有すること。
        <br />
        ③お客さまが暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること。
        <br />
        (2)
        お客さまは、自ら又は第三者を利用して次の各号のいずれか一つでも該当する行為を行わないことを保証するものとします。
        <br />
        ①暴力的な要求行為 ②不当な要求行為
        <br />
        ③取引に関して、脅迫的な言動をし、又は暴力を用いる行為
        <br />
        ④風説を流布し、偽計を用い、又は威力を用いて、当社の信用を毀損し、又は当社の業務を妨害する行為
        <br />
        ⑤その他前各号に準ずる行為
        <h2>第17条（規約の変更）</h2>
        <br />
        本規約およびその他の諸規定は、適宜追加、変更、廃止等の改定を行います。この場合、当社は、改定日の一ヶ月前に、第14条に従い、当該改定の内容をお客さまに通知するものとします。
        <br />
        <h2>第18条（譲渡禁止等）</h2>
        <br />
        お客さまは、お客さまの権利について、第三者に譲渡、売買、質権の設定その他の担保に供する等の行為はできないものとします。
        <br />
        <h2>第19条（合意管轄）</h2>
        <br />
        お客さまおよび当社は、本規約に関連した訴訟を行う場合は、大阪簡易裁判所および大阪地方裁判所を第一審の専属的合意管轄裁判所とすることに合意します。
        <br />
        <h2>第20条（準拠法）</h2>
        <br />
        本規約の成立、効力、解釈及び履行については、日本国法に準拠するものとします。
        <br />
        <h2>第21条（その他）</h2>
        <br />
        本規約に関する疑義または本規約に定めのない事項については、お客さまおよび当社の双方が誠意をもって協議し、円満に解決を図るものとします。
        <h1 id="kaketsuke">駆けつけサービス契約約款</h1>
        <br />
        <h2>第１条 （目的）</h2>
        <br />
        この約款は、大阪ガス株式会社（以下、「当社」といいます。）が、「駆けつけサービス」をご契約いただくお客さま（以下、「お客さま」といいます。）に対して、「駆けつけサービス」（以下、「本サービス」といいます。）の契約内容（以下、「本契約」といいます。）を定めるものです。
        <br />
        <h2>第２条 （サービス内容）</h2>
        本サービスの内容は、次のとおりです。
        <br />
        ・お客さまのご自宅（お客さまがお引越しに際し新たにご契約される物件を指し、以下、「対象物件」といいます。）の防犯に関してご懸念が生じた時に、お客さまからのお電話によるご依頼に基づき、警備員がかけつけるサービス（以下、「警備員かけつけ」といいます。なお、本サービスは、犯罪の未然防止を約束するサービスではございません。）
        <br />
        ・対象物件のスマートロックの解錠に関するトラブルについて、お客さまからのお電話によるご依頼に基づき、ワンタイムパスワードを用いて対応するサービス（以下、「スマートロックの解錠サポート」といいます。）
        <br />
        ただし、対象物件の所在地その他の理由により、一部のサービスの提供を行えない場合があります。
        <br />
        <h2>第３条 （お客さま）</h2>
        <br />
        本サービスは、お客さまのみが利用することができます。また、対象物件と異なる物件では利用することはできません。
        <br />
        ２．本サービスを利用する場合、運転免許証等の公的機関が発行した身分証明書による本人確認が必要となる場合があります。
        <br />
        <h2>第４条 （提供期間）</h2>
        <br />
        本サービスの提供期間は、原則として対象物件への入居日から2年間とします。ただし、お申込みから入居日までの期間が短い場合、入居日からご依頼を受けられない場合があります。なお、お客さまに当社の電気料金メニュー（「スマモル賃貸プラン」に限ります。以下、「スマモル賃貸プラン」といいます。）の適用がある場合は、入居日から2年を経過した以降も、その契約が継続する限り本サービスの提供は継続されます。上記にかかわらず、退居に伴う解約の場合、または入居日から2年を経過する前に退居される場合は、退居日をもってサービスの提供は終了されるものとします。
        <br />
        <h2>第５条 （サービス提供の中止）</h2>
        <br />
        お客さまにおいて次の事由が生じた場合、当社はお客さまに何らの通知を要することなく本サービスの提供を中止することができます。
        <br />
        ①本サービスの目的に反した使用が繰り返される等、お客さまが正常な方法でご使用いただけない場合で、当社からお客さまに改善を申し入れたにもかかわらず、状況が改善されないとき
        <br />
        ②お客さまが、暴力団、暴力団関連企業をはじめとする反社会的勢力またはその構成員または準構成員に該当することが判明した場合
        <br />
        ③お客さまが、自らまたは第三者を利用して、次のいずれかに該当する行為をした場合
        <br />
        ・暴力的な要求行為 ・法的な責任を超えた不当な要求行為
        <br />
        ・取引に関して、脅迫的な言動をし、または暴力を用いる行為
        <br />
        ・風説を流布し、偽計を用い、または威力を用いて当社の信用を毀損し、または当社の業務を
        <br />
        妨害する行為 ・その他上記に準ずる行為 ④本約款の各条項に違反したとき
        <br />
        ⑤前各号に掲げる事由の他、当社が本サービスの提供上支障があると判断したとき
        <br />
        <h2>第６条 （業務委託等）</h2>
        <br />
        当社は、本サービスの各種業務等について、業務の一部を委託することがあります。
        <br />
        ２．当社は、業務を委託する場合に、当社から業務委託先に対し、必要な範囲でお客さま情報を提供することがあります。この場合、当社は、業務委託先との間でお客さま情報の取り扱いに関する契約を結ぶ等、適切な監督を行います。
        <br />
        <h2>第７条 （対象物件への立ち入り等）</h2>
        <br />
        当社または当社の指定する第三者は、本サービスの遂行に必要がある場合には、対象物件に立ち入ることができるものとし、お客さまは、当該立ち入り、工事に正当な事由がない限り、立ち入りを承諾するものとする。
        <br />
        ２．対象物件を第三者へ貸借している場合で、お客さまが貸主の場合、お客さまは、対象物件の借主に対して、お客さまの責任にて、当社が本サービスの遂行のため対象物件に立ち入ることに関して承諾を得るものとします。
        <br />
        ３．前項でお客さまが借主の場合、お客さまは、対象物件の貸主に対して、お客さまの責任にて、当社が本サービスの遂行のため対象物件に立ち入ることに関して承諾を得るものとします。
        <br />
        ４．当社は、お客さまが第2 項および第3
        <br />
        項に定める承諾を得なかったことにより生じた損害については一切責任を負いません。
        <br />
        <h2>第８条 （サービスの停止）</h2>
        <br />
        当社は、以下の各号に定める場合で本サービスの提供を継続できないと判断した場合には、本サービスの提供を停止します。停止期間中、当社は本サービスの提供についての義務を一切まぬがれるものとし、停止に起因する損害について責めを負わないものとします。
        <br />①
        天災地変、暴動、通信回線（お客さまが設置された通信機器等を含みます）の障害、停電、その他不可抗力による損壊等が生じた場合
        <br />② 本サービス提供に必要なシステムに異常が発生した場合
        <br />
        ③ お客さま以外の依頼
        <br />
        ④ スマモル賃貸プランの適用がある場合で、スマモル賃貸プランの料金のお支払いがない場合
        <br />⑤ 申込内容に変更が生じたにもかかわらず、お客さまが当社への連絡を行わなかった場合
        <br />⑥ 本サービス対象の不動産自体の瑕疵もしくはお客さまの管理上の瑕疵があった場合
        <br />⑦ 本サービスの継続に通常必要となる作業を行う場合
        <h2>第９条 （損害賠償）</h2>
        <br />
        当社は、本サービスの提供にあたって、当社の責めに帰すべき事由によりお客さまに損害を与えた場合、お客さまに対して以下のとおり、当該損害の賠償をいたします。ただし、警備員かけつけは犯罪の未然防止を約束するサービスではないため、犯罪によりお客さまに発生した損害については、当社による損害賠償の対象外といたします。
        <br />
        ２．当社に対して損害の賠償を請求する場合、お客さまは損害の発生の事実を知った後、遅滞なく当社に対して通知し、当社は現場確認や公的機関等から発行された証明書等により損害を確認したうえで、損害賠償を行います。ただし、その限度額は、1事故1億円までとします。
        <br />
        ３．本条の規定にかかわらず、お客さまが前項の通知を怠った場合は、賠償に関する諸手続きが行えない場合があります。
        <br />
        <h2>第１０条 （協議事項）</h2>
        <br />
        お客さまと当社は、本契約に定めのない事項については、法令および商慣習に従うほか、誠意をもって協議いたします。
        <br />
        <h2>第１１条 （守秘義務）</h2>
        <br />
        お客さまおよび当社は、本サービスを通じて知り得た秘密情報（技術情報、お客さま情報等）を善良なる管理者の注意をもって管理し、第三者へ漏らしてはなりません。このことは本サービス終了後も同様とします。
        <br />
        <h2>第１２条 （お客さま情報の取り扱い）</h2>
        <br />
        当社はお客さまの個人情報を当社ホームページに記載の「プライバシーポリシー」および別添「お客さま情報の利用目的について」に基づき取り扱います。お客さまの個人情報の利用目的については、「プライバシーポリシー」および「お客さま情報の利用目的について」をご参照ください。
        <br />
        ２．当社が本サービスの提供の一部を委託した場合は、委託先へ必要な範囲で個人情報を提供、開示する場合があります。この場合、当社は委託先との間で取扱いに関する契約を結ぶ等、適切な監督を行います。
        <br />
        ３．当社は、業務品質向上等のため通話録音をさせていただくことがありますが、お客さま・緊急連絡先からの録音内容の確認のご依頼には対応いたしません。
        <br />
        <h2>第１３条 （権利譲渡の禁止）</h2>
        <br />
        お客さまは当社の同意なしに本サービスに関する権利・義務を第三者に譲渡できません。
        <br />
        <h2>第１４条 （相互協力）</h2>
        <br />
        お客さまおよび当社は互いに協力し、信義を守り誠実に本規定に従うものとし、本規程に定めのない事項および疑義の生じた事項については、お客さまおよび当社の協議により誠意をもって解決するものとします。
        <br />
        <h2>第１５条 （管轄裁判所）</h2>
        <br />
        本契約または本サービスの提供に関して、訴訟の必要が生じた場合には、大阪地方裁判所を専属的合意管轄裁判所とします。
        <br />
        <h2>第１６条 （改定）</h2>
        <br />
        当社は、本サービスの約款を予告なく変更させていただくことがあります。なお、変更後の約款につきましては、当社のホームページ等にお知らせいたします。
        <br />
        <h2>第１７条 （効力開始） </h2>
        本約款は、2019年12月1日から有効とします。
        <br />
        <h1>＜警備員かけつけに関する規程＞</h1>
        <h2>第１８条（警備員の出動）</h2>
        <br />
        当社は警備員の出動業務を大阪ガスセキュリティサービス株式会社（以下、「OSS」といいます。）等に委託します。
        <br />
        ２．当社は出動業務の委託先に対し、必要な範囲でお客さま情報を提供します。この場合、当社は委託先との間でお客さま情報の取り扱いに関する契約を結ぶ等、適切な監督を行います。
        <br />
        ３．本サービスにおける警備員かけつけの実施日は、お客さまの申込みにより、実際に駆けつけを実施する日とします。また、一件当たりの所要時間の目安は、5分～15分程度となります。ただし、案件により前後する場合があります。
        <br />
        <h2>第１９条（警備員かけつけ時の措置）</h2>
        <br />
        お客さまの依頼をいただいた場合、監視センターの指令により警備員は対象物件に出動し、対象物件の建物外周より目視可能な範囲内で異常発生の有無を確認（以下、「外周点検」といいます。）します。
        <br />
        異常発生が認められた場合には、警察、消防等へ通報するなどお客さまの被る損害の拡大防止のための必要な措置をとります。
        <br />
        ２．外周点検のため、警備員は、お客さまの承諾なく対象物件の敷地内に立ち入ることができるものとします。但し、門扉に鍵がかかっているなどの場合は敷地外からの点検といたします。
        <br />
        ３．異常事態発生の場合、OSSは、お客さまに対して、緊急連絡を行います。
        <br />
        ４．天候・交通状況・警備員の出動状況等により現場到着に相応の時間を要する場合があります。
        <br />
        ５．警備員かけつけサービスは、防犯に関するご心配時の警備員かけつけサポートであり、日常生活の雑用等を警備員に依頼することはできません。
        <br />
        [警備員に依頼できない行為の例] ・人の救急 ・人の身辺警備
        <br />
        ・ガスの消し忘れ対応 ・ポストの郵便物の整理・回収 ・植木の水やり
        <br />
        ・ペットの餌やり
        <h2>第２０条（警備員かけつけの報告）</h2>
        <br />
        警備員が外周点検を行った場合、警備員は点検報告書を対象物件へ投函します。また、監視センターは、必要に応じて警備員が外周点検を行った結果を登録電話番号に通知します。
        <br />
        <h2>第２１条（警備員かけつけ出動の料金）</h2>
        <br />
        お客さまからのお電話による依頼に基づき警備員を出動させた場合には、当社は出動に要する費用（１回の出動あたり５，５００円（税込））をお客さまに従量サービス料金として請求します。
        <br />
        ２．従量サービス料金は、出動の指示を行った時点で発生するものとし、お客さまが出動をキャンセル・変更された場合でもお支払いいただきます。
        <br />
        <h2>第２２条（出動予約）</h2>
        <br />
        お客さまは、出動を希望される日（以下、「出動日」といいます。）の14日前まで当社に連絡することにより、出動を予約することができます。
        <br />
        ２．出動可能時間は以下の時間帯とし、時間指定はできません。
        <br />
        ① ６：００～１２：００
        <br />
        ② １３：００～１９：００
        <br />
        ③ １９：００～２４：００
        <br />
        ３．出動予約後にキャンセルを希望される場合は、当社に出動日の前日までにご連絡いただきます。
        <br />
        ４．出動日当日のキャンセルにつきましては、第２１条第1項に規定する費用を申し受けます。
        <br />
        <h2>第２３条 （免責）</h2>
        <br />
        警備員のかけつけは、犯罪の早期発見および早期対応による拡大防止を目的とするものであり、犯罪の未然防止を保証するものではありません。従って、当社はお客さまが犯罪により被られた損害について、本契約に基づいて賠償する責めを負いません。これらの損害に対する備えとして、お客さまご自身で盗難保険等に加入していただきます。
        <br />
        ［当社が賠償する責めを負わない損害の例］
        <br />
        ①
        天災地変、暴動、通信回線（お客さまが設置された通信機器等を含みます）の障害、停電、その他不可抗力による損壊等により当社がお客さまに本サービスの提供ができなくなったことに起因する損害
        <br />② 対象物件自体の瑕疵もしくはお客さまの管理上の瑕疵またはお客さまの故意や重過失により生じた損害
        <br />③ 地震・火災などの災害により、またはこれらの災害に起因する損害
        <br />
        ④ 窃盗または強盗のために生じた火災または爆発等による損害
        <br />
        ⑤ 窃盗・強盗・火災発生等にともなう、OSS、消防隊、警察隊等による入口扉・窓等破壊等の損害
        <br />
        ⑥ 警備員かけつけサービスとの因果関係が認められない損害
        <br />
        ⑦ 警備員かけつけサービスが停止されている間に生じた損害
        <br />
        ⑧ 申込内容に変更が生じたにもかかわらず、お客さまが当社への連絡を行わなかったことに起因する損害
        <br />⑨ その他客観的に判断して賠償免責に相当する損害
        <br />
        ２．天候・交通状況・作業員の作業状況等により現場到着に相応の時間を要する場合があります。
        <br />
        <h1>＜スマートロックの解錠サポートに関する規程＞</h1>
        <h2>第２４条（サービス内容）</h2>
        <br />
        当社は、お客さまに対して、スマートロックの解錠サポートを行います。当社は、本業務をOSS等に委託します。
        <br />
        ２．対象となるスマートロックは、株式会社ビットキーが提供する「bitlock
        <br />
        LITE」に限ります。
        <br />
        ３．お客さまの依頼をいただいた場合、警備員は事前にお客さまよりお預かりしたワンタイムパスワードを用いて解錠を行います。お客さまが事前にワンタイムパスワードをお預けにならない場合、解錠を行うことはできません。
        <br />
        <h2>第２５条（スマートロックの解錠サポートの料金）</h2>
        <br />
        お客さまからのお電話による依頼に基づき警備員を出動させた場合には、当社は出動に要する費用（１回の出動あたり５，５００円（税込））をお客さまに従量サービス料金として請求します。
        <br />
        ２．従量サービス料金は、出動の指示を行った時点で発生するものとし、お客さまが出動をキャンセル・変更された場合でもお支払いいただきます。
        <br />
        <h2>第２６条（免責）</h2>
        <br />
        スマートロックの解錠サポートに関して、以下の事項に該当する場合、本サービスの提供をお断りする場合があります。
        <br />
        ①お客さま以外の依頼
        <br />
        ②災害・天災・暴動等に起因する依頼
        <br />
        ③カギの解錠の場合にお客さま本人の立会いがない場合
        <br />
        ④運転免許証等の顔写真付公的身分証明書の提示がない場合。また、顔写真付公的身分証明書の提示があっても、身分証明書上の住所、氏名が当社に届出のある住所、氏名と異なっていた場合
        <br />
        ⑤スマートロックの解錠サポートとは異なるサポート依頼
        <br />
        ⑥対象物件以外に関する本サービスの依頼
        <br />
        ⑦離島もしくはサービス提供が困難な地域からの依頼
        <br />
        ２．天候・交通状況・作業員の作業状況等により現場到着に相応の時間を要する場合があります。
        <br />
        ３．当社及び業務委託先（業務委託先の業務提携先を含む）は、故意または過失がない限り、スマートロックの解錠サポートに関して、損害賠償等の責任を負わないものとします。
        <br />
        ４．ワンタイムパスワードによる解錠が困難な場合等、OSS等では対応できない場合は、OSS等は専門業者等を手配することができます。ただし、当該専門業者による対応にかかる費用はお客さまのご負担となる場合があります。
        <p>（別表）</p>
        <h3>[お客さまがご契約される会社]</h3>
        <table>
          <tbody>
            <tr>
              <th>名称</th>
              <td>大阪ガス株式会社</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>〒541-0046 大阪市中央区平野町4丁目1番2号</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                [ご契約に関するお問い合わせ窓口]
                <br />
                TEL 06-6205-3692（平日9～17時 土・日・祝・年末年始を除く）
              </td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役社長 本荘 武宏</td>
            </tr>
          </tbody>
        </table>
        <h3>[警備サービス提供会社]</h3>
        <table>
          <tbody>
            <tr>
              <th>名称</th>
              <td>大阪ガスセキュリティサービス株式会社</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>〒532-0024 大阪市淀川区十三本町3-6-35</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                [駆けつけのご用命]
                <br />
                TEL0120-750-082 （24時間受付（年中無休））
              </td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役社長 米田 吉克</td>
            </tr>
          </tbody>
        </table>
        <h3>[出動に関する警備員の情報]</h3>
        <table>
          <tbody>
            <tr>
              <th>人数および担当業務</th>
              <td>
                ①契約対象物件からのかけつけ依頼の受付業務 ：基地局に常時１名以上配置
                ②かけつけ依頼時の契約対象物件への派遣要員 ：待機所毎に１名以上配置
              </td>
            </tr>
            <tr>
              <th>知識および技能</th>
              <td>警備業法に基づく教育、並びにOSS独自の教育、訓練を実施</td>
            </tr>
            <tr>
              <th>服装</th>
              <td>指定の制服を着用</td>
            </tr>
            <tr>
              <th>使用する機器・機材</th>
              <td>車両、無線機、特殊警戒棒、ヘルメット、防刃チョッキ、携帯電話、その他</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Terms;
