import React, { FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import bg from '../../static/images/bg.svg';

const useStyles = makeStyles(
  createStyles({
    wrapper: {
      backgroundColor: 'var(--color-white)',
      '@media (min-width:768px)': {
        backgroundColor: '#F7FCFF',
        overflow: 'hidden',
        position: 'relative'
      }
    },
    base: {
      display: 'none',
      '@media (min-width:768px)': {
        zIndex: 10,
        display: 'block',
        position: 'absolute',
        backgroundColor: '#71D1FD',
        height: 365,
        width: '100%',
        top: 0,
        left: 0
      }
    },
    wrapperInner: {
      '@media (min-width:768px)': {
        position: 'relative',
        zIndex: 11,
        margin: '72px auto 0',
        width: '640px',
        borderRadius: 10,
        backgroundColor: 'var(--color-white)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)'
      }
    }
  })
);

interface Props {
  children: any;
  footer?: boolean;
}

export const DesktopBackground: FC<Props> = ({ children, footer }) => {
  const styles = useStyles({});
  return (
    <div className={styles.wrapper} style={{ minHeight: footer ? 'calc(100vh - 44px)' : '100vh' }}>
      <div className={styles.wrapperInner}>{children}</div>
      <div className={styles.base}>
        <img src={bg} alt={'background'} />
      </div>
    </div>
  );
};
