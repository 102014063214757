import { ContainerElement, ComponentElementCondition } from '../types/common/item-definition-types';
import { ItemValueKey } from './item-value-key';
import * as moment from 'moment-timezone';
import { ConfirmationDisplayType } from '../enums/confirmation/confirmation-display-type';
import { ComponentDescriptionType } from '../enums/common/component-description-type';
import { ValueOriginType } from '../enums/common/value-origin-type';
import { ComponentType } from '../enums/common/component-type';
import { DetailConditionOperator } from '../enums/common/detail-condition-operator';
import { FixedValueKey } from '../types/common/fixed-value-key';
import { ConditionsResultJudgeRule } from '../enums/common/conditions-result-judge-rule';

export const dateDisplayFormat = 'YYYY年MM月DD日(ddd)';

const useElectronicCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.HasElectricContract,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      }
    ]
  }
];

const notUseElectronicCondition: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.ApplicationParams,
        valueKey: FixedValueKey.HasElectricContract,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: false
          }
        ]
      }
    ]
  }
];

const isHolidayConditions: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.ElectricityStartDate,
        operator: DetailConditionOperator.IsHoliday,
        targetValues: []
      }
    ]
  }
];
const isSundayConditions: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.ElectricityStartDate,
        operator: DetailConditionOperator.IsSunday,
        targetValues: []
      }
    ]
  }
];

const isCheckedContactDirectly: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.ContactToContract,
        operator: DetailConditionOperator.Equal,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      }
    ]
  }
];

const isNotCheckedContactDirectly: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      {
        valueType: ValueOriginType.InputValues,
        valueKey: ItemValueKey.ContactToContract,
        operator: DetailConditionOperator.NotEqual,
        targetValues: [
          {
            valueType: ValueOriginType.Fixed,
            valueKey: true
          }
        ]
      }
    ]
  }
];

const useElectronicAndCheckedContactDirectly: Array<ComponentElementCondition> = [
  {
    detailConditions: [...useElectronicCondition[0].detailConditions, ...isCheckedContactDirectly[0].detailConditions]
  }
];

const useElectronicAndNotCheckedContactDirectly: Array<ComponentElementCondition> = [
  {
    detailConditions: [
      ...useElectronicCondition[0].detailConditions,
      ...isNotCheckedContactDirectly[0].detailConditions
    ]
  }
];

// 申込画面に表示するコンポーネント定義
export const RegisteredInputContainers: Array<ContainerElement> = [
  {
    id: 'SelectBasicPlan',
    title: '「スマモル賃貸プラン」は、京葉ガスの都市ガスもセットでのお申し込みとなります。',
    confirmationTitle: '都市ガスの申し込み',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: ItemValueKey.UseGasPlanSelection,
        label: undefined,
        isDisplayCondition: useElectronicCondition
      }
    ],
    isDisplayCondition: useElectronicCondition,
    showOnlyInput: true
  },
  {
    id: 'MoveInfo',
    title: 'お引越し情報',
    components: [
      {
        id: ItemValueKey.OccupyScheduledDate,
        label: '入居予定日',
        inputElement: {
          valueKey: ItemValueKey.OccupyScheduledDate,
          valueType: ValueOriginType.ApplicationParams,
          inputType: ComponentType.FixedText,
          converter: value =>
            moment(value)
              .tz('Asia/Tokyo')
              .format(dateDisplayFormat)
        },
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.ElectricityStartDate,
        label: '電気・ガス：利用開始日',
        inputElement: {
          valueKey: ItemValueKey.ElectricityStartDate,
          inputType: ComponentType.KeyboardDateInput,
          minDateOffset: 2,
          useBusinessDayOffset: true,
          isDisableCondition: isCheckedContactDirectly
        },
        showOnlyInput: true,
        descriptionsBeforeInput: [
          {
            messages: [
              '※電気とガスのご使用日は同日とさせていただきます。',
              '※電気は、お引越し当日からご利用いただけるように不在でも通電作業を行います。'
            ]
          }
        ],
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.ElectricityStartDate,
        label: '電気・ガス：利用開始日',
        inputElement: {
          valueKey: ItemValueKey.ElectricityStartDate,
          inputType: ComponentType.TextFieldInput,
          initialValue: '使用開始日を京葉ガスに直接連絡する'
        },
        showOnlyConfirmation: true,
        isDisplayCondition: useElectronicAndCheckedContactDirectly
      },
      {
        id: ItemValueKey.ElectricityStartDate,
        label: '電気・ガス：利用開始日',
        inputElement: {
          valueKey: ItemValueKey.ElectricityStartDate,
          inputType: ComponentType.KeyboardDateInput
        },
        showOnlyConfirmation: true,
        isDisplayCondition: useElectronicAndNotCheckedContactDirectly
      },
      {
        id: ItemValueKey.ContactToContract,
        label: undefined,
        inputElement: {
          valueKey: ItemValueKey.ContactToContract,
          inputType: ComponentType.CheckInput,
          selectionItems: [
            {
              value: 'dummy',
              label: '使用開始日を京葉ガスに直接連絡する'
            }
          ]
        },
        showOnlyInput: true,
        descriptionsBeforeInput: [
          {
            messages: ['※申し込みから2営業日（土日祝除く平日）以内に電気・ガスの使用開始をご希望の場合、']
          }
        ],
        descriptionsAfterInput: [
          {
            messages: [
              '上記にチェックを入れ、登録を完了させてください。 ',
              'また、京葉ガスお引越し専用電話へお電話をいただき',
              '「スマモル賃貸プランであること」 「電気・ガスの使用開始日」を併せてご連絡ください。 ',
              '京葉ガスお引越し専用電話：0120-047-555'
            ]
          }
        ],
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.GasStartTimeSelection,
        label: 'ガス開栓希望時間帯',
        inputElement: {
          valueKey: ItemValueKey.GasStartTimeSelection,
          inputType: ComponentType.SelectItemInput,
          placeholder: '時間を選択',
          initialValue: '',
          selectionItems: [
            {
              value: '0',
              label: '9:00‐10:30'
            },
            {
              value: '1',
              label: '10:30‐12:00'
            },
            {
              value: '2',
              label: '13:00‐15:00'
            },
            {
              value: '3',
              label: '15:00‐17:00'
            },
            {
              value: '4',
              label: '17:00‐19:00',
              notDisplayConditions: [
                {
                  judgeRuleType: ConditionsResultJudgeRule.Some,
                  detailConditions: [
                    ...isHolidayConditions[0].detailConditions,
                    ...isSundayConditions[0].detailConditions
                  ]
                }
              ]
            }
          ]
        },
        descriptionsBeforeInput: [
          {
            messages: ['※ガス開栓にはお客さまの立ち会いが必要となります。']
          }
        ],
        descriptionsAfterInput: [
          {
            messages: ['※月曜〜土曜は9-19時、日曜・祝日は9-17時の間で お選びいただけます。']
          }
        ],
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.ContactBeforeVisit,
        label: '訪問前連絡',
        inputElement: {
          valueKey: ItemValueKey.ContactBeforeVisit,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: '要'
            },
            {
              value: '1',
              label: '不要'
            }
          ]
        },
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.WitnessGasStart,
        label: '開栓の立ち会い者',
        inputElement: {
          valueKey: ItemValueKey.WitnessGasStart,
          inputType: ComponentType.SelectItemInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: '本人'
            },
            {
              value: '1',
              label: 'ご家族'
            },
            {
              value: '2',
              label: '大家'
            },
            {
              value: '3',
              label: '管理人'
            },
            {
              value: '4',
              label: 'その他'
            }
          ]
        },
        isDisplayCondition: useElectronicCondition
      },
      {
        id: ItemValueKey.WitnessNameGasStart,
        label: '立ち会い者氏名',
        inputElement: {
          valueKey: ItemValueKey.WitnessNameGasStart,
          inputType: ComponentType.TextFieldInput,
          placeholder: '姓 名'
        },
        isDisplayCondition: [
          {
            detailConditions: [
              {
                valueType: ValueOriginType.InputValues,
                valueKey: ItemValueKey.WitnessGasStart,
                operator: DetailConditionOperator.Some,
                targetValues: [
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '1'
                  },
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '2'
                  },
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '3'
                  },
                  {
                    valueType: ValueOriginType.Fixed,
                    valueKey: '4'
                  }
                ]
              }
            ]
          },
          ...useElectronicCondition
        ]
      },
      {
        id: ItemValueKey.PhoneNumberForGasStart,
        label: 'ガス開栓日に連絡可能な電話番号',
        inputElement: {
          valueKey: ItemValueKey.PhoneNumberForGasStart,
          inputType: ComponentType.TextFieldInput,
          placeholder: '08012345678',
          inputComponentType: 'tel'
        },
        descriptionsAfterInput: [
          {
            messages: ['※立ち会い者と連絡可能な電話番号を入力ください']
          }
        ],
        isDisplayCondition: useElectronicCondition
      }
    ],
    isDisplayCondition: useElectronicCondition
  },
  {
    id: 'CustomerInformation',
    title: 'お客様情報',
    components: [
      {
        id: ItemValueKey.CustomerNamePrefix,
        inputElement: {
          valueKey: ItemValueKey.CustomerNamePrefix,
          inputType: ComponentType.KanaNameAutoCompleteInput
        },
        showOnlyInput: true
      },
      {
        id: ItemValueKey.CustomerName,
        label: '氏名',
        inputElements: [
          {
            valueKey: ItemValueKey.CustomerLastName,
            inputType: ComponentType.TextFieldInput
          },
          {
            valueKey: ItemValueKey.CustomerFirstName,
            inputType: ComponentType.TextFieldInput
          }
        ],
        showOnlyConfirmation: true
      },
      {
        id: ItemValueKey.CustomerNameKana,
        label: '氏名(カナ)',
        inputElements: [
          {
            valueKey: ItemValueKey.CustomerLastNameKana,
            inputType: ComponentType.TextFieldInput
          },
          {
            valueKey: ItemValueKey.CustomerFirstNameKana,
            inputType: ComponentType.TextFieldInput
          }
        ],
        showOnlyConfirmation: true
      },
      {
        id: ItemValueKey.CustomerBirthDay,
        label: '生年月日',
        inputElement: {
          valueKey: ItemValueKey.CustomerBirthDay,
          inputType: ComponentType.SelectDateInput
        }
      },
      {
        id: ItemValueKey.CustomerPhoneNumber,
        label: '電話番号',
        inputElement: {
          valueKey: ItemValueKey.CustomerPhoneNumber,
          inputType: ComponentType.TextFieldInput,
          placeholder: '08012345678',
          inputComponentType: 'tel'
        }
      },
      {
        id: ItemValueKey.CustomerGender,
        label: '性別',
        inputElement: {
          valueKey: ItemValueKey.CustomerGender,
          inputType: ComponentType.SelectItemInput,
          placeholder: '性別を選択',
          initialValue: '',
          selectionItems: [
            {
              value: '1',
              label: '男性'
            },
            {
              value: '2',
              label: '女性'
            },
            {
              value: '3',
              label: '無回答'
            }
          ]
        }
      },
      {
        id: ItemValueKey.CustomerEmail,
        label: 'メールアドレス',
        inputElement: {
          valueKey: ItemValueKey.CustomerEmail,
          inputType: ComponentType.EmailInput
        }
      }
    ]
  },
  {
    id: ItemValueKey.PaymentMethodSelection,
    title: 'お支払い方法',
    confirmationTitle: 'お支払い手続き',
    confirmationDisplayType: ConfirmationDisplayType.EmphasizedDisableInput,
    components: [
      {
        id: 'paymentInfo',
        confirmationLabel: '',
        descriptionsBeforeInput: [
          {
            messages: [
              '口座振替・クレジットカードをご選択の場合、申込書はガスを開ける当日に係員よりお渡しいたします。',
              '※お手続が完了するまでは払込用紙でのお支払いとなります。'
            ]
          }
        ],
        inputElement: {
          valueKey: ItemValueKey.PaymentMethodSelection,
          inputType: ComponentType.RadioButtonInput,
          initialValue: '0',
          selectionItems: [
            {
              value: '0',
              label: '口座振替'
            },
            {
              value: '1',
              label: 'クレジットカード'
            },
            {
              value: '2',
              label: '払込用紙'
            }
          ]
        }
      }
    ],
    isDisplayCondition: useElectronicCondition
  }
];

export const ableGoToNextPageConditionDefinition = {
  needToInput: {
    // TOdO コメントアウトした部分も対応できるようにする
    inputValues: [
      // ItemValueKey.ElectricityStartDate,
      // ItemValueKey.GasStartTimeSelection,
      // ItemValueKey.WitnessNameGasStart,
      // ItemValueKey.PhoneNumberForGasStart,
      ItemValueKey.CustomerFirstName,
      ItemValueKey.CustomerLastName,
      ItemValueKey.CustomerFirstNameKana,
      ItemValueKey.CustomerLastNameKana,
      ItemValueKey.CustomerPhoneNumber,
      ItemValueKey.CustomerGender,
      ItemValueKey.CustomerEmailInput,
      ItemValueKey.CustomerEmailConfirmation
    ]
  },
  hasNoError: {
    inputValues: [
      ItemValueKey.ElectricityStartDate,
      ItemValueKey.GasStartTimeSelection,
      ItemValueKey.PhoneNumberForGasStart,
      ItemValueKey.CustomerFirstName,
      ItemValueKey.CustomerLastName,
      ItemValueKey.CustomerFirstNameKana,
      ItemValueKey.CustomerLastNameKana,
      ItemValueKey.CustomerPhoneNumber,
      ItemValueKey.CustomerEmailInput,
      ItemValueKey.CustomerEmailConfirmation
    ]
  },
  satisfySpecificCondition: [
    {
      detailConditions: [
        {
          valueType: ValueOriginType.InputValues,
          valueKey: ItemValueKey.AgreeThePolicy,
          operator: DetailConditionOperator.Equal,
          targetValues: [
            {
              valueType: ValueOriginType.Fixed,
              valueKey: true
            }
          ]
        }
      ]
    }
  ]
};

export const pageDisplayText = {
  initialTemplateDisplayPlanLabel: '電気契約申し込み',
  initialTemplateDisplayPlanName: '京葉ガスの電気(スマモル賃貸プラン)に申し込む',
  initialTemplateNameDescriptions: [
    'ご入居予定の物件には、京葉ガスの電気「スマモル賃貸プラン」が標準採用されています。'
  ],
  initialTemplateDisplayNameCautions: ['スマモル賃貸プランに申し込まない場合は、物件の管理会社へご連絡ください。'],
  initialTemplateDisplayDetailCautions: [
    [
      { text: '各種サービスと併せて、', type: 'NORMAL' },
      {
        text: '京葉ガスの電気「スマモル賃貸プラン」に契約申込希望の場合は、物件の管理会社へご連絡ください。',
        type: 'YELLOW'
      },
      {
        text: '原則、入居日を過ぎてからスマモル賃貸プランに加入することは出来ません。',
        type: 'NORMAL'
      }
    ],
    [
      {
        text:
          '※京葉ガスの電気「スマモル賃貸プラン」に加入されない方でも、「スマモル賃貸サービス」へのお申し込みは必要になります。',
        type: 'NORMAL'
      }
    ]
  ]
};

export const getFooterOptions = {
  privacyPolicy: {
    text: '京葉ガスプライバシーポリシー',
    link: 'https://www.keiyogas.co.jp/policy.html'
  }
};

export type DescriptionElement = {
  text: string;
  type: ComponentDescriptionType;
  link?: string;
  path?: string;
};

export const confirmationText: {
  contents: DescriptionElement[][];
  isDisplay?: ComponentElementCondition[];
}[] = [
  {
    contents: [
      [
        {
          text:
            '上記でご入力いただいた情報は、「優待・割引サービス」「駆けつけサービス」で利用されます。また、お客さまが入居契約した物件の管理会社もご入力いただいた情報を利用します。',
          type: ComponentDescriptionType.Normal
        }
      ]
    ]
  },
  {
    contents: [
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '電気需給約款（低圧）',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/base_electric_term.pdf'
        }
      ],
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '電気料金プラン定義書【スマモル賃貸プラン】',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/base_price_definitions.pdf'
        }
      ],
      // [
      //   {
      //     text: '・',
      //     type: ComponentDescriptionType.Normal
      //   },
      //   {
      //     text: '重要事項説明書',
      //     type: ComponentDescriptionType.InternalLink,
      //     path: '/important-term'
      //   }
      // ],
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '駆けつけサービス契約約款',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/security_service_term.pdf'
        }
      ],
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '優待・割引サービス利用規約',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/benefit_one_term.pdf'
        }
      ],
      [
        {
          text: 'にご同意上、申込み内容のご確認へお進みください',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    isDisplay: useElectronicCondition
  },
  {
    contents: [
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '駆けつけサービス契約約款',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/security_service_term.pdf'
        }
      ],
      [
        {
          text: '・',
          type: ComponentDescriptionType.Normal
        },
        {
          text: '優待・割引サービス利用規約',
          type: ComponentDescriptionType.InternalLink,
          path: '/static/benefit_one_term.pdf'
        }
      ],
      [
        {
          text: 'にご同意上、申込み内容のご確認へお進みください',
          type: ComponentDescriptionType.Normal
        }
      ]
    ],
    isDisplay: notUseElectronicCondition
  }
];

export const baseFormText: {
  useInformationConfirmation: string[];
  needToConfirmContent: {
    content: DescriptionElement[][];
    isDisplay: Array<ComponentElementCondition>;
  }[];
} = {
  useInformationConfirmation: [],
  needToConfirmContent: [
    {
      content: [
        [
          {
            text: '・',
            type: ComponentDescriptionType.Normal
          },
          {
            text: '電気需給約款（低圧）',
            type: ComponentDescriptionType.InternalLink,
            path: '/static/base_electric_term.pdf'
          }
        ],
        [
          {
            text: '・',
            type: ComponentDescriptionType.Normal
          },
          {
            text: '電気料金プラン定義書【スマモル賃貸プラン】',
            type: ComponentDescriptionType.InternalLink,
            path: '/static/base_price_definitions.pdf'
          }
        ],
        // [
        //   {
        //     text: '・',
        //     type: ComponentDescriptionType.Normal
        //   },
        //   {
        //     text: '重要事項説明書',
        //     type: ComponentDescriptionType.ExternalLink,
        //     path: '/important-term'
        //   }
        // ],
        [
          {
            text: '・',
            type: ComponentDescriptionType.Normal
          },
          {
            text: '駆けつけサービス契約約款',
            type: ComponentDescriptionType.InternalLink,
            path: '/static/security_service_term.pdf'
          }
        ],
        [
          {
            text: '・',
            type: ComponentDescriptionType.Normal
          },
          {
            text: '優待・割引サービス利用規約',
            type: ComponentDescriptionType.InternalLink,
            path: '/static/benefit_one_term.pdf'
          }
        ],
        [
          {
            text: 'にご同意上、申込み内容のご確認へお進みください',
            type: ComponentDescriptionType.Normal
          }
        ]
      ],
      isDisplay: useElectronicCondition
    },
    {
      content: [
        [
          {
            text: '・',
            type: ComponentDescriptionType.Normal
          },
          {
            text: '駆けつけサービス契約約款',
            type: ComponentDescriptionType.InternalLink,
            path: '/static/security_service_term.pdf'
          }
        ],
        [
          {
            text: '・',
            type: ComponentDescriptionType.Normal
          },
          {
            text: '優待・割引サービス利用規約',
            type: ComponentDescriptionType.InternalLink,
            path: '/static/benefit_one_term.pdf'
          }
        ],
        [
          {
            text: 'にご同意上、申込み内容のご確認へお進みください',
            type: ComponentDescriptionType.Normal
          }
        ]
      ],
      isDisplay: useElectronicCondition
    }
  ]
};

export const getImportantConfirmationText = (
  env: 'develop' | 'staging' | 'production'
): {
  electronic: DescriptionElement[][];
  gas?: DescriptionElement[][];
  service?: DescriptionElement[][];
  company?: DescriptionElement[][];
} => {
  return {
    electronic: [
      [
        {
          text: '1.お申し込み方法',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) スマモル賃貸プランのお申込みをご希望の場合、物件管理等を行う事業者(以下「管理会社等」といいます。)より、お客さまからお知らせいただいた電子メールアドレス宛または携帯電話番号宛に、当社のお申込みサイトのURLをお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) お客さまは、お申込みにあたって、原則お申込みサイトの案内に従い、お申込みに必要な情報を入力いただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) お申込みいただいた内容は、スマモル賃貸プランの申込みに利用するほか、スマモル賃貸プランの付帯サービスのご利用に必要な範囲で、管理会社等や関連会社と共同利用します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4) お申込みサイトには、お客さまからあらかじめ管理会社等にお伝えいただいた入居情報が表示されます。表示内容が異なる場合は、お申込み内容の入力前に、必ず管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '2.お申し込みの承諾について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '　当社は、以下に該当するお客さまからお申し込みをいただいた場合、お申し込みを承諾しないことがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1) ガス料金や電気料金等、お客さまが当社に支払うべき料金を支払期日が過ぎてもお支払いいただけていない場合、または支払期日が過ぎてからお支払いいただいたことがある場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(2) 現在、当社のガスをご使用されていない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　例外1:現在の電気のご使用場所において、LPガス等から当社の都市ガスに変更していただく予定があり、当社は、既にお客さまからガス工事等のご依頼をいただいている場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　例外2:お引越し等にともない、「新たな電気のご使用場所(以下、引越し先等)」でガス使用契約のお申し込みをいただく場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) 電気のご使用場所とガスのご使用場所が同じ場合であって、電気料金をガス料金と合算し、同一の方法で支払うことにご了承いただけない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4) お引越し等にともない、引越し先等においても(3)の規定と同様に電気料金をガス料金と合算し、同一の方法で支払うことにご了承いただけない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5) 現在、ガス料金を払込用紙でお支払いいただいている場合であって、そのお支払い方法を口座振替またはクレジットカード払いに変更して支払うことにご了承いただけない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6) お引越し等にともない、新たに電気需給契約とガス使用契約をお申し込みいただく場合であって、電気料金とガス料金を口座振替またはクレジットカードで支払うことにご了承いただけない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(7) 口座振替またはクレジットカード払いの手続きが完了していない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(8) 同一のご使用場所において、繰り返し、契約のお申し込みと解約をご希望される場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(9) 他の小売電気事業者へのスイッチングを除き、お客さまが当社との契約を解約した後、1年以内に再び、当社とのご契約を希望される場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '3.スマートメーターの設置',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) お客さまの電気のご使用場所において、電気メーターがスマートメーターに交換されていない場合、原則、当社の電気の供給が開始される前に送配電事業者がスマートメーターの交換を行います。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) 送配電事業者は、スマートメーターへの交換を工事会社に委託し、委託を受けた工事会社の係員がスマートメーターの交換に伺います。その際、メーター交換にかかる費用は原則、送配電事業者が負担します。また、従来の電気メーターと同様にスマートメーターは、送配電事業者の所有物となります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) 送配電事業者の都合でスマートメーターの設置が遅れた場合や設置が困難な場合でも、当社はお客さまへの電気の供給を開始させていただきます。この場合、送配電事業者は従来どおり、検針員による検針で電気使用量の計量等を実施します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '4.電気のご使用量およびご請求額の確認について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '(1) ウェブサイトにて電気のご使用量とご請求額等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) お客さまご自身でウェブサイトに会員登録をしていただき、登録の後、当社に登録した「お客さまID」を当社お客さまコールセンターにご連絡ください。当社は、ご連絡をいただいた後、お客さまがウェブサイトでご確認ができるよう、手続きをさせていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) ウェブサイトの利用ができないお客さまには、ご使用量(kWh)、ご請求額(円)、検針日を記載した簡易的な書面等を送付いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '5.他の小売電気事業者(現在の電力会社)からの当社への切り替え',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '他の小売電気事業者から当社の電気に切り替える際、他の小売電気事業者との契約条件により、お客さまに解約金等の不利益事項が発生する場合があります。当社とのご契約の前に他の小売電気事業者との契約内容を十分にご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '6.ご契約内容の確認',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '(1) 電気需給契約は、お客さまからのお申し込みを当社が承諾したときに成立します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) ご契約いただく電気料金プランや契約容量(A)は、原則、お客さまからお申し込みいただいた内容をもとに決定させていただきます。ただし、お申し込みいただいた内容が送配電事業者に登録されている情報と異なる場合には、お客さまと協議のうえ、決定させていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '7.電気需給開始予定日',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 他の小売電気事業者(現在の電力会社)から当社に契約を切り替える場合、需給開始(供給開始)予定日は、原則、以下の1または2のとおりとなります。なお、需給開始予定日は、契約手続きが完了した後、当社所定の方法でお客さまにお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　1 スマートメーターが設置されていない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　　お申し込み後、契約手続き等が完了した日から起算して8営業日に2暦日を加えた日以降に到来する最初の電気の検針日',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　2 スマートメーターが設置されている場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　　お申し込み後、契約手続き等が完了した日から起算して1営業日に2暦日を加えた日以降に到来する最初の電気の検針日',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　　※需給開始日は指定できません。また、お申し込み時のお客さま情報等に誤りがあった場合には、契約完了までに時間を要することがありますので、ご了承願います。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(2) お引越し等にともなう、引越し先等の需給開始予定日は、原則、お客さまがご希望される日となります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) お客さまが引越し先等でいずれの小売電気事業者とも契約せずに電気の使用を開始し、後に当社との電気需給契約が成立した場合は、その使用を開始した日を需給開始日とします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '8.供給電圧および周波数',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '当社が供給する電気の供給標準電圧は、単相100V、単相100Vおよび200V、3相200Vのいずれかとなります。また、標準周波数は50Hzとなります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '9.電気ご使用量の計量や電気料金の計算方法について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 当社は、送配電事業者が計量した電気使用量を計量日以降に受領し、当社の約款等にもとづき、電気料金を計算いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) 当社は、供給開始初月、または契約を解約される月において、対象の計量期間(日数)が30日を下回る場合、当社の約款等にもとづき、電気料金を日割計算いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '10.電気料金のお支払い方法について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text: '(1) 電気料金は、原則としてガス料金と同一の方法で合算してお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) ガス料金と同一の方法とは、口座振替でお支払いいただいている場合は、お支払い口座と同一の口座から振り替えることをいい、クレジットカードでお支払いいただいている場合は、お支払いのクレジットカードと同一のクレジットカードにてお支払いいただくことをいいます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) ガス料金を払込用紙でお支払いいただいている場合には、原則、口座振替払いまたはクレジットカード払いにお支払い方法を変更していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4) 電気料金のご請求は、送配電事業者の計量結果を当社で受領後、原則、電気料金のご請求額が確定した後、直近に到来するガスの検針結果で算定するガス料金と合算してご請求いたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5) お客さまのガスの検針日と電気の検針日(計量日)が近接しており、電気料金の確定がガス料金との合算に間に合わない場合、電気料金を合算せず、ガス料金のみでご請求させていただく月が発生いたします。また、この場合、合算できなかった電気料金は、翌月以降のガス料金に合算してご請求させていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6) (5)の事例が発生した場合、翌月以降のガス料金に複数月分の電気料金を合算しないよう、翌月以降の電気料金のご請求時期を調整いたします。ただし、ご契約を解約される場合、精算時に複数月分と最終月の電気料金を合算してご請求させていただくことがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(7) 従来のクレジットカード払いと同様にカード会社の締め日との関係等により、合算した料金を複数月分、まとめて1度にお支払いいただく場合や請求がない月が発生する場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '11.お客さまからのお申し出による契約の変更・解約について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 契約の変更やお引越し(転居)にともなう解約については、当社問い合わせ先までご連絡いただき、解約希望日をお知らせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) お客さまより、実際に使用を廃止した日以降に解約のお申し出をいただいた場合は、原則として、そのお申し出を受け付けた日を当社は、解約日とさせていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) 他の小売電気事業者への切り替えにともなう解約については、当社にご連絡をいただく必要はありません。切り替え先の小売電気事業者にお申し込みください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4) 契約中に、当社との電気需給契約を廃止し、他の小売電気事業者との需給契約等にもとづき当該需要場所で引き続き電気を使用される場合、当社による解約または当社の他の電気料金プランへの変更により、お客さまにスマモル賃貸プランの適用がなくなった場合は、原則として、適用がなくなった日から適用開始日より24か月経過する予定であった日までの残存期間(1か月未満の端数は切り捨てます。)に応じて、1か月あたり1,200円を解約金として最終の電気料金とあわせて当社にお支払いいただきます。ただし、転居等のやむをえない場合の解約は、当社は解約金をいただきません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5) スマモル賃貸プランの適用開始日が属する月をひと月目として24か月目の月の末日までに解約した場合、適用開始日より24か月経過する予定であった日まではスマモル賃貸プランの付帯サービスをご利用いただけます。適用開始日より24か月経過する予定であった日以降のスマートロックのご利用には、別途費用が発生する場合があります。詳細は管理会社等にお問い合わせください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '12.その他需給に関わる費用',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 需給開始等にともない工事費負担金が発生した場合、当社は、送配電事業者が見積り算定した費用をお客さまにご請求させていただきます。また、当該費用は工事着手前にお支払いいただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) お客さまが契約電力等を新たに設定した場合や契約電力を増加した場合、その日以降、1年に満たないで電気需給契約を解約される場合、または契約電力等を減少しようとされる場合、送配電事業者が定める託送供給等約款(以下、託送約款)に従い、当社は電気需給契約の解約または変更の日に当社が送配電事業者に支払うべき金額をお客さまに精算していただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) 当社は、お客さまが電気を不正に使用した際の違約金等、送配電事業者が当社に請求する費用についてお客さまにご請求させていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '13.電気の需給に関するお客さまのご協力のお願い',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '電気の需給契約にあたり、お客さまには託送約款に規定された事項を遵守していただきます。また、当社もしくは送配電事業者から以下の事項へのご協力をお願いする場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(1) お客さまの承諾を得たうえで、当社または送配電事業者が必要な業務のために実施するお客さまの土地・建物への立ち入り',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) お客さまの電気のご利用により他者の電気の使用を妨害する恐れがある、または送配電事業者の電気工作物に支障を及ぼす恐れがある場合、電気の品質の維持・改善のために必要な装置・設備の設置',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) 送配電事業者が所有する供給設備の故障・点検・修繕・変更その他、工事上やむを得ない場合、または電気の需給および保安上の必要がある場合に送配電事業者が実施する停電(お客さまの電気の使用の中止または制限)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4) 電気工作物に異常もしくは故障がある場合やその恐れがある場合、またはお客さまが電気工作物の変更の工事を行い、その工事が完成した場合のその旨の通知',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5) お客さまの電気のご利用に際し、送配電事業者が所有する供給設備の工事および維持のため必要な用地の確保',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '14.当社からの契約の変更および解約について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 当社は、託送約款や関係法令・条例・規則等の改正、あるいは社会的経済的に当社に大きな影響を及ぼす事象の発生、株式会社ビットキーが定めるビットキー利用規約、大阪ガス株式会社が定める駆けつけサービス契約約款、当社が定める優待・割引サービス利用規約の変更により、料金表を変更する必要性が生じた場合、その他、当社が必要と判断した場合、約款等を変更する場合があります。その場合、あらかじめ、変更後の約款等と変更の効力発生日を一定期間当社のホームページに掲載することでお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) 当社は、以下の場合、電気需給契約を解約させていただくことがあります。また、2の規定に該当する場合を除き、原則として事前にその旨をお客さまにお知らせいたします。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　1 お客さまが次のいずれかに該当する場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　a.電気料金の支払期日を経過してなお、お支払いいただけない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　b.当社との他の契約(既に消滅しているものを含む)における債務を期日までに履行しない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　c.約款等によってお支払いいただくことが必要となった電気料金以外の債務(延滞利息や工事費負担金等)を履行しない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　d.電気を使用する需要場所において、当社とのガス使用契約が解約となった場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　e.電気料金を口座振替またはクレジットカードでお支払いいただけない場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　2 お客さまが引越し等の理由により、電気需給契約を解約する旨を当社へ申し出ずにその需要場所から移転し、電気を使用していないことが明らかな場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　3 お客さまの責めとなる理由等により、送配電事業者が託送供給を停止した場合、またはその恐れがある事実が判明した場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　4 お客さまが約款等、託送約款または関連法令・条例・規則等に反した場合',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '15.割引料金について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '当社は、当社のスマモル賃貸プランの定義書にもとづく電気料金プランのいずれかをご契約いただいている需要場所においてお客さまが当社とガス使用契約を締結している場合、その種別に応じて、約款等に定める以下の割引種別のいずれかを適用させていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　「ペア割(定率)」 「ほっと割(定率)」 「ピカ割(定率)」',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '1 原則、割引料金は電気の需給開始日より適用させていただきます。ただし、電気の計量日においてガスの使用が開始されていない場合、当該計量日を含む使用期間の割引額は0円とさせていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '2 お客さまがガス使用契約の種別を変更した場合、当社は変更後のガス使用契約の種別に応じて割引種別を変更し、変更後のガス使用契約成立日以降に到来する電気の計量日より適用させていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '3 お客さまのガスのご利用状況によって、割引種別の適用条件を満たさないことが判明した場合、当社は、判明した日以降に到来する電気の計量日を含む使用期間の割引額を0円とさせていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '16.電気需給契約締結前および締結後にお客さまへお知らせすべき事項を記載した書面交付について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 当社は、お客さまと新たに電気需給契約を締結しようとするとき、または既に締結している契約の内容を変更しようとするときであって、お客さまに対し、供給条件の説明、契約締結前の書面交付を行う場合は、書面の交付、インターネット上での開示または電子メールの送信その他当社が適当と判断した方法(以下、当社が適当と判断した方法)により行います。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) 当社は、お客さまと新たに電気需給契約を締結したとき、または既に締結している契約の内容を変更したときであって、お客さまに対し、供給条件の説明、契約締結後の書面交付を行う場合は、当社が適当と判断した方法により行い、当社の名称および住所、契約年月日ならびに供給地点特定番号を記載します。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) 当社がお客さまと既に締結している契約の内容を変更しようとするときにお知らせする事項は、「当社の名称および住所」、「契約年月日」、「変更する事項」、「供給地点特定番号」とさせていただきます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '17.付帯サービスの適用について',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '当社は、お客さまに対し、当社または当社が委託するサービス提供会社による付帯サービスを提供することがあります。付帯サービスを利用される場合には、別途定める規約に従っていただきます。付帯サービスの規約および内容(適用条件、適用期間等)は、当社ホームページ等にてご確認ください。なお、当社ホームページ等で事前にお知らせすることにより、お客さまの承諾なく付帯サービスの内容の変更やサービス自体を終了することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '18.でんきのあんしんサポートサービスについて',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 当社のスマモル賃貸プランの定義書にもとづく電気料金プランを適用しているお客さまはご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) 本サービスは毎月15日を基準日とし、電気を供給開始させていただいた直後の基準日を含む月の翌月からご利用いただけます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(3) 本サービスは停電、分電盤やブレーカーの不具合等の電気設備(電気のご利用に必要な設備で家電製品・照明器具等を除くもの)のトラブルや通電不良に関し、不具合箇所および原因を調査するサービスです。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(4) 本サービスの提供を、当社は東京電力パワーグリッド株式会社に委託しております。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(5) 本サービスの対象外となる不具合の場合、電話口での不具合解決に関するご案内で解決する場合、非常変災の場合等、出張サービスを行わない事があります。また、天候・交通状況・作業員の作業状況等により出張に時間を要する場合があります。なお、火災の恐れがある等の緊急の場合、お客さまの安全のために消防等へ連絡をお願いし、出張を行わないことがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6) 本サービスの無料の範囲は、電気設備や通電不良に関する原因究明作業に要する60分以内の調査費および出張費となります。部品代、特殊作業費、60分以上の作業費の他、電気設備の故障や破損に伴う設備取替等を含む二次対応の費用についてはお客さまのご負担となります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(7) 当社ホームページ等において事前にお知らせすることにより、お客さまの承諾なく本サービスの内容を変更し、または本サービス自体を終了することがあります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(8) 本サービスの規約および詳細については、当社ホームページ等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '19.契約解除(クーリングオフ※)に関する事項',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) 当社または当社の代理店等からの勧誘を受けてお申し込みされた場合、お客さまがお申し込み内容を記載した申込書を当社が受領した日から起算して8日を経過する日までの間は、書面により無条件に契約のお申し込みの撤回または契約の解除ができます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) クーリングオフの効力は、お客さまが書面を発信したとき(郵便消印日付)から生じます。当社宛てに郵送してください。(簡易書留扱いが確実です。)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(3) 発信する書面には以下の内容をご記載ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　1 お客さまご住所・お名前(漢字およびカナ)・お電話番号',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　2 クーリングオフのお申し出年月日',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　3 お申し込みを撤回または契約を解除する内容',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　(例:「電気需給契約申込」または「電気需給契約」)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　4 3のお申し込みを撤回または契約を解除する意向',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '　　(例:「電気需給契約申込を撤回します」または「電気需給契約を解除します」)',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(4) クーリングオフにお申し出いただいた場合、お客さまは、損害賠償または違約金を支払う必要はなく、当該契約にもとづき既に電気が供給されていた場合においても、当該電気の供給に係る対価、その他の金銭を支払う必要はありません。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '(5) クーリングオフにお申し出いただいた場合、スマートロックの利用に別途費用が発生する場合があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(6) お客さまがクーリングオフにより契約を解除されると無契約状態となり、その状態が継続した場合は送配電事業者により、電気の供給が停止される恐れがありますので、当社の新たな電気需給契約または他の小売電気事業者と契約いただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '　　※「特定商取引に関する法律」の適用を受けて、お客さまが当該お申し込みの撤回、または当該契約の解除を行うことをいいます。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '20.お客さま情報の取扱いについて',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '本業務機会を通じてお客さまより取得するお客さまの名義、需要場所(供給地点特定番号を含みます。)、電話番号等を当社と株式会社ビットキー、大阪ガスセキュリティサービス株式会社、株式会社ベネフィット・ワンで共同利用します。それ以外の個人情報については、当社のプライバシーポリシーまたは株式会社ビットキー、大阪ガスセキュリティサービス株式会社、株式会社ベネフィット・ワンのプライバシーポリシーに従って利用いたします。なお、各プライバシーポリシーの詳細は、当社ホームページ等または各社公式サイト等でご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '21.その他',
          type: ComponentDescriptionType.Title
        }
      ],
      [
        {
          text:
            '(1) スマモル賃貸プランを申込む事前にビットキー利用規約・プライバシーポリシー、駆けつけサービス契約約款、優待・割引サービス利用規約を確認していただく必要があります。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text:
            '(2) その他、スマモル賃貸プランの付帯サービスの詳細・最新情報については、各利用規約等をご確認ください。',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'ビットキー利用規約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'https://homehub.site/term/usage-term/',
          type: ComponentDescriptionType.ExternalLink,
          link: 'https://homehub.site/term/usage-term/'
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '株式会社ビットキーのプライバシーポリシー',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'https://www.bitlock.jp/legal/privacy-policy',
          type: ComponentDescriptionType.ExternalLink,
          link: 'https://www.bitlock.jp/legal/privacy-policy'
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '駆けつけサービス契約約款',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'https://www.keiyogas.co.jp/denki/ryoukin.html?anchor=yakkan',
          type: ComponentDescriptionType.ExternalLink,
          link: 'https://www.keiyogas.co.jp/denki/ryoukin.html?anchor=yakkan'
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '大阪ガスセキュリティサービス株式会社のプライバシーポリシー',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'https://www.oss-og.co.jp/policy/',
          type: ComponentDescriptionType.ExternalLink,
          link: 'https://www.oss-og.co.jp/policy/'
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '優待・割引サービス利用規約',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'https://www.keiyogas.co.jp/denki/ryoukin.html?anchor=yakkan',
          type: ComponentDescriptionType.ExternalLink,
          link: 'https://www.keiyogas.co.jp/denki/ryoukin.html?anchor=yakkan'
        }
      ],
      [
        {
          text: '',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: '株式会社ベネフィット・ワンのプライバシーポリシー',
          type: ComponentDescriptionType.Normal
        }
      ],
      [
        {
          text: 'https://bs.benefit-one.co.jp/BE-ONE/official/handling.html',
          type: ComponentDescriptionType.ExternalLink,
          link: 'https://bs.benefit-one.co.jp/BE-ONE/official/handling.html'
        }
      ]
    ],
    gas: [],
    service: [],
    company: []
  };
};
