import ActionType from './types';
import { ActionWithPayload } from '../utils/types';

export const setEmail = (email: string): ActionWithPayload<ActionType.setEmail, string> => ({
  type: ActionType.setEmail,
  payload: email
});

export const setPhoneNumber = (phoneNumber: string): ActionWithPayload<ActionType.setPhoneNumber, string> => ({
  type: ActionType.setPhoneNumber,
  payload: phoneNumber
});

export const setBirthday = (birthday: string): ActionWithPayload<ActionType.setBirthday, string> => ({
  type: ActionType.setBirthday,
  payload: birthday
});

export const setAccessToken = (token: string): ActionWithPayload<ActionType.setAccessToken, string> => ({
  type: ActionType.setAccessToken,
  payload: token
});

export const setUserId = (token: string): ActionWithPayload<ActionType.setUserId, string> => ({
  type: ActionType.setUserId,
  payload: token
});

export const setRefreshToken = (refreshToken: string): ActionWithPayload<ActionType.setRefreshToken, string> => ({
  type: ActionType.setRefreshToken,
  payload: refreshToken
});

export type UserAction =
  | ReturnType<typeof setEmail>
  | ReturnType<typeof setPhoneNumber>
  | ReturnType<typeof setBirthday>
  | ReturnType<typeof setUserId>
  | ReturnType<typeof setAccessToken>
  | ReturnType<typeof setRefreshToken>;
