import * as React from 'react';
import { useMemo, useContext } from 'react';
import { getComponentElementKeyValue } from '../utils/key-value-getter';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import OccupancyLabel from '../OccupancyLabel';

interface P {
  componentElement: ComponentInputElement;
}

const FixedTextElement: React.FC<P> = ({ componentElement }) => {
  const { inputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);

  const inputValue = useMemo(
    () =>
      getComponentElementKeyValue({
        inputValues,
        applicationParams,
        inputValueType: componentElement.valueType,
        valueKey: valueKey,
        initialValue: componentElement.initialValue,
        converter: componentElement.converter
      }),
    [
      inputValues[valueKey],
      applicationParams[valueKey],
      componentElement.valueType,
      valueKey,
      componentElement.initialValue
    ]
  );

  return <OccupancyLabel style={{ margin: '8px 0 0 !important' }} label="" desc={inputValue} />;
};

export default React.memo(FixedTextElement);
