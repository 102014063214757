import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import { AnyAction } from 'redux';
import { AppState } from '../../store';
import moment from 'moment';
import { bitlockApiPath, ifBaseApiPath, basicSiteVariablesDefinition } from '../../config/baseConfig';
import { ItemValueKey } from '../../config/item-value-key';
import { ApplicationCategory } from '../../enums/common/application-category';
import { FixedValueKey } from '../../types/common/fixed-value-key';
import { BasicItemKeys } from '../../enums/item/basic-item-keys';
import { SumamoruInstallAppType } from '../property/types';

export const submitParamater = (): ThunkAction<Promise<void>, AppState, {}, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(Actions.submitParamater());

    const email = getState().user.email;
    const phoneNumber = getState().user.phoneNumber;
    const access_token = getState().user.access_token;
    const refresh_token = getState().user.refresh_token;
    const user_id = getState().user.user_id;
    const propParam = getState().property.property;
    const method = 'POST';
    const bitlockManageEndpoint = `${bitlockApiPath}/users`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': access_token
    };
    const body = JSON.stringify({
      bkp_user_id: user_id,
      email: email,
      phone_number: phoneNumber
    });

    let status = 0;

    const bitlockRes = await fetch(bitlockManageEndpoint, {
      method,
      headers,
      body,
      mode: 'cors'
    }).then(res => {
      if (res.status === 200 || res.status === 409 || res.status === 201) {
        status = res.status;
      } else if (res.status === 401) {
        dispatch(Actions.failedToSubmitParamater('unauthorized'));
        return { statusCode: res.status };
      } else {
        dispatch(Actions.failedToSubmitParamater('internal'));
        return { statusCode: res.status };
      }
      status = res.status;
    });

    if (bitlockRes !== undefined && bitlockRes.statusCode === 401) return;
    // 409は既にアカウントがある状態なので申請に進んで良い
    if (status !== 200 && status !== 409 && status !== 201) {
      dispatch(Actions.failedToSubmitParamater('internal'));
      return;
    }
    const formParam = getState().applyForm.parameters;
    const endpoint = `${ifBaseApiPath}/application/create`;

    const { isSameEmail } = getState().applyForm.parameters;

    let formEmail = formParam.email;
    let formEmailConfirm = formParam.email_confirmation;

    if (isSameEmail) {
      formEmail = email;
      formEmailConfirm = email;
    }

    let bodyParameter;
    Object.values(ItemValueKey).forEach(valueKey => {
      bodyParameter = {
        ...bodyParameter,
        [valueKey]: formParam[valueKey]
      };
    });

    bodyParameter = {
      ...bodyParameter,
      [FixedValueKey.ProjectId]: basicSiteVariablesDefinition.projectId,

      // 認証関連
      [FixedValueKey.RefreshToken]: refresh_token,

      bkpUserId: user_id,
      bkpEmail: email,
      bkpPhoneNumber: phoneNumber,
      applicationCategory: ApplicationCategory.NewApplication,

      // bMからURL発行時点で確定している情報
      [FixedValueKey.HasElectricContract]: propParam.hasElectricContract,
      [FixedValueKey.OrganizationId]: getOrganizationId(propParam.allSpaceId),
      [FixedValueKey.AllSpaceId]: propParam.allSpaceId,
      [BasicItemKeys.PropertyId]: propParam.allSpaceId,
      [FixedValueKey.ContractId]: propParam.contractId,
      [FixedValueKey.PropertyServiceCode]: propParam.sumamoruCode,
      [FixedValueKey.Address1]: propParam.prefecture + propParam.city + propParam.afterCity,
      [FixedValueKey.Address2]: propParam.buildingName + propParam.propertyName,
      [FixedValueKey.PostCode]: propParam.postCode,
      [FixedValueKey.OccupyScheduledDate]: moment(propParam.occupyScheduledDate).format(
        basicSiteVariablesDefinition.linkageDateFormat
      ),
      [FixedValueKey.PropertyCode]: propParam.propertyCode,
      [FixedValueKey.Prefecture]: propParam.prefecture,
      [FixedValueKey.City]: propParam.city,
      [FixedValueKey.AfterCity]: propParam.afterCity,
      [FixedValueKey.BuildingName]: propParam.buildingName,
      [FixedValueKey.PropertyName]: propParam.propertyName,
      installAppName:
        propParam.sumamoruInstallAppTypes &&
        propParam.sumamoruInstallAppTypes.some(type => type === SumamoruInstallAppType.homehub)
          ? SumamoruInstallAppType.homehub
          : SumamoruInstallAppType.bitlock
    };

    fetch(endpoint, {
      method,
      headers,
      body: JSON.stringify(bodyParameter),
      mode: 'cors'
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(Actions.successToSubmitParamater());
        } else {
          dispatch(Actions.failedToSubmitParamater('internal'));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(Actions.failedToSubmitParamater('internal'));
      });
  } catch (error) {
    console.error(error);
    dispatch(Actions.failedToSubmitParamater('internal'));
  }
};

const getDateString = (date?: string): string | undefined => {
  if (!date || date.length === 0) {
    return undefined;
  }
  return moment(new Date(date)).format(basicSiteVariablesDefinition.linkageDateFormat);
};

const getOrganizationId = (allSpaceId: string): string | undefined => {
  if (!allSpaceId || allSpaceId.length === 0) {
    return undefined;
  }
  const splited = allSpaceId.split('_');
  if (!splited || splited.length === 0) {
    return undefined;
  }
  return splited[0];
};
