import * as React from 'react';
import { useMemo, useContext } from 'react';
import { getConfirmationInputText } from '../utils/key-value-getter';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { OccupancyLabelBox } from '../OccupancyLabel';
import { ConfimationFormStateContext } from '../template/ConfirmTemplate';

interface P {
  component: ComponentInputElement;
}

const EmphasizedDisableInput: React.FC<P> = ({ component }) => {
  const { inputValues, applicationParams } = useContext(ConfimationFormStateContext);

  const valueKey = useMemo(() => component.valueKey, []);

  const displayText = useMemo(
    () =>
      getConfirmationInputText({
        inputValues,
        applicationParams,
        inputValueType: component.valueType,
        valueKey: valueKey,
        initialValue: component.initialValue,
        converter: component.converter,
        inputComponentType: component.inputType,
        selectionItems: component.selectionItems
      }),
    [inputValues[valueKey], applicationParams[valueKey], component.valueType, valueKey, component.initialValue]
  );

  return <OccupancyLabelBox desc={displayText} />;
};

export default React.memo(EmphasizedDisableInput);
