export enum ItemValueKey {
  UseGasPlanSelection = 'useGasPlanSelection',
  ElectricityStartDate = 'electricityStartDate',
  ElectricityBasePlanSelection = 'electricityBasePlanSelection',
  ElectricityOptionPlanSelection = 'electricityOptionPlanSelection',
  GasStartDateTime = 'gasStartDateTime',
  GasStartDate = 'gasStartDate',
  GasStartTimeSelection = 'gasStartTimeSelection',
  OccupyScheduledDate = 'occupyScheduledDate',
  WitnessGasStart = 'witnessGasStart',
  WitnessNameGasStart = 'witnessNameGasStart',
  PhoneNumberForGasStart = 'phoneNumberForGasStart',
  GasBasePlanSelection = 'gasBasePlanSelection',
  GasOptionPlanSelection = 'gasOptionPlanSelection',
  CustomerName = 'customerName',
  CustomerNameKana = 'customerNameKana',
  CustomerNamePrefix = 'customer',
  CustomerLastName = 'customerLastName',
  CustomerFirstName = 'customerFirstName',
  CustomerLastNameKana = 'customerLastNameKana',
  CustomerFirstNameKana = 'customerFirstNameKana',
  CustomerBirthDay = 'customerBirthDay',
  CustomerPhoneNumber = 'customerPhoneNumber',
  CustomerGender = 'customerGender',
  CustomerEmail = 'customerEmail',
  CustomerEmailInput = 'customerEmailInput',
  CustomerEmailConfirmation = 'customerEmailConfirmation',
  PaymentMethodSelection = 'paymentMethodSelection',
  AgreeThePolicy = 'agreeThePolicy',
  ContactToContract = 'ContactToContract',
  ContactBeforeVisit = 'ContactBeforeVisit'
}
