import React, { FC, useState, useEffect, useCallback } from 'react';
import { Modal, Container, Button, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { Path } from '../constants/path';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { BaseFab } from '../components/BaseFab';
import { getImportantConfirmationText } from '../config/item-definitions';
import { ComponentDescriptionType } from '../enums/common/component-description-type';
import { stage } from '../config/baseConfig';

const TERMS_CONTAINER_HEIGHT = 300;

const useStyles = makeStyles(
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: 'var(--color-white)',
      width: '100%',
      margin: '0 20px',
      borderRadius: 10,
      '@media (min-width:768px)': {
        width: '50%'
      }
    },
    paperHeadingContainer: {
      backgroundColor: 'var(--color-key)',
      padding: '20px 16px',
      fontWeight: 300,
      fontStyle: 'normal',
      borderRadius: '10px 10px 0 0',
      position: 'relative'
    },
    paperHeading: {
      color: 'var(--color-white)',
      fontSize: 14,
      letterSpacing: '0.08em',
      margin: 0
    },
    closeIconButton: {
      position: 'absolute',
      top: 6,
      right: 10
    },
    closeIcon: {
      color: 'var(--color-white)'
    },
    agreementButton: {
      backgroundColor: 'var(--color-key)',
      color: 'var(--color-white)',
      boxShadow: 'none',
      width: '100%',
      margin: '0 auto',
      '@media (min-width:768px)': {
        fontSize: '16px',
        letterSpacing: '0.75px',
        fontWeight: '500',
        width: '60%',
        marginTop: '20px'
      }
    },
    cancelButtonContainer: {
      margin: '20px auto',
      textAlign: 'center',
      '@media (min-width:768px)': {
        fontSize: '16px',
        letterSpacing: '0.75px',
        fontWeight: '500',
        width: '60%'
      }
    },
    modalShopLabelContainer: {
      backgroundColor: 'var(--color-background-gray)',
      padding: '8px 12px',
      fontSize: 12,
      width: 'calc(100% - 24px)',
      margin: '16px 12px'
    },
    modalShopLabel: {
      margin: 0,
      color: 'var(--color-gray-3)',
      fontWeight: 500
    },
    modalShopDetails: {
      color: 'var(--color-gray-1)',
      lineHeight: '17px',
      margin: '8px 0 0',
      '@media (min-width:768px)': {
        marginTop: 0
      }
    },
    termsContainer: {
      marginTop: 16,
      paddingRight: 12,
      height: 300,
      overflowY: 'scroll'
    },
    terms: {
      margin: 0,
      fontSize: 12,
      color: 'var(--color-text)',
      lineHeight: '120%'
    },
    bottomContainer: {
      textAlign: 'center'
    }
  })
);

interface Props {
  modalOpen: boolean;
  setModalOpen: (e: boolean) => void;
  management_company_name: string;
  management_company_address: string;
  management_company_phonenumber: string;
  hasElectricContract: boolean;
  useGasPlanSelection: boolean;
}

const ApplicationModal: FC<Props> = ({
  modalOpen,
  setModalOpen,
  management_company_name,
  management_company_address,
  management_company_phonenumber,
  useGasPlanSelection,
  hasElectricContract
}) => {
  const styles = useStyles();
  const history = useHistory();

  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);

  const handleModalClose = () => {
    setModalOpen(false);
    setIsScrolledBottom(false);
  };

  useEffect(() => {
    const width = document.getElementById('root')!.clientWidth;
    setScreenWidth(width);
  }, []);

  const [resetScroll, setResetScroll] = useState(false);

  const handleScroll = useCallback(
    e => {
      if (resetScroll) {
        e.target.scrollTop = 8;
        setResetScroll(false);
        return;
      }
      const termsHeight = document.getElementById('terms')!.getBoundingClientRect().height;
      if (termsHeight - e.target.scrollTop - TERMS_CONTAINER_HEIGHT <= 10) {
        setIsScrolledBottom(true);
      }
    },
    [resetScroll]
  );

  const handleClick = () => {
    history.push(Path.application.confirm);
  };

  const [agreeFirst, setAgreeFirst] = useState(false);

  const importantConfirmationText = getImportantConfirmationText(stage);

  const sumamoruTermSentence = (
    <React.Fragment>
      {importantConfirmationText.service &&
        importantConfirmationText.service.map(unit => (
          <>
            {unit.map(item => (
              <>
                {item.type === ComponentDescriptionType.Normal ? (
                  <>
                    {item.text}
                    <br />
                  </>
                ) : item.type === ComponentDescriptionType.ExternalLink ? (
                  <>
                    <a target="_blank" rel="noopener noreferrer" href={item.link}>
                      {item.text}
                    </a>
                    <br />
                  </>
                ) : (
                  <h3>{item.text}</h3>
                )}
              </>
            ))}
          </>
        ))}
    </React.Fragment>
  );

  const electricTermSentences = (
    <React.Fragment>
      {importantConfirmationText.electronic.map(unit => (
        <>
          {unit.map(item => (
            <>
              {item.type === ComponentDescriptionType.Normal ? (
                <>
                  {item.text}
                  <br />
                </>
              ) : item.type === ComponentDescriptionType.ExternalLink ? (
                <>
                  <a target="_blank" rel="noopener noreferrer" href={item.link}>
                    {item.text}
                  </a>
                  <br />
                </>
              ) : (
                <h3>{item.text}</h3>
              )}
            </>
          ))}
        </>
      ))}
    </React.Fragment>
  );

  const gasTermSentence = (
    <React.Fragment>
      {importantConfirmationText.gas &&
        importantConfirmationText.gas.map(unit => (
          <>
            {unit.map(item => (
              <>
                {item.type === ComponentDescriptionType.Normal ? (
                  <>
                    {item.text}
                    <br />
                  </>
                ) : item.type === ComponentDescriptionType.ExternalLink ? (
                  <>
                    <a target="_blank" rel="noopener noreferrer" href={item.link}>
                      {item.text}
                    </a>
                    <br />
                  </>
                ) : (
                  <h3>{item.text}</h3>
                )}
              </>
            ))}
          </>
        ))}
    </React.Fragment>
  );

  const aboutCompany = (
    <React.Fragment>
      {importantConfirmationText.company &&
        importantConfirmationText.company.map(unit => (
          <>
            {unit.map(item => (
              <>
                {item.type === ComponentDescriptionType.Normal ? (
                  <>
                    {item.text}
                    <br />
                  </>
                ) : (
                  <h3>{item.text}</h3>
                )}
              </>
            ))}
          </>
        ))}
    </React.Fragment>
  );

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalOpen}
      className={styles.modal}
      onClose={() => setModalOpen(false)}
    >
      {!hasElectricContract ? (
        <div className={styles.paper}>
          <Container className={styles.paperHeadingContainer}>
            <h2 className={styles.paperHeading}>スマモル賃貸プランに関する重要事項説明</h2>
            <IconButton className={styles.closeIconButton} onClick={handleModalClose}>
              <CloseIcon className={styles.closeIcon} />
            </IconButton>
          </Container>

          <Container>
            <div className={styles.termsContainer} onScroll={handleScroll}>
              <div id={'terms'} className={styles.terms}>
                <p className={styles.terms}>
                  {sumamoruTermSentence}
                  <hr />
                  {aboutCompany}
                </p>
              </div>
            </div>
          </Container>

          {/* <Container className={styles.modalShopLabelContainer}>
            {screenWidth > 768 ? (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <p className={styles.modalShopLabel}>販売店(媒介業者)</p>
                  </Grid>
                  <Grid item xs={8}>
                    <p className={styles.modalShopDetails}>
                      {management_company_name}
                      <br />
                      {management_company_address}
                      <br />
                      TEL：{management_company_phonenumber}
                    </p>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <p className={styles.modalShopLabel}>販売店(媒介業者)</p>
                <p className={styles.modalShopDetails}>
                  {management_company_name}
                  <br />
                  {management_company_address}
                  <br />
                  TEL：{management_company_phonenumber}
                </p>
              </>
            )}
          </Container> */}

          <Container className={styles.bottomContainer}>
            <BaseFab
              className={styles.agreementButton}
              variant="extended"
              size="large"
              aria-label="add"
              disabled={isScrolledBottom === false}
              onClick={e => {
                if (useGasPlanSelection && hasElectricContract) {
                  setResetScroll(true);
                  setAgreeFirst(true);
                  setIsScrolledBottom(false);
                } else {
                  handleClick();
                }
              }}
            >
              同意して申込内容の確認へ
            </BaseFab>
            <div className={styles.cancelButtonContainer}>
              <Button onClick={handleModalClose}>キャンセル</Button>
            </div>
          </Container>
        </div>
      ) : (
        <>
          {!agreeFirst ? (
            <div className={styles.paper}>
              <Container className={styles.paperHeadingContainer}>
                <h2 className={styles.paperHeading}>スマモル賃貸プラン 電気契約の重要事項説明</h2>
                <IconButton className={styles.closeIconButton} onClick={handleModalClose}>
                  <CloseIcon className={styles.closeIcon} />
                </IconButton>
              </Container>

              <Container>
                <div className={styles.termsContainer} onScroll={handleScroll}>
                  <div id={'terms'} className={styles.terms}>
                    <p className={styles.terms}>
                      {sumamoruTermSentence}
                      <hr />
                      {electricTermSentences}
                      <hr />
                      {aboutCompany}
                    </p>
                  </div>
                </div>
              </Container>

              {/* <Container className={styles.modalShopLabelContainer}>
                {screenWidth > 768 ? (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <p className={styles.modalShopLabel}>販売店(媒介業者)</p>
                      </Grid>
                      <Grid item xs={8}>
                        <p className={styles.modalShopDetails}>
                          {management_company_name}
                          <br />
                          {management_company_address}
                          <br />
                          TEL：{management_company_phonenumber}
                        </p>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <p className={styles.modalShopLabel}>販売店(媒介業者)</p>
                    <p className={styles.modalShopDetails}>
                      {management_company_name}
                      <br />
                      {management_company_address}
                      <br />
                      TEL：{management_company_phonenumber}
                    </p>
                  </>
                )}
              </Container> */}

              <Container className={styles.bottomContainer}>
                <BaseFab
                  className={styles.agreementButton}
                  variant="extended"
                  size="large"
                  aria-label="add"
                  disabled={isScrolledBottom === false}
                  onClick={e => {
                    if (useGasPlanSelection && hasElectricContract) {
                      setResetScroll(true);
                      setAgreeFirst(true);
                      setIsScrolledBottom(false);
                    } else {
                      handleClick();
                    }
                  }}
                >
                  {useGasPlanSelection && hasElectricContract
                    ? 'ガスの重要説明事項の確認へ'
                    : '同意して申込内容の確認へ'}
                </BaseFab>
                <div className={styles.cancelButtonContainer}>
                  <Button onClick={handleModalClose}>キャンセル</Button>
                </div>
              </Container>
            </div>
          ) : (
            <div className={styles.paper}>
              <Container className={styles.paperHeadingContainer}>
                <h2 className={styles.paperHeading}>ガスの重要事項説明</h2>
                <IconButton className={styles.closeIconButton} onClick={handleModalClose}>
                  <CloseIcon className={styles.closeIcon} />
                </IconButton>
              </Container>

              <Container>
                <div className={styles.termsContainer} onScroll={handleScroll}>
                  <div id={'terms'} className={styles.terms}>
                    <p className={styles.terms}>
                      {gasTermSentence}
                      <hr />
                      {aboutCompany}
                    </p>
                  </div>
                </div>
              </Container>

              {/* <Container className={styles.modalShopLabelContainer}>
                {screenWidth > 768 ? (
                  <>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <p className={styles.modalShopLabel}>販売店(媒介業者)</p>
                      </Grid>
                      <Grid item xs={8}>
                        <p className={styles.modalShopDetails}>
                          {management_company_name}
                          <br />
                          {management_company_address}
                          <br />
                          TEL：{management_company_phonenumber}
                        </p>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <p className={styles.modalShopLabel}>販売店(媒介業者)</p>
                    <p className={styles.modalShopDetails}>
                      {management_company_name}
                      <br />
                      {management_company_address}
                      <br />
                      TEL：{management_company_phonenumber}
                    </p>
                  </>
                )}
              </Container> */}

              <Container className={styles.bottomContainer}>
                <BaseFab
                  className={styles.agreementButton}
                  variant="extended"
                  size="large"
                  aria-label="add"
                  disabled={isScrolledBottom === false}
                  onClick={handleClick}
                >
                  同意して申込内容の確認へ
                </BaseFab>
                <div className={styles.cancelButtonContainer}>
                  <Button onClick={handleModalClose}>キャンセル</Button>
                </div>
              </Container>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default ApplicationModal;
