import * as holidayjp from '@holiday-jp/holiday_jp';
import momentTimezone from 'moment-timezone';
import { basicSiteVariablesDefinition } from '../config/baseConfig';

export default class DateUtils {
  /**
   * 今日の日付をフォーマット化された文字列で返します。
   */
  public static strToday = (): string => {
    return momentTimezone()
      .tz('Asia/Tokyo')
      .format(basicSiteVariablesDefinition.linkageDateFormat);
  };

  /**
   * 指定された日数目の日付をフォーマット化された文字列で返します。
   * 例1）本日が金曜だとして、1を渡すと、同週の土曜日の日付が返ってきます。
   */
  public static offsetDaysToTargetDate = (days: number): string => {
    return momentTimezone
      .tz('Asia/Tokyo')
      .add(days, 'days')
      .format(basicSiteVariablesDefinition.linkageDateFormat);
  };

  /**
   * 指定された営業日目の日付をフォーマット化された文字列で返します。
   * 例1）本日が金曜だとして、1を渡すと、次週の月曜日の日付が返ってきます。
   * 例2）本日が土曜だとして、1を渡すと、次週の月曜日の日付が返ってきます。
   * @param businessDays
   * @param baseDate - counted as businessDay or holiday
   */
  public static businessDaysToTargetDate(businessDays: number, baseDate?: string | number | Date): string {
    const currentDate = baseDate ? momentTimezone(baseDate).tz('Asia/Tokyo') : momentTimezone.tz('Asia/Tokyo');

    let countBusinessDays = 0;
    while (countBusinessDays < businessDays) {
      currentDate.add(1, 'days'); // 参照先の日数がIncrementされる。
      const isBusinessDay =
        currentDate.day() !== 0 && currentDate.day() !== 6 && !holidayjp.isHoliday(currentDate.toDate());
      if (isBusinessDay) {
        countBusinessDays++;
      }
    }

    return currentDate.format(basicSiteVariablesDefinition.linkageDateFormat);
  }
}
