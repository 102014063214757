import React, { useState, useEffect } from 'react';
import { Modal, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Path } from '../../constants/path';
import { mapStateToProps, mapDispatchToProps } from '../../container/backModal';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    container: {
      background: 'var(--color-white)',
      borderRadius: 10,
      textAlign: 'left',
      padding: '20px 16px',
      position: 'relative',
      margin: '0 32px'
    },
    desc: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '21px',
      marginTop: 4
    },
    buttonsContainer: {
      marginTop: 20,
      textAlign: 'right'
    },
    cancelButton: {
      borderRadius: 4,
      padding: '4px 8px',
      color: 'var(--color-gray-3)',
      backgroundColor: 'var(--color-white)',
      fontWeight: 600,
      marginRight: 12,
      '&:hover': {
        backgroundColor: '#eee'
      }
    },
    submitButton: {
      backgroundColor: '#fff',
      borderRadius: 4,
      fontWeight: 600,
      padding: '4px 8px',
      color: '#E91E63',
      '&:hover': {
        backgroundColor: '#fff'
      }
    }
  })
);

interface PresenterProps {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & PresenterProps;

const BackModal: React.FC<Props> = ({ isOpen, setIsOpen, signinStatus, signupStatus, backStatus }) => {
  const styles = useStyles({});
  const history = useHistory();
  const [back, setBack] = useState(false);

  useEffect(() => {
    if (back && signinStatus === 'not_signin' && signupStatus === 'not_signup') {
      history.push(Path.account.signin);
    }
  }, [history, back, signupStatus, signinStatus]);

  const handleClick = () => {
    backStatus();
    setBack(true);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      className={styles.modal}
      onClose={() => setIsOpen(false)}
    >
      <div className={styles.container}>
        <p className={styles.desc}>アカウント連携に戻りますがよろしいですか？ご入力内容は保存されません。</p>
        <div className={styles.buttonsContainer}>
          <Button className={styles.cancelButton} onClick={() => setIsOpen(false)}>
            キャンセル
          </Button>
          <Button className={styles.submitButton} onClick={handleClick}>
            戻る
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BackModal;
