import ActionTypes from './types';
import { ValidationAction } from './actions';

type ValidationStatus = 'not_submit' | 'submit_loading' | 'submit' | 'error';

interface SignupTokens {
  accessToken: string;
  refreshToken: string;
  userId: string;
  email: string;
  phoneNumber: string;
}

export interface ValidationState {
  status: ValidationStatus;
  tokens: SignupTokens;
}

export const initialState: () => ValidationState = () => {
  return {
    code: '',
    status: 'not_submit',
    tokens: {
      accessToken: '',
      refreshToken: '',
      userId: '',
      email: '',
      phoneNumber: ''
    }
  };
};

const reducer = (state: ValidationState = initialState(), action: ValidationAction): ValidationState => {
  switch (action.type) {
    case ActionTypes.initSubmitStatus: {
      const newState = { ...state };
      newState.status = 'not_submit';
      return newState;
    }
    case ActionTypes.submitCode: {
      const newState = { ...state };
      newState.status = 'submit_loading';
      return newState;
    }
    case ActionTypes.submitSuccess: {
      const newState = { ...state };
      newState.status = 'submit';
      newState.tokens = action.payload;
      return newState;
    }
    case ActionTypes.submitFailed: {
      const newState = { ...state };
      newState.status = 'error';
      return newState;
    }
    default:
      ((_: never) => {
        return;
      })(action);
      return state;
  }
};

export default reducer;
