enum ActionType {
  startToFetchProperty = 'START_TO_FETCH_PROPERTY',
  setProperty = 'SET_PROPERTY',
  failedSetProperty = 'FAILED_SET_PROPERTY',
  setAllSpaceId = 'SET_ALL_SPACE_ID',
  setContractId = 'SET_CONTRACT_ID'
}

export type PropertyStatus = 'not_load' | 'property_loading' | 'set' | 'error';

export interface Property {
  allSpaceId: string;
  contractId: string;
  applicationStatus: string;
  hasElectricContract: boolean;
  occupyScheduledDate: Date;
  postCode: string;
  address: string;
  prefecture: string;
  city: string;
  afterCity: string;
  buildingName: string;
  propertyName: string;
  propertyCode: string;
  sumamoruCode: string;
  status: string;
  managementCompanyAddress: string;
  managementCompanyName: string;
  managementCompanyPhoneNumber: string;
  sumamoruInstallAppTypes: Array<string>;
}

export enum SumamoruInstallAppType {
  bitlock = 'bitlock',
  homehub = 'homehub'
}

export default ActionType;
