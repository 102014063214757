import React, { FC, useEffect, useMemo, useState } from 'react';
import { mapDispatchToProps, mapStateToProps } from '../container/identification';
import { ApplyHeading, ApplyLabel } from './Label';
import { Container, FormControl, Grid, MenuItem, Select, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BaseFab } from './BaseFab';
import Footer from './Footer';
import { useHistory } from 'react-router';
import { Loading } from './atoms/Loading';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { getFooterOptions } from '../config/item-definitions';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const YEAR: number[] = [];
for (let i = 1901; i < 2020; i++) {
  YEAR.push(i);
}
const MONTH = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: 'var(--color-key)',
    height: '180px',
    '@media (min-width:768px)': {
      borderRadius: '10px 10px 0 0'
    }
  },
  heading: {
    margin: 0,
    paddingTop: 20,
    color: 'var(--color-white)',
    fontSize: 16,
    letterSpacing: '0.08em',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  description: {
    color: 'var(--color-white)',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'left',
    padding: '0 24px'
  },
  contents: {
    '@media (max-width:767px)': {
      minHeight: 'calc(100vh - 296px)'
    }
  },
  container: {
    textAlign: 'left',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0'
    },
    '& .Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--color-key)'
      }
    }
  },
  selectPlaceholder: {
    color: '#BDBDBD'
  },
  caption: {
    fontSize: 12,
    lineHeight: '17px',
    color: 'var(--color-gray-3)',
    textAlign: 'left',
    '& span': {
      color: 'var(--color-sub)'
    }
  },
  error: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '17px',
    textAlign: 'left',
    color: 'var(--color-error)'
  },
  button: {
    marginTop: theme.spacing(2),
    color: 'var(--color-white)',
    backgroundColor: 'var(--color-key)',
    width: '90%'
  },
  linkContainer: {
    margin: '24px 24px 0'
  },
  link: {
    textDecoration: 'none',
    color: 'var(--color-key)',
    fontWeight: 600
  },
  footer: {
    marginTop: theme.spacing(4)
  },
  loadingContainer: {
    marginTop: 28,
    height: 298,
    background: '#ECEFF1',
    borderRadius: 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingCaption: {
    color: 'var(--color-key)',
    marginTop: 20,
    fontSize: 16,
    textAlign: 'center'
  },
  lockedIcon: {
    color: 'var(--color-sub)',
    fontSize: 144,
    marginTop: 8
  },
  lockedCaption: {
    color: 'var(--color-sub)',
    marginTop: 8,
    fontSize: 16,
    textAlign: 'center'
  },
  lockedSmallCaption: {
    margin: 16,
    color: 'var(--color-sub)',
    fontSize: 12
  }
}));

const IdentificationByBirth: FC<Props> = ({ setQueryParams, status, updateParameter, verifyIdentification }) => {
  const classes = useStyles();
  const history = useHistory();

  const [year = 1980, setYear] = useState<number | undefined>(undefined);
  const [month = 1, setMonth] = useState<number | undefined>(undefined);
  const [day, setDay] = useState<number | undefined>(undefined);
  const [lastday, setLastday] = useState<number[]>([]);
  const notMatchedBirthday = useMemo(() => status === 'unauthorized', [status]);

  useEffect(() => {
    if (status === 'not_set') {
      const params = new URLSearchParams(history.location.search);
      const allSpaceId = params.get('s') || '';
      const contractId = params.get('c') || '';

      if (allSpaceId === '' || contractId === '') {
        // パラメータの取得に失敗した場合は404ページに飛ばす
        history.push('/not_found');
        return;
      }
      setQueryParams(allSpaceId, contractId);
    }
  }, [history, setQueryParams, status]);

  useEffect(() => {
    if (year !== undefined && month !== undefined) {
      if (day === undefined) setDay(1);
      const lastday = new Date(year, month, 0).getDate();
      const days: number[] = [];
      for (let i = 0; i < lastday; i++) {
        days.push(i + 1);
      }
      if (day !== undefined && day > lastday) {
        setDay(1);
      }
      setLastday(days);
    }
  }, [year, month, day]);

  useEffect(() => {
    if (year !== undefined && month !== undefined && day !== undefined) {
      const _month = month < 10 ? `0${month}` : `${month}`;
      const _day = day < 10 ? `0${day}` : `${day}`;

      updateParameter('birthday', `${year}${_month}${_day}`);
    }
  }, [year, month, day, updateParameter]);

  useEffect(() => {
    // 成功後に初期画面（契約内容確認画面）に遷移
    if (status === 'identified') {
      history.push('/');
    }
  }, [status]);

  return (
    <div>
      <div className={classes.header}>
        <h1 className={classes.heading}>{'スマモル賃貸サービスお申し込み'}</h1>
        <p className={classes.description}>
          スマモル賃貸サービスにお申し込みいただきありがとうございます。
          <br />
          契約者様ご本人であることの確認のため、契約者様の生年月日をご入力いただき、本人確認へお進みください。
        </p>
      </div>
      <div className={classes.contents}>
        {status === 'processing' ? (
          <Container className={classes.container}>
            <ApplyHeading text={'ご本人様確認'} />
            <div className={classes.loadingContainer}>
              <Loading />
              <p className={classes.loadingCaption}>確認中です...</p>
            </div>
          </Container>
        ) : status === 'locked' ? (
          <Container className={classes.container}>
            <ApplyHeading text={'ご本人様確認'} />
            <div className={classes.loadingContainer}>
              <ReportProblemOutlinedIcon className={classes.lockedIcon} />
              <p className={classes.lockedCaption}>ロックされています</p>
              <p className={classes.lockedSmallCaption}>
                入力された生年月日が一定回数連続して一致しなかったため、お申し込みをロックさせていただきました。
                <br />
                ロック解除については、物件の管理会社にお問い合わせください。
              </p>
            </div>
          </Container>
        ) : (
          <>
            <Container className={classes.container}>
              <ApplyHeading text={'ご本人様確認'} />
              <ApplyLabel text={'生年月日'} />
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <FormControl margin="normal" style={{ width: '100%', marginTop: 8 }} variant="outlined">
                    <Select
                      value={year}
                      onChange={e => setYear(e.target.value as number)}
                      displayEmpty
                      error={!!notMatchedBirthday}
                    >
                      <MenuItem value={undefined} disabled>
                        <span className={classes.selectPlaceholder}>年</span>
                      </MenuItem>
                      {YEAR.reverse().map(y => {
                        return (
                          <MenuItem key={`y${y}`} value={y}>
                            {y}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl margin="normal" style={{ width: '100%', marginTop: 8 }} variant="outlined">
                    <Select
                      value={month}
                      onChange={e => setMonth(e.target.value as number)}
                      displayEmpty
                      error={!!notMatchedBirthday}
                    >
                      <MenuItem value={undefined} disabled>
                        <span className={classes.selectPlaceholder}>月</span>
                      </MenuItem>
                      {MONTH.map(m => {
                        return (
                          <MenuItem key={`m${m}`} value={m}>
                            {m}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl margin="normal" style={{ width: '100%', marginTop: 8 }} variant="outlined">
                    {day ? (
                      <Select
                        value={day}
                        onChange={e => setDay(e.target.value as number)}
                        displayEmpty
                        disabled={lastday === []}
                        error={!!notMatchedBirthday}
                      >
                        <MenuItem value={undefined} disabled>
                          <span className={classes.selectPlaceholder}>日</span>
                        </MenuItem>
                        {lastday &&
                          lastday.map(d => (
                            <MenuItem key={`m${d}`} value={d}>
                              {d}
                            </MenuItem>
                          ))}
                      </Select>
                    ) : (
                      <></>
                    )}
                  </FormControl>
                </Grid>
                {notMatchedBirthday && (
                  <Grid item xs={12}>
                    <p className={classes.error}>
                      ご入力いただいた生年月日がお申し込み時に登録いただいた生年月日と異なります。
                    </p>
                  </Grid>
                )}
              </Grid>
              <p className={classes.caption}>
                スマモル賃貸サービス申込み時にご提出いただいた生年月日をご入力ください。
                <br />
                <span>ご提出いただいた生年月日が不明の場合は、物件の管理会社にお問い合わせください。</span>
              </p>
            </Container>
            <BaseFab
              className={classes.button}
              variant="extended"
              size="large"
              aria-label="add"
              onClick={verifyIdentification}
            >
              ご契約物件の確認へ
            </BaseFab>
            <div className={classes.linkContainer}>
              <p className={classes.caption}>
                別途、郵送されるパンフレットでご説明している電気プラン、サービスは
                <a
                  className={classes.link}
                  href={'/static/sumamoruchintai.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
                からご確認いただけます。
              </p>
            </div>
          </>
        )}
      </div>
      {/* desktopでのフッター位置保留 */}
      <div className={classes.footer}>
        <Footer privacyPolicy={getFooterOptions.privacyPolicy} />
      </div>
    </div>
  );
};

export default IdentificationByBirth;
