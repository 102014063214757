import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import * as serviceWorker from './serviceWorker';
import BitkeyPlatformAPI from './utils/bitkey-platform-api';
import { bkpPath } from './config/baseConfig';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Lato', 'Noto Sans CJK JP'].join(','),
    subtitle1: {
      fontSize: '18px',
      fontWeight: 500
    },
    h1: {
      fontWeight: 'bold'
    },
    body1: {
      fontSize: '14px'
    }
  },
  palette: {
    primary: {
      main: '#03A9F4'
    }
  },
  overrides: {
    MuiPickersDay: {
      daySelected: {
        color: 'white'
      },
      current: {
        color: 'black'
      }
    }
  }
});
const history = require('history').createBrowserHistory();
BitkeyPlatformAPI.init(bkpPath);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
