import { AppState } from '../../store';
import { SigninErrorState, TextFieldStatus } from './types';

export const getIdentifyValue = ({ signInForm }: AppState): string => {
  return signInForm.identifyValue.value;
};

export const getIdentifyValueStatus = ({ signInForm }: AppState): TextFieldStatus => {
  return signInForm.identifyValue.status;
};

export const getPassword = ({ signInForm }: AppState): string => {
  return signInForm.password.value;
};

export const gettPasswordStatus = ({ signInForm }: AppState): TextFieldStatus => {
  return signInForm.password.status;
};

type status = 'not_signin' | 'signin_loading' | 'signin' | SigninErrorState;

export const getStatus = ({ signInForm }: AppState): status => {
  return signInForm.status;
};
