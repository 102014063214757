import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentDescriptionType } from '../../enums/common/component-description-type';
import { ComponentDescriptionElement } from '../../types/common/item-definition-types';
import { ApplyCaption } from '../../components/Label';

interface P {
  description: ComponentDescriptionElement;
}

const useStyles = makeStyles(
  createStyles({
    balloon: {
      backgroundColor: 'var(--color-sub)',
      color: 'var(--color-white)',
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: 4,
      padding: '7px 0 8px',
      lineHeight: '17px',
      textAlign: 'center',
      margin: '16px 0 0',
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        height: 0,
        width: 0,
        position: 'absolute',
        bottom: '-8px',
        left: 'calc(50% - 16px)',
        borderTop: '16px solid var(--color-sub)',
        borderRight: '16px solid transparent',
        borderLeft: '16px solid transparent'
      }
    }
  })
);

const ComponentDescriptionElementFunction: React.FC<P> = ({ description }) => {
  const styles = useStyles({});

  switch (description.descriptionType || ComponentDescriptionType.Normal) {
    case ComponentDescriptionType.Balloon:
      return (
        <div className={styles.balloon}>
          {description.messages.map((message, i) => (
            <React.Fragment key={`caption-${i}`}>
              {message}
              <br />
            </React.Fragment>
          ))}
        </div>
      );
    case ComponentDescriptionType.Normal:
    default:
      return (
        <>
          {description.messages.map((message, i) => (
            <ApplyCaption text={message} key={`caption-${i}`} />
          ))}
        </>
      );
  }
};

export default React.memo(ComponentDescriptionElementFunction);
