import { SignUpAction } from './actions';
import ActionType, { SignupMethod, SignupStatus, TextFieldStatus } from './types';

interface SignupFieldValue {
  value: string;
  status: TextFieldStatus;
}

export interface SignUpFormState {
  email: SignupFieldValue;
  phoneNumber: SignupFieldValue;
  password: SignupFieldValue;
  status: SignupStatus;
  signupSessionId: string;
  signupMethod: SignupMethod;
}

export const initialState: () => SignUpFormState = () => {
  return {
    email: {
      value: '',
      status: 'empty'
    },
    phoneNumber: {
      value: '',
      status: 'empty'
    },
    password: {
      value: '',
      status: 'empty'
    },
    status: 'not_signup',
    signupSessionId: '',
    signupMethod: SignupMethod.Email
  };
};

const reducer = (state: SignUpFormState = initialState(), action: SignUpAction) => {
  switch (action.type) {
    case ActionType.updateEmail: {
      const newState = { ...state };
      newState.email.value = action.payload;
      newState.status = 'not_signup';
      newState.email.status = 'valid';
      return newState;
    }
    case ActionType.validateSignupEmail: {
      const newState = { ...state };
      newState.email.status = action.payload;
      return newState;
    }
    case ActionType.updatePhoneNumber: {
      const newState = { ...state };
      newState.phoneNumber.value = action.payload;
      newState.status = 'not_signup';
      newState.phoneNumber.status = 'valid';
      return newState;
    }
    case ActionType.validateSignupPhoneNumber: {
      const newState = { ...state };
      newState.phoneNumber.status = action.payload;
      return newState;
    }
    case ActionType.updatePassword: {
      const newState = { ...state };
      newState.password.value = action.payload;
      newState.status = 'not_signup';
      newState.password.status = 'valid';
      return newState;
    }
    case ActionType.validateSignupPassword: {
      const newState = { ...state };
      newState.password.status = action.payload;
      return newState;
    }
    case ActionType.startToSignup: {
      const newState = { ...state };
      newState.status = 'signup_loading';
      return newState;
    }
    case ActionType.signupSuccess: {
      const newState = { ...state };
      newState.status = 'signup';
      newState.signupSessionId = action.payload;
      return newState;
    }
    case ActionType.signupFailed: {
      const newState = { ...state };
      newState.status = action.payload;
      return newState;
    }
    case ActionType.clearSignupStatus: {
      return initialState();
    }
    case ActionType.changeSignupMethod: {
      const newState = { ...state };
      newState.signupMethod = action.payload;
      return newState;
    }
    default:
      ((_: never) => {
        return;
      })(action);
      return state;
  }
};

export default reducer;
