import React from 'react';
import { Container } from '@material-ui/core';
import { Path } from '../../constants/path';
import BaseStepper from '../../components/BaseStepper';
import { ApplyHeading, AccountCaption, AccountBottomGuide } from '../../components/Label';
import SignUpForm from '../../container/signUpForm';
import { ChangeAccountTypeButton } from '../../components/LinkButton';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DesktopBackground } from '../../components/atoms/DesktopBackground';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 24px',
      marginBottom: '48px',
      '@media (min-width:768px)': {
        padding: '0 32px 32px',
        '& h1': {
          textAlign: 'center'
        },
        '&>p': {
          textAlign: 'center',
          fontSize: 14
        }
      }
    }
  })
);

interface Props {
  hasElectricContract?: boolean;
}

const SignupScreen: React.FC<Props> = ({ hasElectricContract }) => {
  const styles = useStyles({});
  return (
    <DesktopBackground>
      <BaseStepper step={0} hasElectoric={hasElectricContract} />
      <Container className={styles.container}>
        <ApplyHeading text={'Bitkeyアカウント連携'} />
        <AccountCaption text={'スマモル賃貸プランのご利用には、Bitkey社の提供するbitkeyアカウントが必要です。'} />
        <SignUpForm />
        <AccountBottomGuide text={'Bitkeyアカウントをお持ちの方は'} />
        <ChangeAccountTypeButton path={Path.account.signin} text={'ログイン'} />
      </Container>
    </DesktopBackground>
  );
};

export default SignupScreen;
