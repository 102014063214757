import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../inputs/CustomTextField';

const useStyles = makeStyles(
  createStyles({
    textField: {
      margin: '8px 0 0',
      width: '100%',
      '& input': {
        padding: '16px 14px',
        fontSize: '14px !important',
        '&::placeholder': {
          fontSize: '14px !important'
        }
      }
    }
  })
);

interface Props {
  id?: string;
  placeholder: string;
  value?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  error?: boolean;
  helperText?: string;
  type?: string;
  disabled?: boolean;
}

export const ApplyTextField: React.FC<Props> = ({
  id,
  placeholder,
  value,
  defaultValue,
  onChange,
  onBlur,
  error,
  helperText,
  type,
  disabled
}) => {
  const styles = useStyles({});

  return (
    <CustomTextField
      id={id}
      className={styles.textField}
      placeholder={placeholder}
      margin={'normal'}
      value={value}
      defaultValue={defaultValue}
      error={error}
      helperText={helperText}
      onChange={e => onChange && onChange(e.target.value)}
      onBlur={e => onBlur && onBlur(e.target.value)}
      InputLabelProps={{
        shrink: true
      }}
      variant="outlined"
      type={type}
      disabled={disabled}
    />
  );
};
