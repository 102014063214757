import ActionType from './types';
import { ActionWithPayload } from '../utils/types';

export const initSubmitStatus = (): ActionWithPayload<ActionType.initSubmitStatus, {}> => ({
  type: ActionType.initSubmitStatus,
  payload: {}
});

export const submitCode = (code: string): ActionWithPayload<ActionType.submitCode, {}> => ({
  type: ActionType.submitCode,
  payload: code
});

export const submitSuccess = (
  accessToken: string,
  refreshToken: string,
  userId: string,
  email: string,
  phoneNumber: string
): ActionWithPayload<
  ActionType.submitSuccess,
  {
    accessToken: string;
    refreshToken: string;
    userId: string;
    email: string;
    phoneNumber: string;
  }
> => ({
  type: ActionType.submitSuccess,
  payload: {
    accessToken: accessToken,
    refreshToken: refreshToken,
    userId: userId,
    email: email,
    phoneNumber: phoneNumber
  }
});

export const submitFailed = (): ActionWithPayload<ActionType.submitFailed, {}> => ({
  type: ActionType.submitFailed,
  payload: {}
});

export type ValidationAction =
  | ReturnType<typeof initSubmitStatus>
  | ReturnType<typeof submitCode>
  | ReturnType<typeof submitSuccess>
  | ReturnType<typeof submitFailed>;
