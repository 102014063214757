import * as React from 'react';
import { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import { BaseFormStateContext } from '../BaseForm';
import { ComponentInputElement } from '../../types/common/item-definition-types';
import { ApplyTextField } from '../baseform/ApplyTextField';
import { getComponentElementKeyValue } from '../utils/key-value-getter';
import { checkComponentElementCondition } from '../utils/check-component-element-condition';
import { phoneRegex, zenkakuRegex, onlyNumberRegex } from '../utils/regex';
import { ItemValueKeySuffix } from '../../enums/common/item-value-key-suffix';

interface P {
  componentElement: ComponentInputElement;
}

const TextFeildElement: React.FC<P> = ({ componentElement }) => {
  const { inputValues, updateInputValues, applicationParams } = useContext(BaseFormStateContext);

  const valueKey = useMemo(() => componentElement.valueKey, []);
  const valueHasErrorKey = useMemo(() => valueKey + ItemValueKeySuffix.HasError, []);

  const inputValue = useMemo(
    () =>
      getComponentElementKeyValue({
        inputValues,
        applicationParams,
        inputValueType: componentElement.valueType,
        valueKey: valueKey,
        initialValue: componentElement.initialValue,
        converter: componentElement.converter
      }),
    [
      inputValues[valueKey],
      applicationParams[valueKey],
      componentElement.valueType,
      valueKey,
      componentElement.initialValue
    ]
  );

  // 初期値の補完
  useEffect(() => {
    if (inputValues[valueKey] || !componentElement.initialValue) {
      return;
    }
    updateInputValues(valueKey, componentElement.initialValue);
  }, []);

  const isDisable = useMemo(
    () =>
      checkComponentElementCondition({
        conditions: componentElement.isDisableCondition,
        inputValues: inputValues,
        applicationParams: applicationParams
      }),
    [inputValues, applicationParams]
  );

  const [errorMessage, setErrorMessage] = useState('');

  const handleOnBlur = useCallback((value: string) => {
    let error = true;
    if (value === '') {
      setErrorMessage('');
    } else if (componentElement.inputComponentType === 'tel' && value.match(zenkakuRegex)) {
      setErrorMessage('半角数字で入力してください');
    } else if (componentElement.inputComponentType === 'tel' && !onlyNumberRegex.test(value)) {
      setErrorMessage('半角数字で入力してください');
    } else if (componentElement.inputComponentType === 'tel' && !phoneRegex.test(value)) {
      setErrorMessage('有効な電話番号を入力してください');
    } else {
      error = false;
      setErrorMessage('');
    }
    updateInputValues(valueHasErrorKey, error);
  }, []);

  return (
    <ApplyTextField
      id={valueKey}
      placeholder={componentElement.placeholder || ''}
      value={inputValue}
      error={errorMessage !== ''}
      helperText={errorMessage}
      onChange={value => updateInputValues(valueKey, value)}
      onBlur={value => handleOnBlur(value)}
      type={componentElement.inputComponentType}
      disabled={isDisable}
    />
  );
};

export default React.memo(TextFeildElement);
