import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { getImportantConfirmationText } from '../../config/item-definitions';
import { stage } from '../../config/baseConfig';
import { ComponentDescriptionType } from '../../enums/common/component-description-type';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box'
    },
    managementContainer: {
      backgroundColor: '#ECEFF1',
      padding: 20,
      boxSizing: 'border-box',
      '& p': {
        fontSize: 15,
        color: 'var(--color-gray-1)',
        lineHeight: '22px',
        margin: 0
      }
    },
    managementHeading: {
      color: '#828282 !important',
      fontWeight: 'bold',
      marginBottom: '8px !important'
    },
    body: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '21px'
    }
  })
);
const ImportantTerm: React.FC = () => {
  const styles = useStyles({});

  const importantText = getImportantConfirmationText(stage).electronic;

  return (
    <div className={styles.container}>
      <h1>重要事項説明</h1>
      <p className={styles.body}>
        {importantText &&
          importantText.map(unit => (
            <>
              {unit.map(item => (
                <>
                  {item.type === ComponentDescriptionType.Normal ? (
                    <>
                      {item.text}
                      <br />
                    </>
                  ) : item.type === ComponentDescriptionType.ExternalLink ? (
                    <>
                      <a target="_blank" rel="noopener noreferrer" href={item.link}>
                        {item.text}
                      </a>
                      <br />
                    </>
                  ) : (
                    <h3>{item.text}</h3>
                  )}
                </>
              ))}
            </>
          ))}
      </p>
    </div>
  );
};

export default ImportantTerm;
