import React, { FC, useState, useEffect, useCallback, useMemo, createContext } from 'react';
import { Container, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import OccupancyLabel, { OccupancyLabelBox } from './OccupancyLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { ApplyHeading, ApplyLabel, ApplyCaption } from '../components/Label';
import { CustomRadioButton, BlueRadio } from './CustomRadioButton';
import { CustomKeyButton } from './CustomButton';
import { mapStateToProps, mapDispatchToProps } from '../container/applyForm';
import * as moment from 'moment-timezone';
import { CustomTextField } from './inputs/CustomTextField';
import ApplicationModal from './ApplyModal';
import { ApplyTextField } from './baseform/ApplyTextField';
import BackModal from '../container/backModal';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import jaLocale from 'date-fns/locale/ja';
import * as AutoKana from 'vanilla-autokana';
import { Link } from 'react-router-dom';
import { Path } from '../constants/path';
import ComponentElement from './componentElements/ComponentElement';
import {
  RegisteredInputContainers,
  ableGoToNextPageConditionDefinition,
  baseFormText
} from '../config/item-definitions';
import { useHistory } from 'react-router-dom';
import { ItemValueKeySuffix } from '../enums/common/item-value-key-suffix';
import { ItemValueKey } from '../config/item-value-key';
import { checkComponentElementCondition } from './utils/check-component-element-condition';
import ContainerElement from '../components/componentElements/ContainerElement';
import { BasicItemKeys } from '../enums/item/basic-item-keys';
import { ComponentDescriptionType } from '../enums/common/component-description-type';
import { unitOfTime } from 'moment';
import AgreeTermElement from './InputComponetns/AgreeTermElement';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const useStyles = makeStyles(
  createStyles({
    container: {
      textAlign: 'left',
      padding: '0 0 20px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0'
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-key)'
        }
      }
    },
    confirmButton: {
      margin: '24px 0 0'
    },
    checkbox: {
      '& .MuiTypography-root': {
        color: 'var(--color-text)',
        fontSize: 14
      },
      '& .MuiCheckbox-root': {
        color: 'var(--color-key)'
      }
    },
    agreeCheckbox: {
      display: 'block',
      margin: 0,
      textAlign: 'center',
      '& .MuiTypography-root': {
        color: 'var(--color-text)',
        fontSize: 14
      },
      '& .MuiCheckbox-root': {
        color: 'var(--color-key)'
      }
    },
    backButtonContainer: {
      margin: '40px 0',
      textAlign: 'center'
    },
    backButton: {
      color: 'var(--color-gray-3)',
      textDecoration: 'none'
    },
    termsCaption: {
      fontSize: 14,
      lineHeight: '21px',
      color: 'var(--color-text)',
      '& a': {
        textDecoration: 'none',
        color: 'var(--color-key)',
        fontWeight: 'bold'
      }
    }
  })
);

export const BaseFormStateContext = createContext<{
  inputValues: any;
  updateInputValues: any;
  applicationParams: any;
}>({
  inputValues: {},
  // tslint:disable-next-line: no-empty
  updateInputValues: () => {},
  applicationParams: {}
});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const BaseForm: FC<Props> = ({
  inputValues,
  updateInputValues,
  applicationParams,

  use_og_gas,
  management_company_address,
  management_company_name,
  management_company_phonenumber,
  birthday,
  email,
  email_confirmation,
  pay_method,
  move_plan_date,
  updateParameter,
  property,
  gender,
  bitkeyEmail,
  bitkeyPhoneNumber,
  initialBirthday,
  isSameEmail,
  getTokens,
  getIds,
  getProperty
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [backModalOpen, setBackModalOpen] = useState(false);

  const styles = useStyles({});
  const history = useHistory();

  // TODO 最終的に組み込みを行う
  // /**
  //  * 電話番号でサインイン、サインアップした場合に実行する初期化処理
  //  */
  // useEffect(() => {
  //   if (bitkeyPhoneNumber) {
  //     updateParameter('isSameEmail', false);
  //     updateParameter('phone_number', bitkeyPhoneNumber);
  //     updateParameter('phone_number_when_move', bitkeyPhoneNumber);
  //   }
  // }, [updateParameter, bitkeyPhoneNumber]);

  useEffect(() => {
    if (property.allSpaceId === '') {
      getIds();
    }
    if (bitkeyEmail === '') {
      getTokens();
    }
    if (bitkeyEmail !== '' && property.allSpaceId !== '' && property.address === '') {
      getProperty();
    }
  }, [bitkeyEmail, property, getIds, getTokens, getProperty]);

  const baseFormStateContext = useMemo(
    () => ({
      inputValues: inputValues,
      updateInputValues: updateInputValues,
      applicationParams: applicationParams
    }),
    [inputValues, updateInputValues, applicationParams]
  );

  const ableToGoToNextPage = useMemo(() => {
    // 入力必須の項目が入力されているか否か、
    const allInput = ableGoToNextPageConditionDefinition.needToInput.inputValues.every(key => {
      // 項目が表示されていない、または、入力向こうの場合には値とチェックする必要がないのでtrueを返却
      if (
        inputValues[key + ItemValueKeySuffix.IsDisplayed] === false ||
        inputValues[key + ItemValueKeySuffix.IsDisabled]
      ) {
        return true;
      }
      return !!inputValues[key] && inputValues[key] !== '';
    });

    // Errorとなっている項目があるか否か
    const hasNoError = ableGoToNextPageConditionDefinition.hasNoError.inputValues.every(key => {
      // 項目が表示されていない、または、入力向こうの場合には値とチェックする必要がないのでtrueを返却
      if (
        inputValues[key + ItemValueKeySuffix.IsDisplayed] === false ||
        inputValues[key + ItemValueKeySuffix.IsDisabled]
      ) {
        return true;
      }
      return !inputValues[key + ItemValueKeySuffix.HasError];
    });

    const satisfySpecificCondition = checkComponentElementCondition({
      conditions: ableGoToNextPageConditionDefinition.satisfySpecificCondition,
      inputValues: inputValues,
      applicationParams: applicationParams,
      initialValue: false
    });

    return allInput && hasNoError && satisfySpecificCondition;
  }, [inputValues]);

  return (
    <div>
      <BaseFormStateContext.Provider value={baseFormStateContext}>
        {RegisteredInputContainers.map(container => (
          <ContainerElement container={container} />
        ))}
      </BaseFormStateContext.Provider>

      <AgreeTermElement
        updateInputValues={updateInputValues}
        inputValues={inputValues}
        applicationParams={applicationParams}
      />

      <CustomKeyButton
        disabled={!ableToGoToNextPage}
        className={styles.confirmButton}
        variant="extended"
        size="large"
        onClick={() => {
          // 電気利用有りの場合
          if (applicationParams[BasicItemKeys.HasElectricContract]) {
            setModalOpen(true);
          } else {
            // setModalOpen(true);
            history.push(Path.application.confirm);
          }
        }}
      >
        申込内容の確認へ
      </CustomKeyButton>

      <div className={styles.backButtonContainer}>
        <Button className={styles.backButton} onClick={() => setBackModalOpen(true)}>
          戻る
        </Button>
      </div>

      <BackModal isOpen={backModalOpen} setIsOpen={e => setBackModalOpen(e)} />
      <ApplicationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        hasElectricContract={applicationParams[BasicItemKeys.HasElectricContract]}
        useGasPlanSelection={inputValues[BasicItemKeys.UseGasPlanSelection] === '0'}
        management_company_address={management_company_address}
        management_company_name={management_company_name}
        management_company_phonenumber={management_company_phonenumber}
      />
    </div>
  );
};

export default BaseForm;
