import { connect } from 'react-redux';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as SignupActions from '../modules/signUp/actions';
import * as SigninActions from '../modules/signIn/actions';
import BackModal from '../components/atoms/BackModal';

export const mapStateToProps = (state: AppState) => {
  return {
    signupStatus: state.signUpForm.status,
    signinStatus: state.signInForm.status
  };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, {}, AnyAction>) => ({
  backStatus: () => {
    dispatch(SignupActions.clearSignupStatus());
    dispatch(SigninActions.clearSigninStatus());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BackModal);
