import { AppState } from '../../store';
import moment from 'moment';

moment.updateLocale('ja', {
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});

export const getParameter = ({ applyForm }: AppState) => applyForm.parameters;

export const getUseOgGas = ({ applyForm }: AppState) => applyForm.parameters.use_og_gas;
export const getFamilyName = ({ applyForm }: AppState) => applyForm.parameters.family_name;
export const getFirstName = ({ applyForm }: AppState) => applyForm.parameters.first_name;
export const getFamilyNameKana = ({ applyForm }: AppState) => applyForm.parameters.family_name_kana;
export const getFirstNameKana = ({ applyForm }: AppState) => applyForm.parameters.first_name_kana;
export const getPhoneNumber = ({ applyForm }: AppState) => applyForm.parameters.phone_number;

export const getPhoneNumberWhenMove = ({ applyForm }: AppState) => applyForm.parameters.phone_number_when_move;
export const getPhoneNumberAfterMove = ({ applyForm }: AppState) => applyForm.parameters.phone_number_after_move;
export const getEmail = ({ applyForm }: AppState) => applyForm.parameters.email;
// 画面上、EmailConfirmationないため確認する。
export const getEmailConfirmation = ({ applyForm }: AppState) => applyForm.parameters.email_confirmation;
export const getBirthday = ({ applyForm }: AppState) => applyForm.parameters.birthday;
export const getPayMethod = ({ applyForm }: AppState) => {
  return applyForm.parameters.pay_method;
};
export const getPayMethodText = ({ applyForm }: AppState) => {
  if (applyForm.parameters.pay_method === '0') {
    applyForm.parameters.pay_method_text = '口座振替';
  } else if (applyForm.parameters.pay_method === '1') {
    applyForm.parameters.pay_method_text = 'クレジットカード';
  } else if (applyForm.parameters.pay_method === '2') {
    applyForm.parameters.pay_method_text = '払込票';
  }
  return applyForm.parameters.pay_method_text;
};
export const getMovePlanDate = ({ applyForm }: AppState) => applyForm.parameters.move_plan_date;
export const getStartPowerDate = ({ applyForm }: AppState) => applyForm.parameters.start_power_date;
export const getGasTime = ({ applyForm }: AppState) => {
  if (applyForm.parameters.isCoodinatedGasStartDate) {
    return null;
  } else {
    return applyForm.parameters.start_gas_time;
  }
};
export const getGasDate = ({ applyForm }: AppState) => {
  if (applyForm.parameters.isCoodinatedGasStartDate) {
    return null;
  } else {
    return applyForm.parameters.start_gas_date;
  }
};
export const getIsCoodinatedGasStartDate = ({ applyForm }: AppState) => applyForm.parameters.isCoodinatedGasStartDate;
export const getGender = ({ applyForm }: AppState) => applyForm.parameters.gender;
export const getIsSameEmail = ({ applyForm }: AppState) => applyForm.parameters.isSameEmail;
export const getIsPhoneNumberError = ({ applyForm }: AppState) => applyForm.parameters.isPhoneNumberError;
export const getIsPhoneNumberWhenMoveError = ({ applyForm }: AppState) =>
  applyForm.parameters.isPhoneNumberWhenMoveError;
export const getIsEmailMatch = ({ applyForm }: AppState) => applyForm.parameters.isEmailMatch;
export const getConfirmEmail = ({ applyForm, user }: AppState) => {
  if (applyForm.parameters.isSameEmail) {
    return user.email;
  } else {
    return applyForm.parameters.email;
  }
};
